/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { OutlineButton, PrimaryButton } from "../Buttons";
import { ErrorMessage, Label, TextArea } from "../formComponents";
import { StepperBlock } from "../Stepper";
import { useSharedFormState } from "../store/store";
import {
  UploadIcon,
  DocumentIcon,
  ExclamationIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import {
  ConfirmationContentContainer,
  ConfirmationFooterContainer,
  ConfirmationModal,
} from "../ConfirmationModal";
import { FormikErrors, useFormik } from "formik";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { ListContainer, ListItem, ListItemIconContainer } from "../Lists";
import { getAxiosInstance } from "../hooks/useAxios";
import { ApplicationFile } from "../../types/applicationFile";
import { Spinner } from "../Spinner";
import { isEmpty, RequiredMessage } from "../validation";
import { FailureNotification, Notification } from "../Notification";
import { useRef } from "react";

import { Disclosure } from "@headlessui/react";

export type UserFile = {
  Description: string;
  File?: File;
};

export type UploadedFile = {
  Description: string;
  Name: string;
  Extension: string;
  ID: number;
};
export interface IRequiredDocumentsStepProps {
  onBack: () => void;
  onNext: () => void;
  onSaveAndComplete?: () => void;
}

const RequireDocumentDisclosure: React.FC = (props) => {
  const [currentId, setCurrentId] = useState<string>("");

  function isOpen(id: string): boolean {
    return currentId === id;
  }

  function handleClick(id: string) {
    if (!isOpen(id)) {
      setCurrentId(id);
    } else {
      setCurrentId("");
    }
  }

  return (
    <div className="w-full">
      <div className="w-full max-w-md py-2 bg-white rounded-2xl">
        <Disclosure>
          {() => (
            <>
              <span onClick={() => handleClick("ID")}>
                <Disclosure.Button
                  id="ID"
                  onClick={() => setCurrentId("ID")}
                  className="flex justify-between w-full px-4 py-2 text-sm 
              font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 
              focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <span>Identification</span>
                  <ChevronUpIcon
                    className={`${
                      isOpen("ID") ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
              </span>

              {isOpen("ID") && (
                <Disclosure.Panel
                  static
                  className="px-4 pl-10 pt-4 pb-2 text-sm text-gray-500"
                >
                  <ol className="list-decimal">
                    <li>Valid SC State ID</li>
                    <li>Current Driver's License</li>
                    <li>
                      Voter Registration Card *
                      <br />
                      <span className="text-xs">
                        *Must have picture and the address must match address on
                        utility bill*
                      </span>
                    </li>
                  </ol>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>

        <Disclosure>
          {() => (
            <>
              <span onClick={() => handleClick("Citizenship")}>
                <Disclosure.Button
                  id="Citizenship"
                  onClick={() => setCurrentId("Citizenship")}
                  className="mt-2 flex justify-between w-full px-4 py-2 text-sm 
              font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 
              focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <span>Citizenship</span>
                  <ChevronUpIcon
                    className={`${
                      isOpen("Citizenship") ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
              </span>

              {isOpen("Citizenship") && (
                <Disclosure.Panel
                  static
                  className="px-4 pl-10 pt-4 pb-2 text-sm text-gray-500"
                >
                  <ol className="list-decimal">
                    <li>Social Security Card</li>
                  </ol>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>

        <Disclosure>
          {() => (
            <>
              <span onClick={() => handleClick("Program")}>
                <Disclosure.Button
                  id="Program"
                  onClick={() => setCurrentId("Program")}
                  className="mt-2 flex justify-between w-full px-4 py-2 text-sm 
              font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 
              focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <span>Program Specific</span>
                  <ChevronUpIcon
                    className={`${
                      isOpen("Program") ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
              </span>

              {isOpen("Program") && (
                <Disclosure.Panel
                  static
                  className="px-4 pl-10 pt-4 pb-2 text-sm text-gray-500"
                >
                  <p className="pb-3 italic text-xs text-gray-900">
                    Must include documentation of the emergency; for example,
                    late notice, hotel bill, lease with notice of eviction
                    filing once late, letter from a shelter. If emergency is due
                    to Covid, provide verification of illness/impact.
                  </p>
                  <ol className="list-decimal">
                    <li>
                      Most recent utility bill with a breakdown of all charges
                    </li>
                    <li>Current lease agreement</li>
                    <li>Military Service Record (DD214 or DD256)</li>
                    <li>Mortgage Statement</li>
                    <li>
                      Nutrition voucher: documentation of current food stamp
                      amount
                    </li>
                    <li>
                      DSS Letter if foster child(ren) are in the household
                    </li>
                  </ol>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>

        <Disclosure>
          {() => (
            <>
              <span onClick={() => handleClick("Income")}>
                <Disclosure.Button
                  id="Income"
                  onClick={() => setCurrentId("Income")}
                  className="mt-2 flex justify-between w-full px-4 py-2 text-sm 
              font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 
              focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                >
                  <span>Income</span>
                  <ChevronUpIcon
                    className={`${
                      isOpen("Income") ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
              </span>

              {isOpen("Income") && (
                <Disclosure.Panel
                  static
                  className="px-4 pl-10 pt-4 pb-2 text-sm text-gray-500"
                >
                  <p className="pb-3 italic text-xs text-gray-900">
                    You are required to upload at least one document from this
                    section, but must review the list carefully and include a
                    document for each income source that applies to you.
                  </p>

                  <div className="text-base font-bold">Fixed Income</div>
                  <p className="pb-3 text-xs italic text-gray-900">
                    Can be in the form of an award letter, payment
                    printout/statement from issuing agency, copy of check, most
                    recent filed IRS Form 1040 or 1099.
                  </p>
                  <ol className="list-decimal">
                    <li>Pension/Retirement</li>
                    <li>Retirement Income from Social Security</li>
                    <li>SSI/SSDI</li>
                    <li>State Assistance (IS General)</li>
                    <li>Alimony or Spousal Support</li>
                    <li>Child Support</li>
                    <li>Estate/Trust</li>
                    <li>
                      VA Service-Connected or Non Service-Connected Disability
                    </li>
                    <li>Veteran's Benefits</li>
                    <li>Private Disability Insurance</li>
                    <li>Long Term Disability</li>
                    <li>Utility Check</li>
                  </ol>

                  <div className="text-base font-bold mt-5">
                    Earned/Employment Income
                  </div>
                  <p className="pb-3 text-xs italic text-gray-900">
                    Must provide all pay stubs received within 30 days of the
                    date of your application, that includes gross wages and
                    year-to-date amounts received; or a signed and dated
                    Employment Verification Form.
                  </p>
                  <ol className="list-decimal">
                    <li>Earnings/Pay Stubs</li>
                    <li>
                      Self-Employment Income and expense for the previous 12
                      months
                    </li>
                    <li>
                      Most recent filed IRS Form 1040 with Schedule C if
                      self-employed or 1099
                    </li>
                    <li>Compensation for Foster Parenting</li>
                  </ol>

                  <div className="text-base font-bold mt-5">
                    Supplemental Income
                  </div>
                  <p className="pb-3 text-xs italic text-gray-900">
                    Can be in the form of an award letter, payment
                    printout/statement from issuing agency, copy of check, most
                    recent filed IRS Form 1099.
                  </p>
                  <ol className="list-decimal">
                    <li>Short Term Disability</li>
                    <li>TANF</li>
                    <li>Unemployment Insurance</li>
                    <li>Worker's Compensation</li>
                    <li>Public Assistance</li>
                    <li>Educational Assistance</li>
                    <li>EITC</li>
                    <li>Black Lung</li>
                    <li>Student Loan/Grant Disbursements</li>
                  </ol>

                  <div className="text-base font-bold mt-5">
                    Other Income Sources
                  </div>
                  <p className="pb-3 text-xs italic text-gray-900">
                    Can be in the form of an award letter, payment
                    printout/statement from issuing agency, copy of check, most
                    recent filed IRS Form 1099, or a signed and dated letter
                    from your supporter including their name, address and phone
                    number.
                  </p>
                  <ol className="list-decimal">
                    <li>Interest/Dividends</li>
                    <li>Rental Income</li>
                    <li>Royalties</li>
                    <li>Outside Assistance</li>
                  </ol>

                  <div className="text-base font-bold mt-5">
                    Non Cash Benefits
                  </div>
                  <p className="pb-3 text-xs italic text-gray-900">
                    Can be in the form of an award letter, payment
                    printout/statement from issuing agency, copy of check, most
                    recent filed IRS Form 1099, or a signed and dated letter
                    from your supporter including their name, address and phone
                    number.
                  </p>
                  <ol className="list-decimal">
                    <li>SNAP</li>
                    <li>WIC</li>
                    <li>LIHEAP</li>
                    <li>Housing Choice Voucher (Section 8)</li>
                    <li>Public Housing</li>
                    <li>Permanent Supportive Housing</li>
                    <li>HUD-VASH</li>
                    <li>Childcare Voucher (ABC)</li>
                    <li>Affordable Care Act Subsidy</li>
                    <li>Other</li>
                  </ol>
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export const RequiredDocumentsStep: React.FC<IRequiredDocumentsStepProps> = (
  props
) => {
  const [state, setState] = useSharedFormState();
  const [showApplicableDocuments, setShowApplicableDocuments] =
    useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<number[]>([]);
  const [showFailNotification, setShowFailNotification] =
    useState<boolean>(false);
  const failNotificationTimeout = useRef<NodeJS.Timeout>();
  const isValid = state.CurrentFormData.Files.length >= 4;

  function handleFileModalAction(file?: ApplicationFile): void {
    if (file) {
      setState({
        ...state,
        CurrentFormData: {
          ...state.CurrentFormData,
          Files: [...(state.CurrentFormData.Files || []), file],
        },
      });

      setShowUploadModal(false);
    } else {
      setShowUploadModal(false);
    }
  }

  async function deleteFile(fileId: number) {
    setShowFailNotification(false);

    //push file id onto array of deleting items
    setIsDeleting([...isDeleting, fileId]);

    try {
      const axios = await getAxiosInstance();

      await axios.delete(`api/application/document/${fileId}`);

      //reduce our current file list and keep all those that don't match what we just deleted.
      const newFiles = state.CurrentFormData.Files.reduce<ApplicationFile[]>(
        (arr, curr) => {
          if (curr.id !== fileId) {
            arr.push(curr);
          }

          return arr;
        },
        []
      );

      //update state
      setState({
        ...state,
        CurrentFormData: {
          ...state.CurrentFormData,
          Files: newFiles,
        },
      });
    } catch {
      setShowFailNotification(true);
    } finally {
      //update deleting items.
      //reduce array and keep items that aren't the current file we're deleting.
      setIsDeleting(
        isDeleting.reduce<number[]>((arr, curr) => {
          if (curr !== fileId) {
            arr.push(curr);
          }
          return arr;
        }, [])
      );
    }
  }

  function onNext() {
    if (isValid) {
      props.onNext();
    }
  }

  return (
    <>
      <StepperBlock
        onBack={props.onBack}
        onNext={onNext}
        onSaveAndComplete={props.onSaveAndComplete}
        isLoading={false}
      >
        <div className="col-span-6">
          <p className="my-2 leading-relaxed text-sm">
            The required document categories are listed below.
            <br />
            <span className="font-bold">
              You should provide at least one document from each category.{" "}
            </span>
          </p>

          <div className="mt-5 mb-5">
            <RequireDocumentDisclosure />
          </div>

          <p className="my-2">
            <PrimaryButton
              color="green"
              onClick={() => setShowUploadModal(true)}
            >
              <UploadIcon className="mr-2 h-6 w-6" />
              Upload Document
            </PrimaryButton>
          </p>

          {/**show file list */}
          <ListContainer noChildrenText="You have no uploaded documents">
            {state.CurrentFormData.Files?.map((x) => {
              const isProcessing = isDeleting.some((id) => id === x.id);
              return (
                <ListItem
                  key={x.id}
                  mainText={x.fileName}
                  subText={x.description}
                  rightElement={
                    <>
                      <a
                        className="text-green-600 mr-3"
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                        rel="noreferrer"
                        href={`/api/application/document/${
                          x.id
                        }/${encodeURIComponent(x.fileName)}`}
                      >
                        VIEW
                      </a>
                      <a
                        className="text-red-600"
                        onClick={async () => {
                          await deleteFile(x.id);
                        }}
                      >
                        {!isProcessing && <>REMOVE</>}
                        {isProcessing && <Spinner />}
                      </a>
                    </>
                  }
                  iconContainer={
                    <ListItemIconContainer>
                      <DocumentIcon />
                    </ListItemIconContainer>
                  }
                />
              );
            })}
          </ListContainer>

          {!isValid && (
            <ErrorMessage>
              You must provide at least 4 documents (1 from each category).
            </ErrorMessage>
          )}
        </div>
      </StepperBlock>

      {showApplicableDocuments && (
        <ConfirmationModal
          title="Documents List"
          onClose={() => setShowApplicableDocuments(false)}
        >
          <ConfirmationContentContainer>
            <p className="mb-4">
              The below documents apply to yourself AND any additional household
              members.
            </p>
            <ul className="list-disc">
              <li>
                Identification card: South Carolina State ID, driver's license
                that is currently dated, or a Voter's Registration card with a
                picture (address matches bill on all)
              </li>
              <li>Social Security Number: social security cards</li>
              <li>Veteran's status: DD214 or DD256</li>
              <li>
                Pay stubs-one for every pay period that fell within the last 30
                days (income from work/job)
              </li>
              <li>
                Current year Social Security Administration-SSA/SSI award
                letters
              </li>
              <li>Current year Veteran's Administration award letters</li>
              <li>
                Unemployment claim print out (must be within 7 days of the date
                of application)
              </li>
              <li>Current child support printout, if obtainable</li>
              <li>
                Current year's complete income tax return transcript with the
                schedule C, if self-employed
              </li>
              <li>
                DSS letter, if foster child(ren) in the household (income for
                foster child)
              </li>
            </ul>
          </ConfirmationContentContainer>
          <ConfirmationFooterContainer>
            <OutlineButton
              color="yellow"
              onClick={() => setShowApplicableDocuments(false)}
            >
              Close
            </OutlineButton>
          </ConfirmationFooterContainer>
        </ConfirmationModal>
      )}

      {showUploadModal && <FileUploadModal onAction={handleFileModalAction} />}

      {showFailNotification && (
        <>
          <FailureNotification
            title="Remove failed"
            message="Something went wrong. Please try again."
            afterShow={() => {
              if (failNotificationTimeout.current) {
                clearTimeout(failNotificationTimeout.current);
              }

              failNotificationTimeout.current = setTimeout(() => {
                setShowFailNotification(false);
              }, 5000);
            }}
          />
        </>
      )}
    </>
  );
};

interface IFileUploadModalProps {
  onAction: (file?: ApplicationFile) => void;
}

function validateFileUpload(values: UserFile): FormikErrors<UserFile> {
  const errors: FormikErrors<UserFile> = {};

  if (isEmpty(values.Description)) {
    errors.Description = RequiredMessage;
  }

  if (!values.File) {
    errors.File = RequiredMessage;
  }

  return errors;
}
const FileUploadModal: React.FC<IFileUploadModalProps> = (props) => {
  const [state] = useSharedFormState();
  const [showFailNotification, setShowFailNotification] =
    useState<boolean>(false);
  //use formik
  const formik = useFormik<UserFile>({
    initialValues: {
      File: undefined,
      Description: "",
    },
    validate: validateFileUpload,
    onSubmit: async (values: UserFile) => {
      setShowFailNotification(false);
      formik.setSubmitting(true);

      try {
        const file = await doFileUpload(values);
        props.onAction(file);
      } catch {
        setShowFailNotification(true);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const { getFieldProps, ErrorMessage } = useFormikHelpers<UserFile>(formik);

  async function doFileUpload(file: UserFile): Promise<ApplicationFile> {
    const axios = await getAxiosInstance();
    const formData = new FormData();

    formData.append("File", file.File as File);
    formData.append("description", file.Description);

    const uploadedFile = await axios.post<ApplicationFile>(
      `api/application/document/upload/${state.CurrentFormData.ApplicationId}`,
      formData
    );

    return uploadedFile.data;
  }

  return (
    <>
      <ConfirmationModal
        title="Upload Document"
        onClose={() => props.onAction()}
      >
        <ConfirmationContentContainer>
          <Label htmlFor="select-file">Select File</Label>
          <input
            type="file"
            className="w-full mt-1"
            accept="image/png, image/jpeg, .pdf"
            id="select-file"
            onChange={(e) => {
              formik.setFieldValue(
                "File",
                e.target.files ? e.target.files[0] : null
              );
            }}
            onBlur={(e) => {
              formik.setFieldTouched("File", true);
            }}
          />
          <ErrorMessage name="File" />

          <Label htmlFor="file-description" className="mt-3">
            Description
          </Label>
          <TextArea
            id="file-description"
            {...getFieldProps("Description")}
          ></TextArea>
          <ErrorMessage name="Description" />
        </ConfirmationContentContainer>
        <ConfirmationFooterContainer>
          <PrimaryButton
            color="regal-blue"
            onClick={formik.submitForm}
            loading={!!formik.isSubmitting}
          >
            Upload
          </PrimaryButton>
          <OutlineButton
            color="yellow"
            onClick={() => props.onAction()}
            loading={!!formik.isSubmitting}
          >
            Cancel
          </OutlineButton>
        </ConfirmationFooterContainer>
      </ConfirmationModal>

      {showFailNotification && (
        <>
          <Notification
            title="Upload failed"
            message="Something went wrong. Please try again."
            icon={<ExclamationIcon className="h-6 w-6 text-guardsman-red" />}
            afterShow={() => {
              setTimeout(() => {
                setShowFailNotification(false);
              }, 5000);
            }}
          />
        </>
      )}
    </>
  );
};
