import React, { useContext } from "react";
import { createContext, useState } from "react";
import { ApplicationFile } from "../../types/applicationFile";
import { AdditionalHouseholdMember } from "../steps/AdditionalHouseHoldMembers";

import { Demographics } from "../steps/DemographicsStep";
import { DisclosuresAndAgreements } from "../steps/DisclosuresAndAgreementsStep";
import { Household } from "../steps/HouseholdStep";
import { IncomeSource } from "../steps/IncomeStep";
import { MailingInformation } from "../steps/MailingInformationStep";
import { OtherContact } from "../steps/OtherContactStep";
import { PersonalInformation } from "../steps/PersonalInformationStep";
import { Race } from "../steps/RaceStep";
import { Residence } from "../steps/ResidenceStep";

export type DisplayState = "main-form" | "add-additional-member";
export const InProgressStatus = "In Progress";
export const NeedsDocumentationStatus = "Needs Documentation";
export interface IGlobalState {
  CurrentFormData: WatereeFormData;
  CurrentAdditionalMember: AdditionalHouseholdMember;
  displayState: DisplayState;
  //set the main form scroll top whenever display is changed to additional member.
  //we'll scroll back when display is toggled back to main form
  mainFormScrollTop?: number;
  currentStep: number;
  additionalMemberCurrentStep: number;
  /**This is set to true to allow edits during statuses other than 'In Progress' (like 'Needs Documentation') */
  isEditingAfterSubmission: boolean;
}

export type WatereeFormData = {
  ApplicationId: number;
  PersonalInformation: PersonalInformation;
  MailingInformation: MailingInformation;
  Demographics: Demographics;
  Household: Household;
  Race: Race;
  Income: IncomeSource;
  Residence: Residence;
  OtherContact: OtherContact;
  AdditionalMembers: AdditionalHouseholdMember[];
  Files: ApplicationFile[];
  DisclosureAgreement: DisclosuresAndAgreements;
  status: string;
  statusDescription: string;
  dateSubmitted?: string;
};

const defaultFormData: WatereeFormData = {
  ApplicationId: 0,
  status: "in-progress",
  statusDescription: "",
  PersonalInformation: {
    AssistanceType: [],
    FirstName: "",
    LastName: "",
    SSN: "",
    DateOfBirth: "",
    Email: "",
    HomePhone: "",
    CellPhone: "",
    DriversLicense: "",
    DriversLicenseExpiration: "",
    PersonsInHousehold: 1,
  },
  MailingInformation: {
    PhysicalAddress: "",
    PhysicalCity: "",
    PhysicalCounty: "",
    PhysicalZip: "",
    MailingAddress: "",
    MailingCity: "",
    MailingCounty: "",
    MailingZip: "",
  },
  Demographics: {
    Gender: "",
    DisablingCondition: "",
    MaritalStatus: "",
    PrimaryLanguage: "",
  },
  Household: {
    HouseholdType: "",
    HousingType: "",
    Education: "",
    MilitaryStatus: "",
    HealthInsurance: "",
    HealthInsuranceType: "",
    Relationship: "",
  },
  Race: {
    Race: "",
    Ethnicity: "",
    Tribe: "",
    WorkAtWCAI: "",
    FamilyAtWCAI: "",
  },
  Income: {
    IncomeSources: [],
    FoodStampAmount: 0,
    AssistanceSources: [],
    FoodAssistanceAmount: 0,
    ShelterAssistanceAmount: 0,
    UtilitiesAssistanceAmount: 0,
  },
  Residence: {
    NumberOfSmokers: "",
    ApproximateAge: "",
    NumberOfStories: "",
    MonthlyRent: "",
    StructureType: "",
    DwellingType: "",
    LocationType: "",
    YearOfConstruction: "",
    HasSmokers: "",
    HasBeenWeatherized: "",
    YearWeatherized: "",
  },
  OtherContact: {
    Name: "",
    Phone: "",
    Relationship: "",
  },
  AdditionalMembers: [],
  Files: [],
  DisclosureAgreement: {
    Relationships: "",
    Agreement: [],
  },
};

export const defaultAdditionalMember: AdditionalHouseholdMember = {
  id: null,
  isComplete: false,
  PersonalInformation: {
    FirstName: "",
    LastName: "",
    SocialSecurity: "",
    BirthDate: "",
  },
  Demographics: {
    Gender: "",
    DisablingCondition: "",
    MaritalStatus: "",
    PrimaryLanguage: "",
  },
  Household: {
    HouseholdType: "",
    HousingType: "",
    Education: "",
    MilitaryStatus: "",
    HealthInsurance: "",
    HealthInsuranceType: "",
    Relationship: "",
  },
  Race: {
    Race: "",
    Ethnicity: "",
    Tribe: "",
    WorkAtWCAI: "",
    FamilyAtWCAI: "",
  },
  Income: {
    IncomeSources: [],
    FoodStampAmount: 0,
    FoodAssistanceAmount: 0,
    ShelterAssistanceAmount: 0,
    UtilitiesAssistanceAmount: 0,
    AssistanceSources: [],
  },
};

const defaultGlobalState: IGlobalState = {
  CurrentFormData: defaultFormData,
  CurrentAdditionalMember: defaultAdditionalMember,
  displayState: "main-form",
  currentStep: 0,
  additionalMemberCurrentStep: 0,
  isEditingAfterSubmission: false,
};

//describe state
const useFormState = () => useState<IGlobalState>(defaultGlobalState);

//create context
const FormStateContext =
  createContext<ReturnType<typeof useFormState> | null>(null);

//create provider
export const FormStateProvider: React.FC = ({ children }) => (
  <FormStateContext.Provider value={useFormState()}>
    {children}
  </FormStateContext.Provider>
);

//easy hook to consume state in children of SharedStateProvider
export const useSharedFormState = () => {
  const value = useContext(FormStateContext);
  if (value === null) throw new Error("Please add SharedStateProvider");
  return value;
};
