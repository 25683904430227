import React from "react";

export interface IListContainerProps {
  className?: string;
  noChildrenText?: string | JSX.Element;
}

export const ListContainer: React.FC<IListContainerProps> = (props) => {
  if (React.Children.count(props.children) === 0 && props.noChildrenText) {
    return typeof props.noChildrenText === "string" ? (
      <p className="my-10 pl-5 italic">{props.noChildrenText}</p>
    ) : (
      props.noChildrenText
    );
  }

  return (
    <div
      className={`container px-0 flex py-4 items-center ${
        props.className || ""
      }`}
    >
      <ul className="flex flex-col pl-0">{props.children}</ul>
    </div>
  );
};

export interface IListItemProps {
  id?: string;
  mainText: string;
  subText?: string;
  rightElement?: JSX.Element;
  iconContainer?: JSX.Element;
  onClick?: () => void;
  error?: boolean;
}

export const ListItem: React.FC<IListItemProps> = (props) => {
  return (
    <li
      id={props.id}
      className={`${
        props.error ? "border-red-600" : "border-gray-200"
      } border rounded-lg flex flex-row mb-2 p-3 transition duration-500 ease-in-out transform hover:shadow-lg`}
      onClick={props.onClick}
    >
      <div className="select-none cursor-pointer rounded-md flex flex-1 items-center">
        {props.iconContainer}
        <div className="flex-1 pl-1 pr-5">
          <div className="font-medium break-all">{props.mainText}</div>
          <div className="text-gray-600 text-sm break-all">{props.subText}</div>
        </div>
        <div className="text-xs flex-shrink-1 justify-self-end">
          {props.rightElement}
        </div>
      </div>
    </li>
  );
};

export const ListItemIconContainer: React.FC = (props) => {
  return (
    <div className="flex flex-col flex-none rounded-md w-10 h-10 justify-center items-center mr-4">
      {props.children}
    </div>
  );
};
