import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import { isEmpty, RequiredMessage } from "../validation";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import React from "react";
import {
  ConfirmationContentContainer,
  ConfirmationFooterContainer,
  ConfirmationModal,
} from "../ConfirmationModal";
import { Label, TextArea } from "../formComponents";
import { OutlineButton } from "../Buttons";
import { ApplicationFile, ApplicationFileCategory } from "../../types/applicationFile";
import { useToken } from "../AppointmenUploadsApp/useToken";

type UserFile = {
  description: string;
  file?: File;
  category: ApplicationFileCategory;
};

export interface IApplicationFileUploadModalProps {
  onAction: (file?: ApplicationFile) => void;
  category: ApplicationFileCategory;
}

function validateFileUpload(values: UserFile): FormikErrors<UserFile> {
  const errors: FormikErrors<UserFile> = {};

  if (isEmpty(values.description)) {
    errors.description = RequiredMessage;
  }

  if (!values.file || !values.file.name) {
    errors.file = RequiredMessage;
  }
  else if (/(\.pdf|\.jpg|\.jpeg|\.png)$/.test(values.file?.name || '') === false) {
    errors.file = "Only PDF, JPG, JPEG, or PNG file types supported";
  }

  return errors;
}
export const ApplicationFileUploadModal: React.FC<
  IApplicationFileUploadModalProps
> = (props) => {
  const { token } = useToken();
  //use formik
  const formik = useFormik<UserFile>({
    initialValues: {
      file: {} as File,
      description: "",
      category: props.category,
    },
    validate: validateFileUpload,
    onSubmit: async (values: UserFile) => {
      formik.setSubmitting(true);

      try {
        const file = await doFileUpload(values);
        props.onAction(file);
      } catch {
        window.alert("Failed to upload document. Please try again");
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const { getFieldProps, ErrorMessage } = useFormikHelpers<UserFile>(formik);

  async function doFileUpload(file: UserFile): Promise<ApplicationFile> {
    const formData = new FormData();

    formData.append("file", file.file as File);
    formData.append("description", file.description);
    formData.append("category", file.category.toString());

    const uploadedFile = await axios.post<ApplicationFile>(
      `api/applicationfiles/file/${token}`,
      formData
    );

    return uploadedFile.data;
  }

  return (
    <>
      <ConfirmationModal
        title="Upload Document"
        onClose={() => props.onAction()}
      >
        <ConfirmationContentContainer>
          <Label htmlFor="select-file">Select File</Label>
          <input
            type="file"
            className="w-full mt-1"
            accept="image/png, image/jpeg, .pdf"
            id="select-file"
            onChange={(e) => {
              formik.setFieldValue(
                "file",
                e.target.files ? e.target.files[0] : null
              );
            }}
            onBlur={(e) => {
              formik.setFieldTouched("file", true);
            }}
          />
          <ErrorMessage name="file" />

          <Label htmlFor="file-description" className="mt-3">
            Description
          </Label>
          <TextArea
            id="file-description"
            {...getFieldProps("description")}
          ></TextArea>
          <ErrorMessage name="description" />
        </ConfirmationContentContainer>
        <ConfirmationFooterContainer>
          <OutlineButton
            color="indigo"
            onClick={formik.submitForm}
            loading={!!formik.isSubmitting}
          >
            Upload
          </OutlineButton>
          <OutlineButton
            color="yellow"
            onClick={() => props.onAction()}
            disabled={!!formik.isSubmitting}
          >
            Cancel
          </OutlineButton>
        </ConfirmationFooterContainer>
      </ConfirmationModal>
    </>
  );
};
