export interface ApplicationFile {
  id: number;
  applicationID: number;
  fileID: string;
  fileName: string;
  fileType: string;
  shareURL: string;
  description: string;
  agencyCode?: number;
  fileCategory?: ApplicationFileCategory;
}

export enum ApplicationFileCategory {
  ID = 0,
  Citizenship = 1,
  Income = 2,
  Utility = 3,
  Other = 4,
}
