import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrimaryButton } from "../Buttons";
import { useRequest } from "../hooks/useApi";
import { BaseLayout } from "../Layout";
import { BaseNavMenu } from "../NavMenu";
import { Spinner } from "../Spinner";
import { useToken } from "../AppointmenUploadsApp/useToken";
import { StepStateProvider, useStepState } from "../AppointmenUploadsApp/stepStore";
import useStepper from "../AppointmenUploadsApp/useStepper";
import { Applications_AM } from "../../types/applicationAM";
import { ApplicationFilesProvider, useSetApplicationFilesState } from "./applicationStore";
import { useApplicationFiles } from "./useApplicationFiles";
import { GenericOverviewStep, GenericPhotoIdStep, GenericCitizenshipStep, GenericIncomeStep, GenericUtilityStep, GenericOtherDocumentsStep, EmailStep, ReviewStep } from "./GenericUploadSteps";

/**spinner to drop in main content areas */
const ContentBodySpinner: React.FC = () => {
  return (
    <div className="flex h-full align-items-center justify-center">
      <Spinner className="h-36 w-36" />
    </div>
  );
};

/**app to render for invalid routes / tokens */
export const Token404Page: React.FC = () => {
  return (
    <MainLayout>
      <div className="prose prose-lg pt-5">
        <h1 className="text-slate-800">404</h1>
        <h3>Something is broken</h3>
        <p>
          The link you clicked is invalid or expired.
        </p>        
      </div>
    </MainLayout>
  );
};

export interface IMainFooterProps {
  disableStepper?: boolean;
}

export const MainFooter: React.FC<
  IMainFooterProps
> = (props) => {
  const stepState = useStepState();
  const { stepUp, stepDown } = useStepper();

  return (
    <>
      {/**footer */}
      <div className="py-3 flex justify-end gap-3">
        {stepState.step > 0 && (
          <PrimaryButton
            color="gray"
            className="min-w-[64px]"
            disabled={!!props.disableStepper}
            onClick={stepDown}
          >
            Previous
          </PrimaryButton>
        )}

        {stepState.step < stepState.steps.length - 1 && (
          <PrimaryButton
            color="indigo"
            className="min-w-[64px]"
            disabled={!!props.disableStepper}
            onClick={stepUp}
          >
            Next
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

/**app to render and manage showing each upload step */
const ApplicationUploads: React.FC = () => {
  const stepState = useStepState();
  const { doStep } = useStepper();

  const [fileResponse] = useApplicationFiles(true);

  useEffect(() => {
    if (stepState.hasStepped) {
      $(document).scrollTop(0);
    }
  }, [stepState.step]);

  return (
    <>
      {fileResponse.isLoading && <ContentBodySpinner />}

      {fileResponse.isLoading === false && (
        <>
          {/**upload steps */}
          {stepState.steps[stepState.step]}

          {/**stepper */}
          <div className="py-3 flex justify-center align-items-center gap-1">
            {stepState.steps.map((s, i) => {
              return (
                <span
                  key={i}
                  className={`rounded-full h-4 w-4 bg-indigo-600 cursor-pointer ${
                    stepState.step === i ? "opacity-100" : "opacity-30"
                  }`}
                  onClick={() => {
                    doStep(i);
                  }}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

/**main layout for the appointment uploads app (has tops bar and content) */
const MainLayout: React.FC = (props) => {
  return (
    <BaseLayout
      navMenu={
        <BaseNavMenu
          title="My Application"
          titleFontClass="text-indigo-600"
          backgroundClass="bg-white"
        />
      }
      cdsLogoImageOnly={true}
    >
      {props.children}
    </BaseLayout>
  );
};

/**app that handles redirect to Token404App (leveraged in multiple places) */
export const RedirectTo404: React.FC = () => {
  return <Redirect to={{ pathname: "/v2/application/404" }} />;
};

/**app to render by main if token is found.  */
export const TokenFoundContainer: React.FC = () => {
  const { token } = useToken();
  const setState = useSetApplicationFilesState();

  /**use request hook to validate token. mark as immediate request. */
  const [validateTokenResult] = useRequest<Applications_AM>(
    {
      url: "/api/applicationfiles/validate/" + token,
    },
    true
  );

  //set app summary to global state after load
  useEffect(() => {
    if (
      !validateTokenResult.isLoading &&
      (validateTokenResult.data || validateTokenResult.error)
    ) {
      setState((prev) => ({
        ...prev,
        appSummary: validateTokenResult.data,
      }));
    }
  }, [setState, validateTokenResult.data, validateTokenResult.error, validateTokenResult.isLoading]);

  return (
    <MainLayout>
      {!validateTokenResult.isLoading && (
        <>
          {/**successfully loaded */}
          {validateTokenResult.data && (
            <div className="prose max-w-none py-5">
              <StepStateProvider
                step={0}
                hasStepped={false}
                steps={[
                  <GenericOverviewStep />,
                  <GenericPhotoIdStep />,
                  <GenericCitizenshipStep />,
                  <GenericIncomeStep />,
                  <GenericUtilityStep />,
                  <GenericOtherDocumentsStep />,
                  <EmailStep />,
                  <ReviewStep />,
                ]}
              >
                <ApplicationUploads />
              </StepStateProvider>
            </div>
          )}

          {/**error from request or no data returned */}
          {(validateTokenResult.error || !validateTokenResult.data) && (
            <RedirectTo404 />
          )}
        </>
      )}

      {/** spinner while loading */}
      {validateTokenResult.isLoading && <ContentBodySpinner />}
    </MainLayout>
  );
};

/**main app for appointment uploads. handles primary routes. */
export const ApplicationsUploadApp: React.FC = () => {
  useEffect(() => {
    document.title = "My Application";
  }, []);

  return (
    <Switch>
      <Route path={"/v2/application/files/:token"}>
        <ApplicationFilesProvider>
          <TokenFoundContainer />
        </ApplicationFilesProvider>
      </Route>
      <Route path={"/v2/application/404"}>
        <Token404Page />
      </Route>      
    </Switch>
  );
};
