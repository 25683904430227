import { FormikErrors, useFormik } from "formik";
import React, { useRef } from "react";
import { Label, TextInput } from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { StepperBlock } from "../Stepper";
import { isEmpty, RequiredMessage } from "../validation";

export type OtherContact = {
  Name: string;
  Phone: string;
  Relationship: string;
};

function validateOtherContact(
  values: OtherContact
): FormikErrors<OtherContact> {
  const errors: FormikErrors<OtherContact> = {};

  if (isEmpty(values.Name)) {
    errors.Name = RequiredMessage;
  }

  if (isEmpty(values.Phone)) {
    errors.Phone = RequiredMessage;
  }

  if (isEmpty(values.Relationship)) {
    errors.Relationship = RequiredMessage;
  }

  return errors;
}

export interface IOtherContactStepProps {
  onBack: () => void;
  onNext: (data: OtherContact) => Promise<void>;
  onSaveAndComplete?: (data: OtherContact) => Promise<void>;
  initialValues: OtherContact;
}

export const OtherContactStep: React.FC<IOtherContactStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  //spinup formik
  const formik = useFormik<OtherContact>({
    initialValues: props.initialValues,
    enableReinitialize: true,
    onSubmit: (values: OtherContact) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
    validate: validateOtherContact,
  });

  //get helpers
  const { getFieldProps, ErrorMessage } =
    useFormikHelpers<OtherContact>(formik);

  return (
    <StepperBlock
      onBack={props.onBack}
      onNext={() => {
        completeOrNext.current = "next";
        formik.submitForm();
      }}
      onSaveAndComplete={() => {
        completeOrNext.current = "complete";
        formik.submitForm();
      }}
      isLoading={formik.isSubmitting}
    >
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="other_contact_name">Name</Label>
        <TextInput
          type="text"
          autoComplete="name"
          id="other_contact_name"
          {...getFieldProps("Name")}
        />
        <ErrorMessage name="Name" />
      </div>

      {/**spacer */}
      <div className="hidden sm:col-span-2"></div>

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="phone_other_contact">Phone</Label>
        <TextInput
          type="text"
          autoComplete="tel"
          id="phone_other_contact"
          {...getFieldProps("Phone")}
        />
        <ErrorMessage name="Phone" />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="relationship_other_contact">Relationship</Label>
        <TextInput
          id="relationship_other_contact"
          type="text"
          {...getFieldProps("Relationship")}
        />
        <ErrorMessage name="Relationship" />
      </div>
    </StepperBlock>
  );
};
