import moment from "moment";

//messages to use
export const RequiredMessage = "Required";
export const InvalidDateMessage = "Invalid date";

/**
 * Required validation for a string.
 * @param value string to validate
 * @returns message if invalid; empty if valid.
 */
export function isEmpty(
  value?: string | string[] | number | number[]
): boolean {
  if (value === undefined || value === null) {
    return true;
  }
  if (Array.isArray(value) && !value.length) {
    return true;
  } else if (
    typeof value === "string" &&
    (!value || value.replace(/\s+/g, "") === "")
  ) {
    return true;
  }

  return false;
}

export function parseNumber(value: number | string) {
  if (typeof value === "number") {
    return value;
  }

  return parseFloat(value);
}

/**
 * Greater than validation for a number
 * @param value number to validate
 * @param greaterThan number to compare to
 * @returns message if invalid; empty if valid
 */
export function numGreaterThan(
  value: number | string,
  greaterThan: number
): boolean {
  if (value === undefined || isNaN(parseNumber(value))) {
    return false;
  }

  if (value > greaterThan) {
    return true;
  }

  return false;
}

/**
 * Date format validation
 * @param value the date string to test
 * @returns message if improper format and/or date value; empty if valid
 */
export function isValidDate(value: string): boolean {
  var m = moment(value, "MM/DD/YYYY", true);

  if (m.isValid()) {
    return true;
  }

  return false;
}

/**
 * Check if a year is properly provided as a 4 digit format
 * @param value
 * @returns
 */
export function isValidYear(value?: string): boolean {
  if (value === undefined) {
    return false;
  }
  var m = moment(value, "YYYY", true);

  return m.isValid();
}

export function isValidSSN(value: string): boolean {
  if (!value) {
    return false;
  }
  return /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(
    value
  );
}

export function isValidPhone(value: string): boolean {
  if (!value) {
    return true;
  }
  return /\d{3}-{0,1}\d{3}-{0,1}\d{4}/.test(value);
}

export function isValidEmail(value: string) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
}
