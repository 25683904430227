import { AdditionalHouseholdMember } from "../../components/steps/AdditionalHouseHoldMembers";
import { WatereeFormData } from "../../components/store/store";
import { AddEditOtherHouseholdMemberDtos } from "../addEditOtherHouseholdMemberDtos";
import { Application } from "../application";
import { EditApplicationDtos } from "../editApplicationDtos";
import { OtherHouseholdMember } from "../otherHouseholdMember";
import { parseBooleanYesNo, formatBooleanYesNo } from "./booleanHelpers";
import {
  formatClientDateForPost,
  formatServerDateForDisplay,
} from "./dateHelpers";

export function mapCurrentFormToEditApplicationDtos(
  currentForm: WatereeFormData,
  currentStep: number
): EditApplicationDtos {
  return {
    applicationId: currentForm.ApplicationId,
    ssn: currentForm.PersonalInformation.SSN,
    dateOfBirth: formatClientDateForPost(
      currentForm.PersonalInformation.DateOfBirth
    ),
    firstName: currentForm.PersonalInformation.FirstName,
    lastName: currentForm.PersonalInformation.LastName,
    physicalAddress: currentForm.MailingInformation.PhysicalAddress,
    physicalCity: currentForm.MailingInformation.PhysicalCity,
    physicalCounty: currentForm.MailingInformation.PhysicalCounty,
    physicalZip: currentForm.MailingInformation.PhysicalZip,
    mailingAddress: currentForm.MailingInformation.MailingAddress,
    mailingCity: currentForm.MailingInformation.MailingCity,
    mailingCounty: currentForm.MailingInformation.MailingCounty,
    mailingZip: currentForm.MailingInformation.MailingZip,
    driversLicense: currentForm.PersonalInformation.DriversLicense,
    driversLicenseExpiration: formatClientDateForPost(
      currentForm.PersonalInformation.DriversLicenseExpiration
    ),
    homePhone: currentForm.PersonalInformation.HomePhone,
    cellPhone: currentForm.PersonalInformation.CellPhone,
    email: currentForm.PersonalInformation.Email,
    personsInHousehold: currentForm.PersonalInformation.PersonsInHousehold,
    gender: currentForm.Demographics.Gender,
    disablingCondition: currentForm.Demographics.DisablingCondition,
    maritalStatus: currentForm.Demographics.MaritalStatus,
    householdType: currentForm.Household.HouseholdType,
    housingType: currentForm.Household.HousingType,
    educationHighestGradeCompleted: currentForm.Household.Education,
    militaryStatus: currentForm.Household.MilitaryStatus,
    healthInsuranceYN: parseBooleanYesNo(
      currentForm.Household.HealthInsurance
    ) as boolean,
    healthInsurance: currentForm.Household.HealthInsuranceType,
    race: currentForm.Race.Race,
    ethnicity: currentForm.Race.Ethnicity,
    tribe: currentForm.Race.Tribe,
    workAtWatereeYN: parseBooleanYesNo(currentForm.Race.WorkAtWCAI) as boolean,
    familyWorkAtWatereeYN: parseBooleanYesNo(
      currentForm.Race.FamilyAtWCAI
    ) as boolean,
    foodStamps: currentForm.Income.FoodStampAmount,
    noIncomeFoodTotal: currentForm.Income.FoodAssistanceAmount,
    noIncomeShelterTotal: currentForm.Income.ShelterAssistanceAmount,
    noIncomeUtilitiesTotal: currentForm.Income.UtilitiesAssistanceAmount,
    residenceStructureType: currentForm.Residence.StructureType,
    residenceNumStories: currentForm.Residence.NumberOfStories as number,
    dwellingType: currentForm.Residence.DwellingType,
    doYouLiveIn: currentForm.Residence.LocationType,
    yearOfHomeConstruction: currentForm.Residence.YearOfConstruction,
    homeApproximateAge: currentForm.Residence.ApproximateAge as number,
    monthlyRentMortgage: currentForm.Residence.MonthlyRent as number,
    doesGovernmentPay: null, //don't know what this is?
    smokersYN: parseBooleanYesNo(currentForm.Residence.HasSmokers),
    smokersTotal: currentForm.Residence.NumberOfSmokers as number,
    dwellingWeatherizedYN: parseBooleanYesNo(
      currentForm.Residence.HasBeenWeatherized
    ),
    dwellingWeatherizedWhen: currentForm.Residence.YearWeatherized,
    primaryLanguage: currentForm.Demographics.PrimaryLanguage,
    noIncomeSourceFamily: currentForm.Income.AssistanceSources.some(
      (a) => a === "family"
    ),
    noIncomeSourceFriend: currentForm.Income.AssistanceSources.some(
      (a) => a === "friend"
    ),
    noIncomeSourceChurch: currentForm.Income.AssistanceSources.some(
      (a) => a === "church"
    ),
    noIncomeSourceNonprofit: currentForm.Income.AssistanceSources.some(
      (a) => a === "nonprofit"
    ),
    noIncomeSourceOther: currentForm.Income.AssistanceSources.some(
      (a) => a === "other"
    ),
    applicationPrograms: currentForm.PersonalInformation.AssistanceType,
    otherContactName: currentForm.OtherContact.Name,
    otherContactPhone: currentForm.OtherContact.Phone,
    otherContactRelationship: currentForm.OtherContact.Relationship,
    publicFormCurrentStep: currentStep,
    relationships: currentForm.DisclosureAgreement.Relationships,
  };
}

export function mapApplicationDomainToState(
  application: Application
): WatereeFormData {
  return {
    dateSubmitted: application.dateReceived as string,
    ApplicationId: application.applicationID,
    PersonalInformation: {
      AssistanceType: application.applicationPrograms.map((x) => x.programId), //map program ids out that we've selected
      FirstName: application.firstName,
      LastName: application.lastName,
      SSN: application.ssn,
      DateOfBirth: formatServerDateForDisplay(
        application.dateOfBirth as string
      ),
      Email: application.email,
      HomePhone: application.homePhone,
      CellPhone: application.cellPhone,
      DriversLicense: application.driversLicense,
      DriversLicenseExpiration: formatServerDateForDisplay(
        application.driversLicenseExpiration as string
      ),
      PersonsInHousehold: application.personsInHousehold as number,
    },
    MailingInformation: {
      PhysicalAddress: application.physicalAddress,
      PhysicalCity: application.physicalCity,
      PhysicalCounty: application.physicalCounty,
      PhysicalZip: application.physicalZip,
      MailingAddress: application.mailingAddress,
      MailingCity: application.mailingCity,
      MailingCounty: application.mailingCounty,
      MailingZip: application.mailingZip,
    },
    Demographics: {
      Gender: application.gender,
      DisablingCondition: application.disablingCondition,
      MaritalStatus: application.maritalStatus,
      PrimaryLanguage: application.primaryLanguage,
    },
    Household: {
      HouseholdType: application.householdType,
      HousingType: application.housingType,
      Education: application.educationHighestGradeCompleted,
      MilitaryStatus: application.militaryStatus,
      HealthInsurance: formatBooleanYesNo(application.healthInsuranceYN),
      HealthInsuranceType: application.healthInsurance,
      Relationship: "",
    },
    Race: {
      Race: application.race,
      Ethnicity: application.ethnicity,
      Tribe: application.tribe,
      WorkAtWCAI: formatBooleanYesNo(application.workAtWatereeYN),
      FamilyAtWCAI: formatBooleanYesNo(application.familyWorkAtWatereeYN),
    },
    Income: {
      IncomeSources: application.incomeSources,
      FoodStampAmount: application.foodStamps as unknown as number,
      AssistanceSources: mapAssistanceIncomeAssistanceSources(application),
      FoodAssistanceAmount: application.noIncomeFoodTotal as number,
      ShelterAssistanceAmount: application.noIncomeShelterTotal as number,
      UtilitiesAssistanceAmount: application.noIncomeUtilitiesTotal as number,
    },
    Residence: {
      StructureType: application.residenceStructureType,
      NumberOfStories: application.residenceNumStories as number,
      DwellingType: application.dwellingType,
      LocationType: application.doYouLiveIn,
      YearOfConstruction: application.yearOfHomeConstruction,
      ApproximateAge: application.homeApproximateAge as number,
      MonthlyRent: application.monthlyRentMortgage as number,
      HasSmokers: formatBooleanYesNo(application.smokersYN),
      NumberOfSmokers: application.smokersTotal as number,
      HasBeenWeatherized: formatBooleanYesNo(application.dwellingWeatherizedYN),
      YearWeatherized: application.dwellingWeatherizedWhen,
    },
    OtherContact: {
      Name: application.otherContactName,
      Phone: application.otherContactPhone,
      Relationship: application.otherContactRelationship,
    },
    AdditionalMembers: mapAdditionalMembersFromApplicationDomain(application),
    Files: application.files,
    DisclosureAgreement: {
      Relationships: application.relationships,
      Agreement: (function () {
        if (application.dateReceived) {
          return ["yes"];
        }

        return ["no"];
      })(),
    },
    status: application.status,
    statusDescription: application.statusDescription,
  };
}

function mapAssistanceIncomeAssistanceSources(
  application: Application
): string[] {
  const values: string[] = [];

  if (application.noIncomeSourceChurch) {
    values.push("church");
  }

  if (application.noIncomeSourceFamily) {
    values.push("family");
  }

  if (application.noIncomeSourceFriend) {
    values.push("friend");
  }

  if (application.noIncomeSourceNonprofit) {
    values.push("nonprofit");
  }

  if (application.noIncomeSourceOther) {
    values.push("other");
  }

  return values;
}

function mapAdditionalMembersFromApplicationDomain(
  application: Application
): AdditionalHouseholdMember[] {
  return application.otherHouseholdMembers.map((member) => {
    return {
      id: member.otherHouseholdMemberID,
      isComplete: member.isComplete,
      PersonalInformation: {
        FirstName: member.firstName,
        LastName: member.lastName,
        BirthDate: formatServerDateForDisplay(member.dateOfBirth as string),
        SocialSecurity: member.ssn,
      },
      Demographics: {
        Gender: member.gender,
        DisablingCondition: member.disablingCondition,
        MaritalStatus: member.maritalStatus,
        PrimaryLanguage: member.primaryLanguage,
      },
      Household: {
        HouseholdType: member.householdType,
        HousingType: member.housingType,
        Relationship: member.relationship,
        Education: member.educationHighestGradeCompleted,
        MilitaryStatus: member.militaryStatus,
        HealthInsurance: formatBooleanYesNo(member.healthInsuranceYN),
        HealthInsuranceType: member.healthInsurance,
      },
      Race: {
        Race: member.race,
        Ethnicity: member.ethnicity,
        Tribe: member.tribe,
        WorkAtWCAI: "",
        FamilyAtWCAI: "",
      },
      Income: {
        FoodAssistanceAmount: 0,
        FoodStampAmount: 0,
        AssistanceSources: [],
        ShelterAssistanceAmount: 0,
        UtilitiesAssistanceAmount: 0,
        IncomeSources: member.incomeSources,
      },
    };
  });
}

export function mapCurrentAdditionalMemberToDtos(
  member: AdditionalHouseholdMember
): AddEditOtherHouseholdMemberDtos {
  return {
    otherHouseholdMemberID: member.id as number,
    ssn: member.PersonalInformation.SocialSecurity,
    dateOfBirth: formatClientDateForPost(member.PersonalInformation.BirthDate),
    firstName: member.PersonalInformation.FirstName,
    lastName: member.PersonalInformation.LastName,
    gender: member.Demographics.Gender,
    disablingCondition: member.Demographics.DisablingCondition,
    maritalStatus: member.Demographics.MaritalStatus,
    householdType: member.Household.HouseholdType,
    housingType: member.Household.HousingType,
    educationHighestGradeCompleted: member.Household.Education,
    militaryStatus: member.Household.MilitaryStatus,
    healthInsuranceYN: parseBooleanYesNo(
      member.Household.HealthInsurance
    ) as boolean,
    healthInsurance: member.Household.HealthInsuranceType,
    race: member.Race.Race,
    ethnicity: member.Race.Ethnicity,
    tribe: member.Race.Tribe,
    incomeSources: member.Income.IncomeSources,
    primaryLanguage: member.Demographics.PrimaryLanguage,
    isComplete: member.isComplete,
    relationship: member.Household.Relationship,
  };
}

export function mapOtherHouseholdMemberDomainToClient(
  otherHouseholdMember: OtherHouseholdMember
): AdditionalHouseholdMember {
  return {
    id: otherHouseholdMember.otherHouseholdMemberID,
    isComplete: otherHouseholdMember.isComplete,
    PersonalInformation: {
      FirstName: otherHouseholdMember.firstName,
      LastName: otherHouseholdMember.lastName,
      BirthDate: formatServerDateForDisplay(
        otherHouseholdMember.dateOfBirth as string
      ),
      SocialSecurity: otherHouseholdMember.ssn,
    },
    Demographics: {
      Gender: otherHouseholdMember.gender,
      DisablingCondition: otherHouseholdMember.disablingCondition,
      PrimaryLanguage: otherHouseholdMember.primaryLanguage,
      MaritalStatus: otherHouseholdMember.maritalStatus,
    },
    Household: {
      HouseholdType: otherHouseholdMember.householdType,
      HousingType: otherHouseholdMember.housingType,
      Education: otherHouseholdMember.educationHighestGradeCompleted,
      MilitaryStatus: otherHouseholdMember.militaryStatus,
      HealthInsurance: formatBooleanYesNo(
        otherHouseholdMember.healthInsuranceYN
      ),
      HealthInsuranceType: otherHouseholdMember.healthInsurance,
      Relationship: otherHouseholdMember.relationship,
    },
    Race: {
      Race: otherHouseholdMember.race,
      Ethnicity: otherHouseholdMember.ethnicity,
      Tribe: otherHouseholdMember.tribe,
      WorkAtWCAI: "",
      FamilyAtWCAI: "",
    },
    Income: {
      IncomeSources: otherHouseholdMember.incomeSources,
      AssistanceSources: [],
      FoodAssistanceAmount: 0,
      FoodStampAmount: 0,
      ShelterAssistanceAmount: 0,
      UtilitiesAssistanceAmount: 0,
    },
  };
}
