import { DocumentIcon } from "@heroicons/react/outline";
import axios from "axios";
import { format, parse, parseISO } from "date-fns";
import { FormikErrors, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  AppointmentFile,
  AppointmentFileCategory,
} from "../../types/appointmentFile";
import { OutlineButton } from "../Buttons";
import { TextInput } from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { ListContainer, ListItem, ListItemIconContainer } from "../Lists";
import { isEmpty, isValidEmail } from "../validation";
import { AppointmentFileUploadModal } from "./AppointmentFileUploadModal";
import {
  useAppointmentsGlobalState,
  useSetAppointmentsGlobalState,
} from "./appointmentStore";
import { AppointmentUploadsFooter } from "./AppointmentUploadsApp";
import {
  GenericCitizenshipStep,
  GenericIncomeStep,
  GenericOtherDocumentsStep,
  GenericOverviewStep,
  GenericPhotoIdStep,
  GenericUtilityStep,
} from "./GenericUploadSteps";
import { useToken } from "./useToken";

/**Upload button to leverge on individual step pages.
 * Manages the upload modal and targets the provided category for uploads.
 */
export const UploadButton: React.FC<{ category: AppointmentFileCategory }> = (
  props
) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const setAppointmentState = useSetAppointmentsGlobalState();

  function onAction(file?: AppointmentFile) {
    if (file) {
      //add file to local data!
      setAppointmentState((prev) => ({
        ...prev,
        files: [...prev.files, file],
      }));
    }
    setShowUpload(false);
  }
  return (
    <>
      <OutlineButton color="indigo" onClick={() => setShowUpload(true)}>
        {props.children}
      </OutlineButton>

      {showUpload && (
        <AppointmentFileUploadModal
          category={props.category}
          onAction={onAction}
        />
      )}
    </>
  );
};

/**
 * List item for an individual file.
 * Will display list item
 * Also controls 'remove' action
 */
const FileListItem: React.FC<{ file: AppointmentFile }> = (props) => {
  const f = props.file;
  const setGlobalState = useSetAppointmentsGlobalState();
  const { token } = useToken();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  /**deprecated and removed from the UI. Leaving this around for a minute in case they want it back. */
  function removeFile(id: number) {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true); //mark as deleting

    //hit api
    axios
      .delete(`/api/appointment/file/${token}/${id}`) //we deletin
      .then(() => {
        //if good update state and remove file
        setGlobalState((prev) => ({
          ...prev,
          //reduce files and omit the one we just deleted
          files: prev.files.reduce((arr, f) => {
            if (f.id !== id) {
              arr.push(f);
            }
            return arr;
          }, [] as AppointmentFile[]),
        }));
      })
      .catch(() => window.alert(`Failed to remove file: ${f.fileName}`)) //if bad window.alert
      .finally(() => setIsDeleting(false)); //always reset deleting flag
  }

  return (
    <ListItem
      key={f.id}
      mainText={f.fileName}
      subText={f.description}
      // rightElement={
      //   <>
      //     <a className="text-red-600" onClick={() => removeFile(f.id)}>
      //       {!isDeleting && <>REMOVE</>}
      //       {isDeleting && <Spinner />}
      //     </a>
      //   </>
      // }
      iconContainer={
        <ListItemIconContainer>
          <DocumentIcon />
        </ListItemIconContainer>
      }
    />
  );
};

/**Files container for listing the files in a given category.
 * Depends on the global state provider
 */
export const FilesContainer: React.FC<{ category: AppointmentFileCategory }> = (
  props
) => {
  const globalState = useAppointmentsGlobalState();
  const files = globalState.files.filter(
    (x) => x.fileCategory === props.category
  );

  return (
    <ListContainer
      noChildrenText={
        <>
          {props.category === AppointmentFileCategory.Other ? (
            <p className="py-2 italic">No documents uploaded</p>
          ) : (
            <p className="py-2 italic text-red-600">
              Warning: No files were uploaded to this required category.
            </p>
          )}
        </>
      }
      className="pt-2 pb-1"
    >
      {files.map((f) => (
        <FileListItem file={f} />
      ))}
    </ListContainer>
  );
};

/**Container for the content inside a '<details>' section. Applies some common styling */
const DetailsContent: React.FC = (props) => {
  return (
    <div className="prose prose-sm py-3 ml-5 sm:ml-8">{props.children}</div>
  );
};

export const OverviewStep: React.FC<{ listItems: string[] }> = (props) => {
  const state = useAppointmentsGlobalState();
  const time = format(
    parse(state.appSummary?.appTime || "", "HH:mm:ss", new Date()),
    "hh:mma"
  );
  const date = format(parseISO(state.appSummary?.appDate || ""), "MM/dd/yyyy");
  return (
    <>
      <h2>Overview</h2>
      <p>
        Upload required documents for your {state.appSummary?.program}{" "}
        appointment with {state.appSummary?.longClientName} scheduled for {time}{" "}
        on {date} at {state.appSummary?.siteName}
      </p>

      <p>
        <strong>
          This form will walk you through uploading these required documents:
        </strong>
      </p>
      <ul>
        {props.listItems.map((s, i) => (
          <li key={i}>{s}</li>
        ))}
      </ul>

      <p>You may be asked to upload additional documents as well.</p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const PhotoIdsStep: React.FC = (props) => {
  return (
    <>
      <h2>Photo IDs</h2>
      {props.children}
      <p>
        <UploadButton category={AppointmentFileCategory.ID}>
          Click to Upload Photo IDs
        </UploadButton>
      </p>
      <p>
        <strong>Uploaded Photo ID Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.ID} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const CitizenshipStep: React.FC = (props) => {
  return (
    <>
      <h2>Citizenship/Residency Documentation</h2>
      <p>
        Upload proof of U.S. citizenship/legal residency for{" "}
        <em>each household member</em>. The lists below explain the documents
        you can use as citizenship/legal residency.
      </p>

      <details>
        <summary>
          Click to see List of Proof of <strong>U.S. Citizenship</strong>{" "}
          Documents
        </summary>
        <DetailsContent>
          <>
            <p>
              For every U.S. citizen in your household provide one of the
              following:
            </p>
            <ul>
              <li>Social security card</li>
              <li>Birth certificate/hospital birth records</li>
              <li>Baptismal records (must show place and date of birth)</li>
              <li>Indian census record</li>
              <li>Military service record</li>
              <li>U.S. passport</li>
              <li>
                Verified citizenship for Ohio Works First {"(OWF)"} program
              </li>
              <li>Voter registration card</li>
            </ul>

            <p>
              <em>
                Please note: If you do not use social security cards as your
                citizenship documents, you will still need to provide the social
                security numbers of all members of your household at your
                appointment.
              </em>
            </p>
          </>
        </DetailsContent>
      </details>

      <details>
        <summary>
          Click to see List of Proof of <strong>Legal Residency</strong>{" "}
          Documents
        </summary>
        <DetailsContent>
          <>
            <p>
              For every permanent resident/qualified alien in your household
              provide one of the following:
            </p>

            <ul>
              <li>Naturalization Papers/Certifications of Citizenship</li>
              <li>INS ID Card</li>
              <li>Alien Registration Cards/Re-entry permits</li>
              <li>
                INS Form I-151 or I-551 (Form I-151 will not be valid after
                August 1, 1993)
              </li>
              <li>
                INS Form I-94 if annotated with either:{" "}
                {
                  "a) Sections 203(a)(7), 207, 208, 212(b)(3) of the Immigration and Nationality Act: or b) One or a combination of the following terms: Refugee, Parolee, or Asylee"
                }
              </li>
              <li>
                Permanent Visa INS Form G-641, “Application for verification of
                Information from INS Records”, when annotated at bottom by INS
                Representative as lawful admission for humanitarian reasons
              </li>
              <li>
                Documentation that alien is classified pursuant to Sections:{" "}
                {
                  "101(a)(2), 203(a), 204(a)(1)(a), 207, 208, 212(d)(5), 241(b)(3), 243(h), or 244(a)(3), of the Immigration and Nationality Act"
                }
              </li>
              <li>
                Court order stating that deportation has been witheld pursuant
                to Section{" "}
                {
                  "241(b)(3) or 243(h) or of the Immigration and Nationality Act"
                }
              </li>
              <li>INS Form I-688</li>
            </ul>
          </>
        </DetailsContent>
      </details>

      <p>
        <UploadButton category={AppointmentFileCategory.Citizenship}>
          Click to Upload Proof of U.S. Citizenship/Legal Residency
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Citizenship/Residency Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Citizenship} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const WatereeScCardStep: React.FC = (props) => {
  return (
    <>
      <h2>Social Security Cards</h2>
      <p>
        Upload copies of social security cards for all members of the household.
      </p>

      <p>
        <UploadButton category={AppointmentFileCategory.Citizenship}>
          Click to Upload Social Security Cards
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Social Security Card Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Citizenship} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const IncomeStep: React.FC = (props) => {
  return (
    <>
      <h2>Income Documentation</h2>
      <p>
        Upload proof of income for all income sources of all household members.
      </p>

      <details>
        <summary>
          Click to see List of Proof of <strong>Fixed Income</strong> Documents
        </summary>
        <DetailsContent>
          <p>
            <strong>Fixed income</strong> includes social security, supplemental
            security (SSI), social security disability insurance (SSDI), pension
            (private and VA), widow/widower’s benefit, alimony, and black lung
            pension.
          </p>
          <p>
            For every source of <strong>fixed income</strong> provide one of the
            following covering at least 30 days:
          </p>

          <ul>
            <li>Award/Benefit letter</li>
            <li>Payment printout/statement from issuing agency</li>
            <li>Copy of check or bank statement including deposit</li>
            <li>Most recent filed IRS Form 1040</li>
            <li>Most recent IRS form 1099</li>
          </ul>
        </DetailsContent>
      </details>

      <details>
        <summary>
          Click to see List of Proof of <strong>Earned Income</strong> Documents
        </summary>
        <DetailsContent>
          <p>
            <strong>Earned employment income</strong> includes wages and active
            military pay.
          </p>
          <p>
            For every source of <strong>earned income</strong> provide one of
            the following covering at least 30 days:
          </p>

          <ul>
            <li>
              All pay stubs recieved in the previous 30 days that include gross
              and year-to-date amounts received
            </li>
            <li>Completed and signed Employment Verification Form</li>
          </ul>
        </DetailsContent>
      </details>

      <details>
        <summary>
          Click to see List of Proof of <strong>Supplemental Income</strong>{" "}
          Documents
        </summary>
        <DetailsContent>
          <>
            <p>
              <strong>Supplemental income</strong> includes unemployment,
              utility assistance, worker’s compensation, Ohio Works First (TANF,
              ADC), employment disability payout, and strike benefit.
            </p>
            <p>
              For every source of <strong>supplemental income</strong> provide
              one of the following covering at least 30 days:
            </p>

            <ul>
              <li>Copy of check/award amount letter</li>
              <li>ODJFS documents/eligibility letter with amounts and dates</li>
              <li>Most recent IRS Form 1099</li>
              <li>Housing Authority Documentation</li>
              <li>Pay Stubs received within the past 30 days</li>
              <li>Payment printout/statement from issuing agency</li>
            </ul>
          </>
        </DetailsContent>
      </details>

      <details>
        <summary>
          Click to see List of Proof of <strong>Other Earned Income</strong>{" "}
          Documents
        </summary>
        <DetailsContent>
          <>
            <p>
              <strong>Other earned income includes</strong> self-employment
              (such as owning own business, babysitting, home party sales, odd
              jobs, Ohio Electronic Child Care, etc.) and seasonal-employment
              (such as teachers, construction workers, etc.)
            </p>

            <p>
              For every source of <strong>other earned income</strong> provide
              one of the following :
            </p>

            <ul>
              <li>
                Pay stubs indicating amount received within the past 12 months
              </li>
              <li>
                Self-Employment Income and Expense Form for the past 12 months
                (form can be found at Apply for Ohio Energy Assistance Programs)
              </li>
              <li>Most recent filed IRS Form 1040 and Schedules</li>
              <li>Most recent IRS Form 1099</li>
            </ul>
          </>
        </DetailsContent>
      </details>

      <details>
        <summary>
          Click to see List of Proof of <strong>Other Sources of Income</strong>{" "}
          Documents
        </summary>
        <DetailsContent>
          <>
            <p>
              <strong>Other Sources of income</strong> include cash withdrawn
              from IRAs / annuities / other investments, interest income, lump
              sum payouts (such as estate & trust settlements / divorce
              settlements / insurance payout / lottery winnings), and any other
              income source not covered by other categories.
            </p>
            <p>
              For every source of <strong>other earned income</strong> provide
              one of the following:
            </p>

            <ul>
              <li>
                Statement(s) from Financial Institution covering past 12 months.
              </li>
              <li>
                Copy of check or bank statements showing deposits for past 12
                months.
              </li>
              <li>Most recent IRS Form 1099</li>
              <li>
                Signed and dated letter from supporter describing support
                provided over past 12 months and including name, address, and
                phone number of supporter.
              </li>
            </ul>
          </>
        </DetailsContent>
      </details>

      <p>
        <UploadButton category={AppointmentFileCategory.Income}>
          Click to Upload Income Documentation
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Income Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Income} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const WatereeIncomeStep: React.FC = (props) => {
  return (
    <>
      <h2>Income Documentation</h2>
      <p>
        Upload proof of total household gross income for the past 30 days. (To
        include utility checks if applicable).
      </p>

      <details>
        <summary>Click to see list of proof of income documents</summary>
        <DetailsContent>
          <>
            <p>Upload any of the following that apply to your household:</p>
            <ul>
              <li>
                For all individuals receiving Social Security (SSA) or
                Supplemental Security Income (SSI): 2022 Award Letter (ALL
                PAGES).
              </li>
              <li>
                For all individuals receiving federal disability benefits: 2022
                Federal Disability Benefits Statements (ALL PAGES).
              </li>
              <li>
                For all individuals receiving federal retirement benefits: 2022
                Federal Retirement Benefits Statements (ALL PAGES).
              </li>
              <li>
                For all self-employed individuals: 2021 income tax documents
                including Schedule C.
              </li>
              <li>
                For all individuals receiving VA benefits: 2022 VA Benefit
                statement.
              </li>
              <li>
                For all individuals receiving unemployment benefits:
                unemployment statement.
              </li>
            </ul>
          </>
        </DetailsContent>
      </details>

      <p>
        <UploadButton category={AppointmentFileCategory.Income}>
          Click to Upload Income Documentation
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Income Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Income} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const UtilitiesStep: React.FC = (props) => {
  return (
    <>
      <h2>Utility Bills</h2>
      <p>Upload a copy of your most recent utility bills</p>
      <p>
        <UploadButton category={AppointmentFileCategory.Utility}>
          Click to Upload Utility Bills
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Utility Bill Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Utility} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const WatereeUtilitiesStep: React.FC = (props) => {
  return (
    <>
      <h2>Bills</h2>
      <p>
        Upload a copy of the current bill(s) for the service(s) you are seeking
        (for example: water, wastewater, energy, rent).
      </p>
      <p>
        <UploadButton category={AppointmentFileCategory.Utility}>
          Click to Upload Bills
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Bills Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Utility} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const OtherDocumentsStep: React.FC = (props) => {
  return (
    <>
      <h2>Other Documents</h2>

      <p>If you have a disability, provide disability verification.</p>

      <p>
        If you are applying on someone else's behalf, provide a notarized letter
        stating that fact and your own photo ID.
      </p>

      <p>
        If you have been asked to upload any other documents, upload them here.
      </p>

      <p>
        <UploadButton category={AppointmentFileCategory.Other}>
          Click to Upload Other Documents
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Other Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Other} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const WatereeOtherDocumentsStep: React.FC = (props) => {
  return (
    <>
      <h2>Other Documents</h2>

      <p>
        If you are a veteran and applying for energy assistance, upload proof of
        your veteran status here. (This is not required for other programs.)
      </p>

      <p>
        If you have been asked to upload any other documents, upload them here.
      </p>

      <p>
        <UploadButton category={AppointmentFileCategory.Other}>
          Click to Upload Other Documents
        </UploadButton>
      </p>

      <p>
        <strong>Uploaded Other Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Other} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const EmailStep: React.FC = (props) => {
  const apptState = useAppointmentsGlobalState();
  const setApptState = useSetAppointmentsGlobalState();
  const [disableFooter, setDisableFooter] = useState<boolean>(false);
  const { token } = useToken();
  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: apptState.appSummary?.email || "",
    },
    onSubmit: (values) => {
      axios //do upload
        .patch(`/api/appointment/email/${token}`, values.email, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() =>
          setApptState((prev) => ({
            //update state: add file
            ...prev,
            appSummary: {
              ...prev.appSummary,
              email: values.email,
            },
          }))
        )
        .catch(() => {
          window.alert("Failed to update email"); //alert and reset form
          formik.resetForm({
            values: { email: apptState.appSummary?.email || "" },
          });
        });
    },
    enableReinitialize: true,
    validate: validate,
  });

  //custom hook to wrap some formik components and apply type safety!
  const { getFieldProps, ErrorMessage } = useFormikHelpers<{ email: string }>(
    formik
  );

  function validate(values: {
    email: string;
  }): FormikErrors<{ email: string }> {
    const errors: FormikErrors<{ email: string }> = {};

    if (
      isEmpty(values.email) === false &&
      isValidEmail(values.email) === false
    ) {
      errors.email = "Invalid email. Fix or remove to continue";
    }

    return errors;
  }

  function handleOnBlur(e: React.FocusEvent<any, Element>) {
    formik.handleBlur(e);

    if (formik.isValid && formik.values.email !== apptState.appSummary.email) {
      formik.submitForm();
    }
  }

  useEffect(() => {
    setDisableFooter(!formik.isValid);
  }, [formik.isValid]);

  return (
    <>
      <h2>Email</h2>
      <p>
        <label htmlFor="email">Enter your email address:</label>
        <TextInput
          type="text"
          {...getFieldProps("email")}
          onBlur={handleOnBlur}
        />
        <ErrorMessage name="email" />
      </p>

      {/**footer */}
      <AppointmentUploadsFooter disableStepper={disableFooter} />
    </>
  );
};

export const ReviewStep: React.FC = (props) => {
  return (
    <>
      <h1>You're all set!</h1>
      <p>Every document you see below has been successfully uploaded.</p>
      <p>
        If you need to upload more just navigate back to the appropriate
        section.
      </p>
      <p>
        You can always re-visit the original link to upload more documents
        later.
      </p>

      <p className="mt-14">
        <strong>Uploaded Photo ID Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.ID} />
        </div>
      </p>

      <p>
        <strong>Uploaded Citizenship/Residency Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Citizenship} />
        </div>
      </p>
      <p>
        <strong>Uploaded Income Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Income} />
        </div>
      </p>

      <p>
        <strong>Uploaded Utility Bill Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Utility} />
        </div>
      </p>

      <p>
        <strong>Uploaded Other Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Other} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const WatereeReviewStep: React.FC = (props) => {
  return (
    <>
      <h2>Review</h2>
      <p>
        The documents you see here have been uploaded. If you need to upload any
        more documents, use the Previous button to navigate to the correct
        section. You can also leave and come back to the original link anytime
        before your appointment.
      </p>

      <p>
        <strong>Uploaded Photo ID Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.ID} />
        </div>
      </p>

      <p>
        <strong>Uploaded Social Security Card Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Citizenship} />
        </div>
      </p>
      <p>
        <strong>Uploaded Income Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Income} />
        </div>
      </p>

      <p>
        <strong>Uploaded Bill Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Utility} />
        </div>
      </p>

      <p>
        <strong>Uploaded Other Files</strong>
        <div className="not-prose">
          <FilesContainer category={AppointmentFileCategory.Other} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

/**Steps in order to be rendered by main app */
export function getSteps(agencyId: number): JSX.Element[] {
  const ohAgencies = [
    115, 138, 118, 129, 106, 135, 144, 113, 121, 107, 130, 101, 141, 124, 133,
    104, 119, 142, 105, 136, 116, 139, 128, 122, 108, 102, 145, 140, 123, 134,
    103, 143, 120, 131,
  ];

  //wateree steps
  if (agencyId === 157) {
    return [
      <OverviewStep
        listItems={[
          "SC photo ID for the applicant",
          "Social Security cards for each household member",
          "Proof of income for each household member.",
          "Copies of your most recent bill(s) related to the service(s) you are seeking (energy, water, sewer, or rent)",
        ]}
      />,
      <PhotoIdsStep>
        <>
          <p>Upload the applicant's SC photo ID.</p>
          <p>
            The name on the ID must match the name on the applicant's social
            security card.
          </p>
          <p>
            The address on the ID must match the address on the utility bill.
          </p>
        </>
      </PhotoIdsStep>,
      <WatereeScCardStep />,
      <WatereeIncomeStep />,
      <WatereeUtilitiesStep />,
      <WatereeOtherDocumentsStep />,
      <EmailStep />,
      <WatereeReviewStep />,
    ];
  } else if (ohAgencies.some((id) => id === agencyId)) {
    //for OH agencies
    return [
      <OverviewStep
        listItems={[
          "Photo IDs for the applicant and everyone listed on utility bill.",
          "Proof of U.S. citizenship/legal residency for each household member.",
          "Proof of income for each household member.",
          "Copies of your most recent utility bills.",
        ]}
      />,
      <PhotoIdsStep>
        <>
          <p>Upload photo IDs for the following individuals:</p>
          <ul>
            <li>The applicant</li>
            <li>Anyone listed on the utility bill</li>
          </ul>
          <p>Photo IDs should be valid and not expired.</p>
        </>
      </PhotoIdsStep>,
      <CitizenshipStep />,
      <IncomeStep />,
      <UtilitiesStep />,
      <OtherDocumentsStep />,
      <EmailStep />,
      <ReviewStep />,
    ];
  }

  //generic steps
  return [
    <GenericOverviewStep />,
    <GenericPhotoIdStep />,
    <GenericCitizenshipStep />,
    <GenericIncomeStep />,
    <GenericUtilityStep />,
    <GenericOtherDocumentsStep />,
    <EmailStep />,
    <ReviewStep />,
  ];
}
