/**
 * parse a string value to boolean.
 * used to handle yes/no values from form input and parse to boolean for sending to server
 * @param value
 * @returns
 */
export function parseBooleanYesNo(value: string): boolean | null {
  return value === "yes" ? true : false;
}

/**
 * format boolean values to yes/no for form handling
 * @param value
 * @returns
 */
export function formatBooleanYesNo(value: boolean | null): string {
  return value ? "yes" : "no";
}
