import React, { useRef, useState } from "react";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { createContext } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLayoutEffect } from "react";
import ReactDOM from "react-dom";

export interface NotificationProps {
  icon: JSX.Element;
  title: string;
  message: string;
  afterShow: () => void;
}
export const Notification: React.FC<NotificationProps> = (props) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      props.afterShow();
    }, 100);
  }, []);

  return ReactDOM.createPortal(
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {/* <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> */}
                    {props.icon}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {props.title}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {props.message}
                    </p>
                  </div>
                  {/* <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>,
    document.body
  );
};

export interface StyledNotificationProps
  extends Omit<NotificationProps, "icon"> {}

export const FailureNotification: React.FC<StyledNotificationProps> = (
  props
) => {
  return (
    <Notification
      title={props.title}
      message={props.message}
      icon={<ExclamationIcon className="h-6 w-6 text-guardsman-red" />}
      afterShow={props.afterShow}
    />
  );
};

export interface NotificationContextState {
  pushNotification: (props: NotificationProps) => void;
  pushFailureNotification: (props: StyledNotificationProps) => void;
}

export const GlobalNotificationContext =
  createContext<NotificationContextState>({
    pushFailureNotification: (props: StyledNotificationProps) => {},
    pushNotification: (props: NotificationProps) => {},
  });

/**
 * to be finished. Would be nice to handle this globally and allow components to just call this push function
 * instead of hiding/showing the component themselves.
 * @param - children - child elements that can consume this context
 * @returns
 */
export const GlobalNotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<JSX.Element[]>([]);
  const notificationMap = useRef<Map<Object, JSX.Element>>(new Map());

  function pushNotification(props: NotificationProps): void {}
  function pushFailureNotification(props: StyledNotificationProps): void {
    const { title, message, afterShow } = props;

    const notification = (
      <FailureNotification
        title={title}
        message={message}
        afterShow={() => {
          handleAfterShow(props);

          if (afterShow) {
            afterShow();
          }
        }}
      />
    );

    notificationMap.current.set(props, notification);
    setNotifications([...notifications, notification]);
  }

  function handleAfterShow(props: any) {
    setTimeout(() => {
      const notification = notificationMap.current.get(props);

      setNotifications(
        notifications.reduce<JSX.Element[]>((arr, curr) => {
          if (notification !== curr) {
            arr.push(curr);
          }
          return arr;
        }, [])
      );

      notificationMap.current.delete(props);
    }, 5000);
  }
  return (
    <GlobalNotificationContext.Provider
      value={{ pushNotification, pushFailureNotification }}
    >
      {children}
      {notifications}
    </GlobalNotificationContext.Provider>
  );
};
