import { format, parse, parseISO } from "date-fns";
import React from "react";
import { AppointmentFileCategory } from "../../types/appointmentFile";
import { useAppointmentsGlobalState } from "./appointmentStore";
import { AppointmentUploadsFooter } from "./AppointmentUploadsApp";
import { FilesContainer, UploadButton } from "./UploadSteps";

interface IGenericStepProps {
  title: string;
  uploadButtonText: string;
  uploadCategory: AppointmentFileCategory;
  fileListTitle: string;
}
const GenericStep: React.FC<IGenericStepProps> = (props) => {
  return (
    <>
      <h2>{props.title}</h2>
      <p>{props.children}</p>
      <p>
        <UploadButton category={props.uploadCategory}>
          {props.uploadButtonText}
        </UploadButton>
      </p>
      <p>
        <strong>Uploaded Photo ID Files</strong>
        <div className="not-prose">
          <FilesContainer category={props.uploadCategory} />
        </div>
      </p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export const GenericPhotoIdStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Photo IDs"
      uploadButtonText="Click to Upload Photo IDs"
      uploadCategory={AppointmentFileCategory.ID}
      fileListTitle="Uploaded Photo IDs"
    >
      Upload photo ID(s) here. Please refer to the required documentation list
      for details on the IDs required for this program.
    </GenericStep>
  );
};

export const GenericCitizenshipStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Citizenship/Residency Documentation"
      uploadButtonText="Click to Upload Proof of U.S. Citizenship/Legal Residency"
      uploadCategory={AppointmentFileCategory.Citizenship}
      fileListTitle="Uploaded Citizenship/Residency Files"
    >
      Upload all required proof of citizenship/residency here. Please refer to
      the required documentation list for details on acceptable forms of proof.
    </GenericStep>
  );
};

export const GenericIncomeStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Income Documentation"
      uploadButtonText="Click to Upload Income Documentation"
      uploadCategory={AppointmentFileCategory.Income}
      fileListTitle="Uploaded Income Files"
    >
      Upload all required proof of income here. Please refer to the required
      documentation list for details on acceptable forms of proof.
    </GenericStep>
  );
};

export const GenericUtilityStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Utility Bills"
      uploadButtonText="Click to Upload Utility Bills"
      uploadCategory={AppointmentFileCategory.Utility}
      fileListTitle="Uploaded Utility Bill Files"
    >
      Upload utility bills here, if required. Please refer to the required
      documentation list to see if utility bills are required for the program
      you are applying to.
    </GenericStep>
  );
};

export const GenericOtherDocumentsStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Other Documents"
      uploadButtonText="Click to Upload Other Documents"
      uploadCategory={AppointmentFileCategory.Other}
      fileListTitle="Uploaded Utility Bill Files"
    >
      Upload utility bills here, if required. Please refer to the required
      documentation list to see if utility bills are required for the program
      you are applying to.
    </GenericStep>
  );
};

export const GenericOverviewStep: React.FC = (props) => {
  const state = useAppointmentsGlobalState();
  const time = format(
    parse(state.appSummary?.appTime || "", "HH:mm:ss", new Date()),
    "hh:mma"
  );
  const date = format(parseISO(state.appSummary?.appDate || ""), "MM/dd/yyyy");
  return (
    <>
      <h2>Overview</h2>
      <p>
        Upload required documents for your {state.appSummary?.program}{" "}
        appointment with {state.appSummary?.longClientName} scheduled for {time}{" "}
        on {date} at {state.appSummary?.siteName}
      </p>

      <p>
        <strong>
          Your appointment reminder text contains a link that lists all required
          documents for the program you are applying to. Please read that list
          carefully. This form will walk you through uploading those documents.
          This upload form is divided into the following categories:
        </strong>
      </p>
      <ul>
        <li>Photo IDs</li>
        <li>Proof of U.S. citizenship/residency.</li>
        <li>Proof of income.</li>
        <li>Copies of your most recent utility bills.</li>
        <li>
          Any other documents required by the program you are applying to.
        </li>
      </ul>

      <p>You will also have the opportunity to upload additional documents.</p>

      {/**footer */}
      <AppointmentUploadsFooter />
    </>
  );
};

export default GenericStep;
