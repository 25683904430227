import { useState } from "react";
import { createContainer } from "react-tracked";
import { AppointmentFile } from "../../types/appointmentFile";
import { AppointmentFileAppSummary } from "../../types/appointmentFileAppSummary";

interface IAppointmentsGlobalState {
  files: AppointmentFile[];
  appSummary: AppointmentFileAppSummary;
}

const useStoreValue = () =>
  useState<IAppointmentsGlobalState>({
    files: [],
    appSummary: {
      apptID: 0,
      appDate: "",
      agencyCode: 0,
      program: "",
      longClientName: "",
      appTime: "",
      siteName: "",
      phoneApptsOnly: false,
      agencyUsesApptTypes: 0,
      apptType: 0,
      email: "",
    },
  });

export const {
  Provider: AppointmentStateProvider,
  useTrackedState: useAppointmentsGlobalState,
  useUpdate: useSetAppointmentsGlobalState,
} = createContainer(useStoreValue);
