export interface AppointmentFile {
  id: number;
  apptID: number;
  fileID: string;
  fileName: string;
  fileType: string;
  fileCategory: AppointmentFileCategory;
  shareURL: string;
  description: string;
}

export enum AppointmentFileCategory {
  ID = 0,
  Citizenship = 1,
  Income = 2,
  Utility = 3,
  Other = 4,
}
