import { FormikErrors, useFormik } from "formik";
import React from "react";
import { DateInput, Label, SsnInput, TextInput } from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { StepperBlock } from "../Stepper";
import { useSharedFormState } from "../store/store";
import {
  isEmpty,
  isValidDate,
  isValidSSN,
  RequiredMessage,
} from "../validation";
import { AdditionalHouseholdMemberPersonalInformation } from "./AdditionalHouseHoldMembers";

export interface IAdditionalMemberPersonalInformationProps {
  initialData: AdditionalHouseholdMemberPersonalInformation;
  onBack: () => void;
  onNext: (
    vaulues: AdditionalHouseholdMemberPersonalInformation
  ) => Promise<void>;
}

export const AdditionalMemberPersonalInformationStep: React.FC<IAdditionalMemberPersonalInformationProps> =
  (props) => {
    const [state] = useSharedFormState();
    const formik = useFormik<AdditionalHouseholdMemberPersonalInformation>({
      initialValues: props.initialData,
      onSubmit: (values: AdditionalHouseholdMemberPersonalInformation) => {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      },
      validate: validate,
    });

    //get helpers
    const { getFieldProps, ErrorMessage } =
      useFormikHelpers<AdditionalHouseholdMemberPersonalInformation>(formik);

    function validate(
      values: AdditionalHouseholdMemberPersonalInformation
    ): FormikErrors<AdditionalHouseholdMemberPersonalInformation> {
      const errors: FormikErrors<AdditionalHouseholdMemberPersonalInformation> =
        {};

      if (isEmpty(values.SocialSecurity)) {
        errors.SocialSecurity = RequiredMessage;
      } else if (isValidSSN(values.SocialSecurity) === false) {
        errors.SocialSecurity = "Format must match XXX-XX-XXXX";
      } else if (
        state.CurrentFormData.AdditionalMembers.some(
          (x) =>
            x.PersonalInformation.SocialSecurity === values.SocialSecurity &&
            x.id !== state.CurrentAdditionalMember.id
        )
      ) {
        errors.SocialSecurity = "This matches an existing member";
      }

      if (isEmpty(values.FirstName)) {
        errors.FirstName = RequiredMessage;
      }

      if (isEmpty(values.LastName)) {
        errors.LastName = RequiredMessage;
      }

      if (isEmpty(values.BirthDate)) {
        errors.BirthDate = RequiredMessage;
      } else if (isValidDate(values.BirthDate) === false) {
        errors.BirthDate = "Invalid date";
      }

      return errors;
    }

    return (
      <StepperBlock
        onBack={props.onBack}
        onNext={formik.submitForm}
        isLoading={formik.isSubmitting}
      >
        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="firstName">First Name</Label>
          <TextInput
            id="firstName"
            type="text"
            {...getFieldProps("FirstName")}
          />
          <ErrorMessage name="FirstName" />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="lastName">Last Name</Label>
          <TextInput id="lastName" type="text" {...getFieldProps("LastName")} />
          <ErrorMessage name="LastName" />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="ssn">Social Security #</Label>
          <SsnInput id="ssn" {...getFieldProps("SocialSecurity")} />
          <ErrorMessage name="SocialSecurity" />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="birthdate">Birthdate</Label>
          <DateInput
            {...getFieldProps("BirthDate")}
            id="birthdate"
            placeholder="MM/DD/YYYY"
            autoComplete="bDay"
          />
          <ErrorMessage name="BirthDate" />
        </div>
      </StepperBlock>
    );
  };
