import { useEffect } from "react";
import { useRequest, UseRequestResult } from "../hooks/useApi";
import { useToken } from "../AppointmenUploadsApp/useToken";
import { ApplicationFile } from "../../types/applicationFile";
import { useSetApplicationFilesState } from "./applicationStore";

/**
 * wrap the api call to get files.
 * will keep the global state in sync
 * @param immediateRequest - if true this will load immediately. else delayed until set to true.
 * @returns request function for loading / refreshing files in the global state
 */
export function useApplicationFiles(
  immediateRequest?: boolean
): [UseRequestResult<ApplicationFile[]>, () => void] {
  const { token } = useToken();
  const setState = useSetApplicationFilesState();  

  //do files request
  const [response, request] = useRequest<ApplicationFile[]>(
    {
      url: "/api/applicationfiles/files/" + token,
    },
    immediateRequest
  );

  useEffect(() => {
    if (!response.isLoading && (response.data || response.error)) {
      setState((prev) => ({ ...prev, files: response.data || [] }));
    }
  }, [response.data, response.error, response.isLoading, setState]);

  return [response, request];
}
