import React from "react";
import { NavMenu } from "./NavMenu";
import logo from "../images/cds.jpg";
import { Log } from "oidc-client";

// export class Layout extends Component {
//   static displayName = Layout.name;

//   render() {
//     return (
//       <div className="pb-10">
//         <NavMenu />
//         <div>{this.props.children}</div>
//       </div>
//     );
//   }
// }

export interface IBaseLayoutProps {
  navMenu?: JSX.Element;
  cdsLogoImageOnly?: boolean;
}

/**Base layout accepting a custom nav menu */
export const BaseLayout: React.FC<IBaseLayoutProps> = (props) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {props.navMenu}
      <div className="flex justify-center py-5 flex-grow-1">
        <div className="shadow flex grow-1 flex-col w-full sm:w-10/12 lg:w-8/12 rounded-xl bg-white lg:px-10 sm:px-6 px-4 pb-10">
          {props.children}
        </div>
      </div>
      <div className="lg:px-10 sm:px-6 px-4 py-3 sm:w-10/12 lg:w-8/12 text-center mr-auto ml-auto">
        {/** cds logo as a link or just image */}
        {props.cdsLogoImageOnly && <Logo />}
        {!props.cdsLogoImageOnly && (
          <>
            <a
              className="text-gray-700"
              href="https://cdsanswersforyou.com/"
              rel="noreferrer"
              target="_blank"
            >
              <Logo />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

const Logo: React.FC = (props) => {
  return (
    <>
      <img src={logo} className="inline-block max-w-full h-24 w-24" alt="" />
      <div className="text-gray-700 text-sm mt-2">Powered by CDS</div>
    </>
  );
};

export interface ILayoutProps {}

/**Composed layout for main Wateree App */
export const Layout: React.FC<ILayoutProps> = (props) => {
  return <BaseLayout navMenu={<NavMenu />}>{props.children}</BaseLayout>;
};
