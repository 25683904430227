import React, { useEffect, useState } from "react";
import { ApplicationFile, ApplicationFileCategory } from "../../types/applicationFile";
import { useApplicationFilesState, useSetApplicationFilesState } from "./applicationStore";
import { OutlineButton } from "../Buttons";
import { MainFooter } from "./ApplicationsAMUploadApp";
import { ListContainer, ListItem, ListItemIconContainer } from "../Lists";
import { DocumentIcon } from "@heroicons/react/outline";
import { useToken } from "../AppointmenUploadsApp/useToken";
import { FormikErrors, useFormik } from "formik";
import { isEmpty, isValidEmail } from "../validation";
import { TextInput } from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import axios from "axios";
import { ApplicationFileUploadModal } from "./ApplicationFileUploadModal";

/**Upload button to leverge on individual step pages.
 * Manages the upload modal and targets the provided category for uploads.
 */
export const UploadButton: React.FC<{ category: ApplicationFileCategory }> = (
  props
) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const setApplicationState = useSetApplicationFilesState();

  function onAction(file?: ApplicationFile) {
    if (file) {
      //add file to local data!
      setApplicationState((prev) => ({
        ...prev,
        files: [...prev.files, file],
      }));
    }
    setShowUpload(false);
  }
  return (
    <>
      <OutlineButton color="indigo" onClick={() => setShowUpload(true)}>
        {props.children}
      </OutlineButton>

      {showUpload && (
        <ApplicationFileUploadModal
          category={props.category}
          onAction={onAction}
        />
      )}
    </>
  );
};

interface IGenericStepProps {
  title: string;
  uploadButtonText: string;
  uploadCategory: ApplicationFileCategory;
  fileListTitle: string;
}
const GenericStep: React.FC<IGenericStepProps> = (props) => {
  return (
    <>
      <h2>{props.title}</h2>
      <p>{props.children}</p>
      <p>
        <UploadButton category={props.uploadCategory}>
          {props.uploadButtonText}
        </UploadButton>
      </p>
      <p>
        <strong>Uploaded Photo ID Files</strong>
        <p className="not-prose m-0">
          <FilesContainer category={props.uploadCategory} />
        </p>
      </p>

      {/**footer */}
      <MainFooter />
    </>
  );
};

export const GenericPhotoIdStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Photo IDs"
      uploadButtonText="Click to Upload Photo IDs"
      uploadCategory={ApplicationFileCategory.ID}
      fileListTitle="Uploaded Photo IDs"
    >
      Upload photo ID(s) here. Please refer to the required documentation list
      for details on the IDs required for this program.
    </GenericStep>
  );
};

export const GenericCitizenshipStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Citizenship/Residency Documentation"
      uploadButtonText="Click to Upload Proof of U.S. Citizenship/Legal Residency"
      uploadCategory={ApplicationFileCategory.Citizenship}
      fileListTitle="Uploaded Citizenship/Residency Files"
    >
      Upload all required proof of citizenship/residency here. Please refer to
      the required documentation list for details on acceptable forms of proof.
    </GenericStep>
  );
};

export const GenericIncomeStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Income Documentation"
      uploadButtonText="Click to Upload Income Documentation"
      uploadCategory={ApplicationFileCategory.Income}
      fileListTitle="Uploaded Income Files"
    >
      Upload all required proof of income here. Please refer to the required
      documentation list for details on acceptable forms of proof.
    </GenericStep>
  );
};

export const GenericUtilityStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Utility Bills"
      uploadButtonText="Click to Upload Utility Bills"
      uploadCategory={ApplicationFileCategory.Utility}
      fileListTitle="Uploaded Utility Bill Files"
    >
      Upload utility bills here, if required. Please refer to the required
      documentation list to see if utility bills are required for the program
      you are applying to.
    </GenericStep>
  );
};

export const GenericOtherDocumentsStep: React.FC = (props) => {
  return (
    <GenericStep
      title="Other Documents"
      uploadButtonText="Click to Upload Other Documents"
      uploadCategory={ApplicationFileCategory.Other}
      fileListTitle="Uploaded Utility Bill Files"
    >
      Upload utility bills here, if required. Please refer to the required
      documentation list to see if utility bills are required for the program
      you are applying to.
    </GenericStep>
  );
};

/**Files container for listing the files in a given category.
 * Depends on the global state provider
 */
export const FilesContainer: React.FC<{ category: ApplicationFileCategory }> = (
  props
) => {
  const globalState = useApplicationFilesState();
  const files = globalState.files.filter(
    (x) => x.fileCategory === props.category
  );

  return (
    <ListContainer
      noChildrenText={
        <>
          {props.category === ApplicationFileCategory.Other ? (
            <p className="py-2 italic">No documents uploaded</p>
          ) : (
            <p className="py-2 italic text-red-600">
              Warning: No files were uploaded to this required category.
            </p>
          )}
        </>
      }
      className="pt-2 pb-1"
    >
      {files.map((f) => (
        <FileListItem file={f} />
      ))}
    </ListContainer>
  );
};

const FileListItem: React.FC<{ file: ApplicationFile }> = (props) => {
  const f = props.file;
    
  return (
    <ListItem
      key={f.id}
      mainText={f.fileName}
      subText={f.description}      
      iconContainer={
        <ListItemIconContainer>
          <DocumentIcon />
        </ListItemIconContainer>
      }
    />
  );
};

export const ReviewStep: React.FC = (props) => {
  return (
    <>
      <h1>You're all set!</h1>
      <p>Every document you see below has been successfully uploaded.</p>
      <p>
        If you need to upload more just navigate back to the appropriate
        section.
      </p>
      <p>
        You can always re-visit the original link to upload more documents
        later.
      </p>

      <p className="mt-14">
        <strong>Uploaded Photo ID Files</strong>
        <div className="not-prose">
          <FilesContainer category={ApplicationFileCategory.ID} />
        </div>
      </p>

      <p>
        <strong>Uploaded Citizenship/Residency Files</strong>
        <div className="not-prose">
          <FilesContainer category={ApplicationFileCategory.Citizenship} />
        </div>
      </p>
      <p>
        <strong>Uploaded Income Files</strong>
        <div className="not-prose">
          <FilesContainer category={ApplicationFileCategory.Income} />
        </div>
      </p>

      <p>
        <strong>Uploaded Utility Bill Files</strong>
        <div className="not-prose">
          <FilesContainer category={ApplicationFileCategory.Utility} />
        </div>
      </p>

      <p>
        <strong>Uploaded Other Files</strong>
        <div className="not-prose">
          <FilesContainer category={ApplicationFileCategory.Other} />
        </div>
      </p>

      {/**footer */}
      <MainFooter />
    </>
  );
};

export const GenericOverviewStep: React.FC = (props) => {
  return (
    <>
      <h2>Overview</h2>
      <p>
        Upload required documents for your application with Brightpoint.
      </p>

      <p>
        <strong>
          Your reminder text contains a link that lists all required
          documents for the program you are applying to. Please read that list
          carefully. This form will walk you through uploading those documents.
          This upload form is divided into the following categories:
        </strong>
      </p>
      <ul>
        <li>Photo IDs</li>
        <li>Proof of U.S. citizenship/residency.</li>
        <li>Proof of income.</li>
        <li>Copies of your most recent utility bills.</li>
        <li>
          Any other documents required by the program you are applying to.
        </li>
      </ul>

      <p>You will also have the opportunity to upload additional documents.</p>

      {/**footer */}
      <MainFooter />
    </>
  );
};

export const EmailStep: React.FC = (props) => {
  const apptState = useApplicationFilesState();
  const setApptState = useSetApplicationFilesState();
  const [disableFooter, setDisableFooter] = useState<boolean>(false);
  const { token } = useToken();
  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: apptState.appSummary.email,
    },
    onSubmit: (values) => {
      axios //do upload
        .patch(`/api/applicationfiles/email/${token}`, values.email, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() =>
          setApptState((prev) => ({
            //update state: add file
            ...prev,
            appSummary: {
              ...prev.appSummary,
              email: values.email,
            },
          }))
        )
        .catch(() => {
          window.alert("Failed to update email"); //alert and reset form
          formik.resetForm({
            values: { email: apptState.appSummary?.email || "" },
          });
        });
    },
    enableReinitialize: true,
    validate: validate,
  });

  //custom hook to wrap some formik components and apply type safety!
  const { getFieldProps, ErrorMessage } = useFormikHelpers<{ email: string }>(
    formik
  );

  function validate(values: {
    email: string;
  }): FormikErrors<{ email: string }> {
    const errors: FormikErrors<{ email: string }> = {};

    if (
      isEmpty(values.email) === false &&
      isValidEmail(values.email) === false
    ) {
      errors.email = "Invalid email. Fix or remove to continue";
    }

    return errors;
  }

  function handleOnBlur(e: React.FocusEvent<any, Element>) {
    formik.handleBlur(e);

    if (formik.isValid && formik.values.email !== apptState.appSummary.email) {
      formik.submitForm();
    }
  }

  useEffect(() => {
    setDisableFooter(!formik.isValid);
  }, [formik.isValid]);

  return (
    <>
      <h2>Email</h2>
      <p>
        <label htmlFor="email">Enter your email address:</label>
        <TextInput
          type="text"
          {...getFieldProps("email")}
          onBlur={handleOnBlur}
        />
        <ErrorMessage name="email" />
      </p>

      {/**footer */}
      <MainFooter disableStepper={disableFooter} />
    </>
  );
};
