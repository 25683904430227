import {
  ErrorMessageProps,
  FieldInputProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from "formik";
import React from "react";
import { ErrorMessage } from "../formComponents";

/** custom formik stuff. probably move this into a helper hook.
 * doing all of this for type safety really
 */

//type safety for GetFieldProps
type TypeSafeGetFieldProps<T> = (k: keyof T) => FieldInputProps<any>;

//type safety for ErrorMessage component
interface TypeSafeErrorMessageProps<T> extends ErrorMessageProps {
  name: Extract<keyof T, string>;
}

type UseFormikHelpers<T> = {
  getFieldProps: TypeSafeGetFieldProps<T>;
  ErrorMessage: React.FC<TypeSafeErrorMessageProps<T>>;
};

export function useFormikHelpers<T>(
  formik: FormikState<T> & FormikHelpers<T> & FormikHandlers
): UseFormikHelpers<T> {
  const FormikErrorMessage: React.FC<TypeSafeErrorMessageProps<T>> = (
    props
  ) => {
    return (
      <>
        {formik.touched[props.name] && formik.errors[props.name] ? (
          <ErrorMessage>{formik.errors[props.name]}</ErrorMessage>
        ) : null}
      </>
    );
  };

  return {
    getFieldProps: formik.getFieldProps as TypeSafeGetFieldProps<T>,
    ErrorMessage: FormikErrorMessage,
  };
}
