import { useEffect } from "react";
import { useState } from "react";
import authService from "./AuthorizeService";

export interface IAuthenticatedState {
  /**if false we're polling */
  ready: boolean;
  authenticated: boolean;
  user?: { name: string };
}

/**Hook to easily get the authenticated state of the user.
 */
export function useAuthenticatedState(): IAuthenticatedState {
  const [state, setState] = useState<IAuthenticatedState>({
    ready: false,
    authenticated: false,
    user: undefined,
  });

  useEffect(() => {
    const subscription = authService.subscribe(() => authenticationChanged());

    populateAuthenticationState();

    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  async function populateAuthenticationState() {
    const user = await authService.getUser();
    setState({ ready: true, user, authenticated: !!user });
  }

  async function authenticationChanged() {
    setState({ ready: false, authenticated: false, user: undefined });
    await populateAuthenticationState();
  }

  return state;
}
