import { FormikErrors, useFormik } from "formik";
import React, { useRef } from "react";
import maritalStatuses from "../../json/maritalStatus.json";
import {
  Select,
  FormSubHeader,
  Label,
  CheckboxStripContainer,
  CheckBoxContainerRadio,
  TextInput,
} from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { StepperBlock } from "../Stepper";
import { isEmpty, RequiredMessage } from "../validation";

export type Demographics = {
  Gender: string;
  DisablingCondition: string;
  MaritalStatus: string;
  PrimaryLanguage: string;
};

function validateDemographics(
  values: Demographics
): FormikErrors<Demographics> {
  let errors: FormikErrors<Demographics> = {};

  if (isEmpty(values.Gender)) {
    errors.Gender = RequiredMessage;
  }

  if (isEmpty(values.DisablingCondition)) {
    errors.DisablingCondition = RequiredMessage;
  }

  if (isEmpty(values.MaritalStatus)) {
    errors.MaritalStatus = RequiredMessage;
  }

  if (isEmpty(values.PrimaryLanguage)) {
    errors.PrimaryLanguage = RequiredMessage;
  }

  return errors;
}

export interface IDemographicsStepProps {
  onBack: () => void;
  onNext: (data: Demographics) => Promise<void>;
  onSaveAndComplete?: (data: Demographics) => Promise<void>;
  initialValues: Demographics;
}

export const DemographicsStep: React.FC<IDemographicsStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  //spin up formik
  const formik = useFormik<Demographics>({
    initialValues: props.initialValues,
    validate: validateDemographics,
    enableReinitialize: true,
    onSubmit: (values: Demographics) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
  });

  //form helpers
  const { getFieldProps, ErrorMessage } =
    useFormikHelpers<Demographics>(formik);

  return (
    <>
      <StepperBlock
        onBack={props.onBack}
        onNext={() => {
          completeOrNext.current = "next";
          formik.submitForm();
        }}
        onSaveAndComplete={() => {
          completeOrNext.current = "complete";
          formik.submitForm();
        }}
        isLoading={formik.isSubmitting}
      >
        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="Gender">Gender</Label>
          <Select {...getFieldProps("Gender")}>
            <option value=""></option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
          <ErrorMessage name="Gender" />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="MaritalStatus">Marital Status</Label>
          <Select {...getFieldProps("MaritalStatus")}>
            {maritalStatuses.sort().map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
          </Select>
          <ErrorMessage name="MaritalStatus" />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="PrimaryLanguage">Primary Language Spoken</Label>
          <TextInput type="text" {...getFieldProps("PrimaryLanguage")} />
          <ErrorMessage name="PrimaryLanguage" />
        </div>

        <div className="col-span-6">
          <FormSubHeader>Do you have a disabling condition?</FormSubHeader>
          <CheckboxStripContainer>
            <CheckBoxContainerRadio
              label="Yes"
              values={[formik.values.DisablingCondition]}
              {...getFieldProps("DisablingCondition")}
              id="condition_yes"
              value="yes"
            />
            <CheckBoxContainerRadio
              label="No"
              values={[formik.values.DisablingCondition]}
              {...getFieldProps("DisablingCondition")}
              id="condition_no"
              value="no"
            />
            <CheckBoxContainerRadio
              label="Unknown"
              values={[formik.values.DisablingCondition]}
              {...getFieldProps("DisablingCondition")}
              id="condition_unknown"
              value="unknown"
            />
          </CheckboxStripContainer>
          <ErrorMessage name="DisablingCondition" />
        </div>
      </StepperBlock>
    </>
  );
};
