import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import authService from "../api-authorization/AuthorizeService";

let _axiosInstance: AxiosInstance;

export type UseAxiosResponse<T> = {
  response: T;
  loading: boolean;
  hasError: boolean;
};

export async function getAxiosInstance(): Promise<AxiosInstance> {
  const token = await authService.getAccessToken();

  if (!_axiosInstance) {
    _axiosInstance = axios.create({
      withCredentials: true,
    });
    _axiosInstance.defaults.headers.common["Authorization"] = token;
  }

  return _axiosInstance;
}

export function useAxios<ResponseDataType>(
  opts: AxiosRequestConfig
): [UseAxiosResponse<ResponseDataType>, () => void] {
  const [responseState, setResponseState] = useState<
    UseAxiosResponse<ResponseDataType>
  >({
    response: null as any,
    loading: false,
    hasError: false,
  });

  const [refreshCount, setRefreshCount] = useState<number>(0);

  useEffect(() => {
    setResponseState({
      ...responseState,
      loading: true,
    });

    getAxiosInstance().then((i) => {
      i.request(opts)
        .then((res: AxiosResponse<ResponseDataType>) => {
          setResponseState({
            response: res.data,
            loading: false,
            hasError: false,
          });
        })
        .catch(() => {
          setResponseState({
            response: null as any,
            loading: false,
            hasError: true,
          });
        });
    });
  }, [refreshCount]);

  function forceReload(): void {
    setRefreshCount(refreshCount + 1);
  }

  return [responseState, forceReload];
}
