/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { PrimaryButton } from "../Buttons";
import { ErrorMessage, FormSubHeader } from "../formComponents";
import { StepperBlock } from "../Stepper";
import { useSharedFormState } from "../store/store";
import { UserCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { Demographics } from "./DemographicsStep";
import { Household } from "./HouseholdStep";
import { Race } from "./RaceStep";
import { IncomeSource } from "./IncomeStep";
import { ListContainer, ListItem, ListItemIconContainer } from "../Lists";
import { getAxiosInstance } from "../hooks/useAxios";

export type AdditionalHouseholdMemberPersonalInformation = {
  FirstName: string;
  LastName: string;
  BirthDate: string;
  SocialSecurity: string;
};

export type AdditionalHouseholdMember = {
  id: number | null;
  isComplete: boolean;
  PersonalInformation: AdditionalHouseholdMemberPersonalInformation;
  Demographics: Demographics;
  Household: Household;
  Race: Race;
  Income: IncomeSource;
};

export interface IAdditionalHouseholdMembersProps {
  onBack: () => void;
  onNext: () => void;
  onSaveAndComplete?: () => Promise<void>;
}

export const AdditionalHouseholdMembers: React.FC<IAdditionalHouseholdMembersProps> =
  (props) => {
    const [globalState, setGlobalState] = useSharedFormState();
    const additionalMembers = globalState.CurrentFormData.AdditionalMembers;
    const hasIncompleteMembers = additionalMembers.some((x) => !x.isComplete);
    //number of additional members should === household - 1
    const membersLeftToAdd =
      globalState.CurrentFormData.PersonalInformation.PersonsInHousehold -
      1 - //minus 1 to offset main applicant
      globalState.CurrentFormData.AdditionalMembers.length;

    function onNextOrComplete(isComplete: boolean): void {
      if (hasIncompleteMembers) {
        return;
      }

      if (membersLeftToAdd > 0) {
        return;
      }

      if (isComplete && props.onSaveAndComplete) {
        props.onSaveAndComplete();
      } else {
        props.onNext();
      }
    }

    return (
      <StepperBlock
        onBack={props.onBack}
        onNext={() => onNextOrComplete(false)}
        onSaveAndComplete={() => onNextOrComplete(true)}
        isLoading={false}
      >
        <div className="col-span-6">
          <FormSubHeader>
            You will need to add the amount you entered in 'Personal
            Information' section of the application.
          </FormSubHeader>
          <PrimaryButton
            className="mt-2"
            onClick={() =>
              setGlobalState({
                ...globalState,
                displayState: "add-additional-member",
                mainFormScrollTop: $(document).scrollTop(),
              })
            }
            color="green"
          >
            <PlusCircleIcon className="mr-2 h-6 w-6 -ml-2" />
            Add member
          </PrimaryButton>

          {additionalMembers.length <= 0 && (
            <p className="my-10 pl-5 italic">
              You have no added additional household members.
            </p>
          )}

          {additionalMembers.length > 0 && (
            <ListContainer>
              {additionalMembers.map((m) => (
                <ListItem
                  key={m.id}
                  id={"additional-member-" + m.id}
                  error={!m.isComplete}
                  mainText={`${m.PersonalInformation.FirstName} ${m.PersonalInformation.LastName}`}
                  subText={m.PersonalInformation.BirthDate}
                  iconContainer={
                    <ListItemIconContainer>
                      <UserCircleIcon />
                    </ListItemIconContainer>
                  }
                  rightElement={
                    <a
                      className="text-red-600"
                      onClick={async (e) => {
                        e.stopPropagation();
                        const axios = await getAxiosInstance();

                        await axios.delete(
                          `/api/application/household_member/${m.id}`
                        );

                        //get new members
                        const filteredMembers =
                          globalState.CurrentFormData.AdditionalMembers.filter(
                            (x) => x.id !== m.id
                          );
                        //set state
                        setGlobalState({
                          ...globalState,
                          CurrentFormData: {
                            ...globalState.CurrentFormData,
                            AdditionalMembers: filteredMembers,
                          },
                        });
                      }}
                    >
                      REMOVE
                    </a>
                  }
                  onClick={() => {
                    setGlobalState({
                      ...globalState,
                      CurrentAdditionalMember: m,
                      displayState: "add-additional-member",
                      mainFormScrollTop: $(document).scrollTop(),
                    });
                  }}
                />
              ))}
            </ListContainer>
          )}

          {hasIncompleteMembers && (
            <ErrorMessage>
              Incomplete household members are highlighted in red.
              <br />
              Please complete or remove them before continuing.
            </ErrorMessage>
          )}

          {!hasIncompleteMembers && membersLeftToAdd > 0 && (
            <ErrorMessage>
              You must add {membersLeftToAdd} household members before you can
              proceed.
            </ErrorMessage>
          )}
        </div>
      </StepperBlock>
    );
  };
