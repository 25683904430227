import React from "react";
import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";

import "tailwindcss/tailwind.css";
import WatereeApp from "./components/ExampleTailwindForm";
import { AppointmentUploadsApp } from "./components/AppointmenUploadsApp/AppointmentUploadsApp";
import { ApplicationsUploadApp, Token404Page } from "./components/ApplicationsAMUploadApp/ApplicationsAMUploadApp";

export default function App() {
  const displayName = App.name;

  return (
    <>
      <Switch>
        <Route path="/v2/application">
          <ApplicationsUploadApp />
        </Route>
        <Route path={"/v2/appointment"}>
          <AppointmentUploadsApp />
        </Route>
        <Route path={"/v2/"}>
          <Token404Page />
        </Route>
        <Route path="/">
          <LegacyWatereeApp />
        </Route>
      </Switch>
    </>
  );
}

/**
 * Legacy wateree app wrapper.
 */
function LegacyWatereeApp() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <AuthorizeRoute path="/application">
          <WatereeApp />
        </AuthorizeRoute>

        <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
          <ApiAuthorizationRoutes />
        </Route>
      </Switch>
    </Layout>
  );
}
