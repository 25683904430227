import { FormikErrors, useFormik } from "formik";
import React from "react";
import {
  CheckBoxContainerCheckBox,
  CheckboxStripContainer,
  FormSubHeader,
  Label,
  TextInput,
} from "../formComponents";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { StepperBlock } from "../Stepper";

export type DisclosuresAndAgreements = {
  Relationships: string;
  Agreement: string[];
};

function validate(
  values: DisclosuresAndAgreements
): FormikErrors<DisclosuresAndAgreements> {
  const errors: FormikErrors<DisclosuresAndAgreements> = {};

  if (!values.Agreement || values.Agreement.length === 0) {
    errors.Agreement =
      "You must agree to all disclosures before submitting your application";
  }

  return errors;
}

export interface IDisclosuresAndAgreementsStepProps {
  initialValues: DisclosuresAndAgreements;
  onBack: () => void;
  onNext: (data: DisclosuresAndAgreements) => Promise<void>;
}

export const DisclosuresAndAgreementsStep: React.FC<IDisclosuresAndAgreementsStepProps> =
  (props) => {
    //spinup formik
    const formik = useFormik<DisclosuresAndAgreements>({
      initialValues: props.initialValues,
      validate: validate,
      onSubmit: (values: DisclosuresAndAgreements) => {
        props.onNext(values).finally(() => {
          formik.setSubmitting(false);
        });
      },
      enableReinitialize: true,
    });

    //get helpers
    const { getFieldProps, ErrorMessage } =
      useFormikHelpers<DisclosuresAndAgreements>(formik);

    return (
      <>
        <StepperBlock
          isLoading={false}
          nextText="Submit"
          onBack={props.onBack}
          onNext={formik.submitForm}
        >
          <div className="col-span-6">
            <FormSubHeader>Familial Relationship Disclosure</FormSubHeader>
            <p className="my-3">
              I hereby certify that I do/do not have any family members that
              work for Wateree Community Actions, Inc. For the purposes of this
              disclosure only, the definition of family includes but is not
              limited to employee’s spouse, parents, children, sisters,
              brothers, grandchildren, great- grandchildren, grandparents, and
              great-parents, uncles, aunts, cousins, etc.
            </p>
            <p className="my-3">
              Failure to disclose any family member status at the time of
              application could result in being suspended for future assistance
              through the federally-funded programs that Wateree offers.
            </p>
            <p className="my-3">
              Since I have a family member that works for the Agency, I am aware
              that an additional approval needs to be obtained through
              management for the process to continue.
            </p>
            <p className="my-3">
              <Label htmlFor="relationships">Relationship(s) (if any)</Label>
              <TextInput
                type="text"
                id="relationships"
                {...getFieldProps("Relationships")}
              />
            </p>
            <FormSubHeader>Customer Disclosure Authorization</FormSubHeader>
            <p className="my-3">
              I do hereby authorize Wateree Community Actions, Inc. officials to
              request, access, receive, apply, disseminate, etc. any information
              regarding my current household condition to potential service
              providers (i.e. vendors, religious organizations, and government
              organizations or other applicable funding agencies) as necessary
              to assist me in meeting my basic human needs.
            </p>
            <FormSubHeader>Client Utility Waiver</FormSubHeader>
            <p className="my-3">
              I authorize my utility provider and/or my fuel supplier to
              disclose my customer data (including, but not limited to, energy
              cost, consumption and billing data) to Wateree Community Actions,
              Inc. for the purposes of verification, eligibility determination,
              analysis and reporting.
            </p>
            <p className="my-3">
              I agree to hold harmless and/or release such companies from and
              against any claims, losses, demands, damages or liability of any
              kind caused by or allegedly caused by such disclosure.
            </p>
            <p className="my-3">
              All information, regarding an applicant or recipient, is
              confidential and may be disclosed only for purposes of determining
              eligibility, providing services, or investigating suspected fraud
              in connection with the program. By signing this application, I
              authorize the Community Action Agency and South Carolina Office of
              Economic Opportunity access to my household’s fuel/utility/energy
              records pertaining to the eligibility of the applicant and for the
              purpose of any reporting required under Federal, State and local
              statutes, regulations and ordinances.
            </p>
            <p className="my-3 mb-5">
              I certify that all the above information is correct and may be
              used for household and income verification as well as zero income
              verification for statistical purposes. I authorize agency
              employees to contact any former employers and/or social services
              agencies to verify household income for the past thirty (30) days.
              I further certify that documentation to verify the same is
              included in the Applicant's official file/record.
            </p>
            <CheckboxStripContainer>
              <CheckBoxContainerCheckBox
                values={formik.values.Agreement}
                {...getFieldProps("Agreement")}
                value="yes"
                label="I agree"
              />
            </CheckboxStripContainer>
            <ErrorMessage name="Agreement" />
          </div>
        </StepperBlock>
      </>
    );
  };
