import React from "react";
import { Link } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import logo from "../images/wateree_logo.png";

export interface IBaseNavMenuProps {
  backgroundClass: string;
  logoSrc?: string;
  logoPath?: string;
  title: string;
  titleFontClass: string;
  loginMenu?: JSX.Element;
}

/**Base nav menu that is customizable. */
export const BaseNavMenu: React.FC<IBaseNavMenuProps> = (props) => {
  return (
    <header className={`shadow ${props.backgroundClass}`}>
      <div className="h-16 flex align-items-center sm:w-9/12 lg:w-8/12 mx-auto px-2 sm:px-0">
        <div className="flex w-3/4">
          {props.logoPath && (
            <Link
              to={props.logoPath}
              className="uppercase hover:no-underline text-gray-800 sm:text-lg font-bold tracking-tight"
            >
              {props.logoSrc && (
                <img
                  src={props.logoSrc}
                  className="sm:h-16 h-9 mr-2 inline-block"
                  alt=""
                />
              )}
              <span className={props.titleFontClass}>{props.title}</span>
            </Link>
          )}
          {!props.logoPath && (
            <div className="uppercase hover:no-underline text-gray-800 sm:text-lg font-bold tracking-tight">
              {props.logoSrc && (
                <img
                  src={props.logoSrc}
                  className="sm:h-16 h-9 mr-2 inline-block"
                  alt=""
                />
              )}
              <span className={props.titleFontClass}>{props.title}</span>
            </div>
          )}
        </div>
        {props.loginMenu && <></>}
      </div>
    </header>
  );
};

/**Customzied nav menu used in Wateree app */
export const NavMenu: React.FC = (props) => {
  return (
    <>
      <BaseNavMenu
        backgroundClass="bg-regal-blue"
        title="Wateree Community Actions, Inc."
        titleFontClass="text-white"
        logoSrc={logo}
        logoPath="/"
        loginMenu={<LoginMenu></LoginMenu>}
      />
    </>
  );
};
