import React, { useRef } from "react";
import {
  Label,
  CheckboxStripContainer,
  CheckBoxContainerCheckBox,
  TextInput,
  PhoneInput,
  SsnInput,
  DateInput,
} from "../formComponents";
import { StepperBlock } from "../Stepper";
import { useFormik, FormikErrors } from "formik";
import {
  isValidDate,
  numGreaterThan,
  isEmpty,
  RequiredMessage,
  InvalidDateMessage,
  isValidSSN,
  isValidPhone,
} from "../validation";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { useSharedFormState } from "../store/store";
import { useAxios } from "../hooks/useAxios";
import { Programs_Clients } from "../../types/programs_Clients";

export interface IPersonalInformationStepProps {
  onNext: (data: PersonalInformation) => Promise<void>;
  onSaveAndComplete?: (data: PersonalInformation) => Promise<void>;
}

export type PersonalInformation = {
  AssistanceType: number[];
  FirstName: string;
  LastName: string;
  SSN: string;
  DateOfBirth: string;
  Email: string;
  HomePhone: string;
  CellPhone: string;
  DriversLicense: string;
  DriversLicenseExpiration: string;
  PersonsInHousehold: number;
};

function validatePersonalInformation(
  values: PersonalInformation
): FormikErrors<PersonalInformation> {
  let errors: FormikErrors<PersonalInformation> = {};

  //basic requirements testing
  if (isEmpty(values.AssistanceType)) {
    errors.AssistanceType = RequiredMessage;
  }
  if (isEmpty(values.FirstName)) {
    errors.FirstName = RequiredMessage;
  }
  if (isEmpty(values.LastName)) {
    errors.LastName = RequiredMessage;
  }

  if (values.CellPhone && !isValidPhone(values.CellPhone)) {
    errors.CellPhone = "Invalid phone number";
  }

  if (values.HomePhone && !isValidPhone(values.HomePhone)) {
    errors.HomePhone = "Invalid phone number";
  }

  if (isEmpty(values.SSN)) {
    errors.SSN = RequiredMessage;
  } else if (isValidSSN(values.SSN) === false) {
    errors.SSN = "Format must match XXX-XX-XXXX";
  }

  //birthdate checks required and proper format
  if (isEmpty(values.DateOfBirth)) {
    errors.DateOfBirth = RequiredMessage;
  } else if (isValidDate(values.DateOfBirth) === false) {
    errors.DateOfBirth = InvalidDateMessage;
  }

  if (isEmpty(values.Email)) {
    errors.Email = RequiredMessage;
  }

  //only require one phone
  if (isEmpty(values.HomePhone)) {
    errors.HomePhone = RequiredMessage;
  }
  if (isEmpty(values.CellPhone)) {
    errors.CellPhone = RequiredMessage;
  }
  if (isEmpty(values.DriversLicense)) {
    errors.DriversLicense = RequiredMessage;
  }

  //expiration checks required and proper format
  if (isEmpty(values.DriversLicenseExpiration)) {
    errors.DriversLicenseExpiration = RequiredMessage;
  } else if (isValidDate(values.DriversLicenseExpiration) === false) {
    errors.DriversLicenseExpiration = InvalidDateMessage;
  }

  if (numGreaterThan(values.PersonsInHousehold, 0) === false) {
    errors.PersonsInHousehold = "Must be greater than 0";
  }

  return errors;
}

export const PersonalInformationStep: React.FC<IPersonalInformationStepProps> =
  (props) => {
    //get global state
    const [state] = useSharedFormState();
    const completeOrNext = useRef<"complete" | "next">("complete");
    //get the programs list
    const [programsRequest] = useAxios<Programs_Clients[]>({
      url: "/data/programs",
    });
    //spin up formik
    const formik = useFormik<PersonalInformation>({
      initialValues: state.CurrentFormData.PersonalInformation,
      enableReinitialize: true,
      onSubmit: (values: PersonalInformation) => {
        console.log("Saving personal information:");
        console.log(values);

        if (completeOrNext.current === "next") {
          props.onNext(values).then(() => {
            formik.setSubmitting(false);
          });
        } else if (props.onSaveAndComplete) {
          props.onSaveAndComplete(values).then(() => {
            formik.setSubmitting(false);
          });
        }
      },
      validate: validatePersonalInformation,
    });

    //custom hook to wrap some formik components and apply type safety!
    const { getFieldProps, ErrorMessage } =
      useFormikHelpers<PersonalInformation>(formik);

    return (
      <>
        <StepperBlock
          onNext={() => {
            completeOrNext.current = "next";
            formik.submitForm();
          }}
          onSaveAndComplete={() => {
            completeOrNext.current = "complete";
            formik.submitForm();
          }}
          isLoading={formik.isSubmitting}
        >
          <div className="col-span-6">
            <Label>What type of assistance are you seeking?</Label>
            {programsRequest.loading === false && programsRequest.response && (
              <CheckboxStripContainer>
                {programsRequest.response.map((p) => (
                  <CheckBoxContainerCheckBox
                    key={p.programID}
                    id={`program_${p.programID}`}
                    values={formik.values.AssistanceType}
                    {...getFieldProps("AssistanceType")}
                    label={p.friendlyName || p.program}
                    value={p.programID}
                  />
                ))}
              </CheckboxStripContainer>
            )}
            <ErrorMessage name="AssistanceType" />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="first_name">First name</Label>
            <TextInput
              type="text"
              {...getFieldProps("FirstName")}
              autoComplete="given-name"
            />
            <ErrorMessage name="FirstName" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="last_name">Last name</Label>
            <TextInput
              type="text"
              {...getFieldProps("LastName")}
              autoComplete="family-name"
            />
            <ErrorMessage name="LastName" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="birth_date">Birthdate</Label>
            <DateInput {...getFieldProps("DateOfBirth")} autoComplete="bday" />
            <ErrorMessage name="DateOfBirth" />
          </div>

          <div className="col-span-6 sm:col-start-1 sm:col-end-3">
            <Label htmlFor="email_address">Email address</Label>
            <TextInput
              type="text"
              {...getFieldProps("Email")}
              autoComplete="email"
            />
            <ErrorMessage name="Email" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="home_phone">Home Phone</Label>
            <PhoneInput {...getFieldProps("HomePhone")} />
            <ErrorMessage name="HomePhone" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="cell_phone">Cell Phone</Label>
            <PhoneInput {...getFieldProps("CellPhone")} />
            <ErrorMessage name="CellPhone" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="ssn">Social Security</Label>
            <SsnInput {...getFieldProps("SSN")} />
            <ErrorMessage name="SSN" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="cell_phone">Drivers License #</Label>
            <TextInput type="text" {...getFieldProps("DriversLicense")} />
            <ErrorMessage name="DriversLicense" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="cell_phone">Expiration Date</Label>
            <DateInput {...getFieldProps("DriversLicenseExpiration")} />
            <ErrorMessage name="DriversLicenseExpiration" />
          </div>

          <div className="col-span-6">
            <Label>How many people live in your house?</Label>
            <Label className="text-xs text-gray-400">
              (including yourself)
            </Label>
            {/* <FormSubHeader description={"(including yourself)"}>
              How many people live in your house?
            </FormSubHeader> */}
          </div>

          <div className="col-span-6 sm:col-span-2 -mt-5">
            <TextInput type="number" {...getFieldProps("PersonsInHousehold")} />
            <ErrorMessage name="PersonsInHousehold" />
          </div>
        </StepperBlock>
      </>
    );
  };
