import React, { useRef } from "react";
import {
  CheckBoxContainerRadio,
  CheckboxStripContainer,
  FormSubHeader,
  Label,
  Select,
  TextInput,
} from "../formComponents";
import { StepperBlock } from "../Stepper";
import structureTypes from "../../json/structureTypes.json";
import dwellingTypes from "../../json/dwellingTypes.json";
import { FormikErrors, useFormik } from "formik";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { isValidYear, RequiredMessage, isEmpty } from "../validation";
import { useSharedFormState } from "../store/store";

export type Residence = {
  StructureType: string;
  NumberOfStories: number | string;
  DwellingType: string;
  LocationType: string;
  YearOfConstruction: string;
  ApproximateAge: number | string;
  MonthlyRent: number | string;
  HasSmokers: string;
  NumberOfSmokers: number | string;
  HasBeenWeatherized: string;
  YearWeatherized: string;
};

function validateResidence(values: Residence): FormikErrors<Residence> {
  const errors: FormikErrors<Residence> = {};

  if (isEmpty(values.StructureType)) {
    errors.StructureType = RequiredMessage;
  }

  if (isEmpty(values.NumberOfStories) || values.NumberOfStories <= 0) {
    errors.NumberOfStories = "Must be greater than 0";
  }

  if (isEmpty(values.DwellingType)) {
    errors.DwellingType = RequiredMessage;
  }

  if (isEmpty(values.LocationType)) {
    errors.LocationType = RequiredMessage;
  }

  //at least one should be defined
  if (isEmpty(values.YearOfConstruction) && isEmpty(values.ApproximateAge)) {
    errors.YearOfConstruction = "Specify one of these";
    errors.ApproximateAge = "Specify one of these";

    //only one should be defined!
  } else if (
    isEmpty(values.YearOfConstruction) === false &&
    isEmpty(values.ApproximateAge) === false
  ) {
    errors.YearOfConstruction = "Specify only one of these";
    errors.ApproximateAge = "Specify only one of these";
  }
  //validate individual inputs assuming we have just 1 entered
  else {
    //validate year type
    if (
      isEmpty(values.YearOfConstruction) === false &&
      isValidYear(values.YearOfConstruction) === false
    ) {
      errors.YearOfConstruction = "Invalid year";
    }

    //validate age is greater than 0 if provided
    if (isEmpty(values.ApproximateAge) === false && values.ApproximateAge < 0) {
      errors.ApproximateAge = "Must be greater than 0";
    }
  }

  //validate rent / mortgage is greater than 0
  if (isEmpty(values.MonthlyRent) || values.MonthlyRent <= 0) {
    errors.MonthlyRent = "Must be greater than 0.00";
  }

  //require has smokers
  if (isEmpty(values.HasSmokers)) {
    errors.HasSmokers = RequiredMessage;

    //require number if yes
  } else if (values.HasSmokers === "yes") {
    if (isEmpty(values.NumberOfSmokers) || values.NumberOfSmokers <= 0) {
      errors.NumberOfSmokers = "Must be greater than 0";
    }
  } else {
    //Can't enter number if they answered no
    if (isEmpty(values.NumberOfSmokers) === false) {
      errors.NumberOfSmokers = "You chose no";
    }
  }

  //require weatherized
  if (isEmpty(values.HasBeenWeatherized)) {
    errors.HasBeenWeatherized = RequiredMessage;

    //if yes require a year
  } else if (values.HasBeenWeatherized === "yes") {
    if (isEmpty(values.YearWeatherized)) {
      errors.YearWeatherized = RequiredMessage;
      //validate year
    } else if (isValidYear(values.YearWeatherized) === false) {
      errors.YearWeatherized = "Invalid year";
    }
  } else {
    //else if no make sure no year provided
    if (isEmpty(values.YearWeatherized) === false) {
      errors.YearWeatherized = "You chose no";
    }
  }

  return errors;
}

export interface IResidenceStepProps {
  onBack: () => void;
  onNext: (data: Residence) => Promise<void>;
  onSaveAndComplete?: (data: Residence) => Promise<void>;
}

export const ResidenceStep: React.FC<IResidenceStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  const [state] = useSharedFormState();
  //spinup formik
  const formik = useFormik<Residence>({
    initialValues: state.CurrentFormData.Residence,
    enableReinitialize: true,
    onSubmit: (values: Residence) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
    validate: validateResidence,
  });

  //get helpers
  const { getFieldProps, ErrorMessage } = useFormikHelpers<Residence>(formik);
  return (
    <StepperBlock
      onBack={props.onBack}
      onNext={() => {
        completeOrNext.current = "next";
        formik.submitForm();
      }}
      onSaveAndComplete={() => {
        completeOrNext.current = "complete";
        formik.submitForm();
      }}
      isLoading={formik.isSubmitting}
    >
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="structure_type">Structure Type</Label>
        <Select id="structure_type" {...getFieldProps("StructureType")}>
          {structureTypes.sort().map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </Select>
        <ErrorMessage name="StructureType" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="dwelling_type">Dwelling Type</Label>
        <Select id="dwelling_type" {...getFieldProps("DwellingType")}>
          {dwellingTypes.sort().map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </Select>
        <ErrorMessage name="DwellingType" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="stories"># of Stories</Label>
        <TextInput
          type="number"
          id="stories"
          {...getFieldProps("NumberOfStories")}
        />
        <ErrorMessage name="NumberOfStories" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="rent_mortgage">Monthly Rent/Mortgage</Label>
        <TextInput
          type="number"
          id="rent_mortgage"
          {...getFieldProps("MonthlyRent")}
        />
        <ErrorMessage name="MonthlyRent" />
      </div>
      <div className="col-span-6">
        <FormSubHeader>Do you live in?</FormSubHeader>
        <CheckboxStripContainer>
          <CheckBoxContainerRadio
            label="City/Town"
            values={[formik.values.LocationType]}
            {...getFieldProps("LocationType")}
            value="city"
            id="location_city"
          />
          <CheckBoxContainerRadio
            label="Rural Area"
            values={[formik.values.LocationType]}
            {...getFieldProps("LocationType")}
            value="rural"
            id="location_rural"
          />
          <CheckBoxContainerRadio
            label="Suburb"
            values={[formik.values.LocationType]}
            {...getFieldProps("LocationType")}
            value="suburb"
            id="location_suburb"
          />
        </CheckboxStripContainer>
        <ErrorMessage name="LocationType" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="year_of_construction">Year of Construction</Label>
        <TextInput
          type="text"
          id="year_of_construction"
          placeholder="YYYY"
          {...getFieldProps("YearOfConstruction")}
        />
        <ErrorMessage name="YearOfConstruction" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="age_of_dwelling">Or Approximate Age (in years)</Label>
        <TextInput
          type="text"
          id="age_of_dwelling"
          {...getFieldProps("ApproximateAge")}
        />
        <ErrorMessage name="ApproximateAge" />
      </div>
      {/**spacer block for non mobiles to force a new row */}
      <div className="hidden sm:col-span-2 sm:block"></div>
      <div className="col-span-6 sm:col-span-2">
        <FormSubHeader>Smokers in household?</FormSubHeader>
        <CheckboxStripContainer>
          <CheckBoxContainerRadio
            values={[formik.values.HasSmokers]}
            {...getFieldProps("HasSmokers")}
            id="has_smokers_yes"
            label="Yes"
            value="yes"
          />
          <CheckBoxContainerRadio
            values={[formik.values.HasSmokers]}
            {...getFieldProps("HasSmokers")}
            id="has_smokers_no"
            label="No"
            value="no"
          />
        </CheckboxStripContainer>
        <ErrorMessage name="HasSmokers" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="number_of_smokers">How many?</Label>
        <TextInput
          type="number"
          id="number_of_smokers"
          {...getFieldProps("NumberOfSmokers")}
        />
        <ErrorMessage name="NumberOfSmokers" />
      </div>
      {/**spacer block for non mobiles to force a new row */}
      <div className="hidden sm:col-span-2 sm:block"></div>

      <div className="col-span-6 sm:col-span-2">
        <FormSubHeader>Has been Weatherized?</FormSubHeader>
        <CheckboxStripContainer>
          <CheckBoxContainerRadio
            id="weatherized_yes"
            label="Yes"
            values={[formik.values.HasBeenWeatherized]}
            {...getFieldProps("HasBeenWeatherized")}
            value="yes"
          />
          <CheckBoxContainerRadio
            id="weatherized_no"
            label="No"
            values={[formik.values.HasBeenWeatherized]}
            {...getFieldProps("HasBeenWeatherized")}
            value="no"
          />
        </CheckboxStripContainer>
        <ErrorMessage name="HasBeenWeatherized" />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Label>If so when? (year)</Label>
        <TextInput
          type="text"
          id="year_weatherized"
          placeholder="YYYY"
          {...getFieldProps("YearWeatherized")}
        />
        <ErrorMessage name="YearWeatherized" />
      </div>
    </StepperBlock>
  );
};
