import React, { useReducer } from "react";
import AnimateHeight from "react-animate-height";
import { OutlineButton, PrimaryButton } from "./Buttons";
import { CheckIcon } from "@heroicons/react/outline";
import { useSharedFormState } from "./store/store";
import { createContainer } from "react-tracked";

export type StepperState = {
  /**array of steps that are currently invalid */
  invalidSteps: number[];
};

export type StepperAction =
  | { action: "Add Invalid Step"; payload: number }
  | { action: "Remove Invalid Step"; payload: number };

const stepperStateReducer = (
  state: StepperState,
  action: StepperAction
): StepperState => {
  //remove current step from invalid steps
  let invalidSteps = [...state.invalidSteps].filter(
    (i) => i !== action.payload
  );
  switch (action.action) {
    case "Add Invalid Step":
      invalidSteps.push(action.payload); //add step back
      return {
        ...state,
        invalidSteps,
      };
    case "Remove Invalid Step":
      //just save trimmed array
      return {
        ...state,
        invalidSteps,
      };
    default:
      return state;
  }
};

const useStepperStateReducer = () =>
  useReducer(stepperStateReducer, { invalidSteps: [] });

export const {
  Provider: StepperStateProvider,
  useTrackedState: useStepperState,
  useUpdate: useUpdateStepperState,
} = createContainer(useStepperStateReducer);

export interface IStepperChildren {
  title: string;
  render: (currentStep: number) => React.ReactNode;
}

export interface IStepperProps {
  steps: IStepperChildren[];
  currentStep: number;
  id: string;
}

export const Stepper: React.FC<IStepperProps> = (props) => {
  return (
    <div id={props.id}>
      {props.steps.map((x, i) => {
        const id = `${props.id}-step-${i}`;
        const hasCompleted = props.currentStep > i;
        return (
          <div key={i} id={id}>
            <div
              className={`flex flex-start flex-center space-x-5 items-center ${
                props.currentStep === i ? "current-step" : ""
              }`}
            >
              <div
                id="stepper-icon-stuff"
                className={`rounded-full h-8 w-8 flex items-center justify-center 
                  ${
                    hasCompleted
                      ? "bg-green-600"
                      : props.currentStep === i
                      ? "bg-regal-blue-300"
                      : "bg-gray-300"
                  } text-white text-xl`}
              >
                {hasCompleted ? (
                  <CheckIcon className="h-7 w-7" />
                ) : i === props.currentStep ? (
                  "?"
                ) : (
                  "!"
                )}
              </div>
              <div className="text-lg">{x.title}</div>
            </div>
            <div
              className="ml-4 pl-2 border-l border-gray-300 mt-2"
              style={{ minHeight: "24px" }}
            >
              <AnimateHeight
                height={props.currentStep === i ? "auto" : 0}
                //don't animate if we're not initialized.
                duration={300}
                onAnimationEnd={({ newHeight }) => {
                  if (props.currentStep === i) {
                    $("html, body").animate(
                      {
                        scrollTop: $(".current-step").position().top - 30,
                      },
                      100
                    );
                  }
                }}
              >
                {x.render(props.currentStep)}
              </AnimateHeight>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export interface IStepperBlockProps {
  onBack?: () => void;
  onNext?: () => void;
  onSaveAndComplete?: () => void;
  nextText?: string;
  /**if true will disable any back/next buttons. use when performing ajax or loading content for display */
  isLoading: boolean;
}

export const StepperBlock: React.FC<IStepperBlockProps> = (props) => {
  const [state] = useSharedFormState();
  return (
    <>
      <div className="w-full">
        <div className="p-2 bg-white">
          <div className="grid grid-cols-6 gap-6">{props.children}</div>
        </div>
        <div className="p-2 pt-5 flex justify-end space-x-2">
          {props.onBack && (
            <OutlineButton
              onClick={props.onBack}
              disabled={props.isLoading}
              color="yellow"
              style={{ width: "125px" }}
            >
              Back
            </OutlineButton>
          )}
          {props.onNext && (
            <PrimaryButton
              onClick={props.onNext}
              disabled={props.isLoading}
              color="regal-blue"
              style={{ width: "125px" }}
            >
              {props.nextText ? props.nextText : "Next"}
            </PrimaryButton>
          )}
          {/**we only show the 'Save and Complete' button when a user has been asked to return
           * And only on the main form. Not on the additional household member sub-form
           */}
          {props.onSaveAndComplete &&
            state.isEditingAfterSubmission &&
            state.displayState === "main-form" && (
              <PrimaryButton
                color="green"
                onClick={props.onSaveAndComplete}
                disabled={props.isLoading}
              >
                Save and Complete
              </PrimaryButton>
            )}
        </div>
      </div>
    </>
  );
};
