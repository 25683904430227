import React from "react";
import NumberFormat from "react-number-format";

export interface IFormSubHeaderProps {
  /**small description under header */
  description?: string;
}
export const FormSubHeader: React.FC<IFormSubHeaderProps> = (props) => {
  return (
    <div className="mb-1">
      <div className="font-medium text-normal">{props.children}</div>
      {props.description && (
        <p className="text-sm text-gray-400">{props.description}</p>
      )}
    </div>
  );
};

export const CheckboxStripContainer: React.FC = (props) => {
  return (
    <div className="flex flex-start flex-col sm:flex-row -ml-3 -mr-3 flex-wrap">
      {props.children}
    </div>
  );
};

export interface ICheckBoxContainerCheckBoxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  values: any[];
}
/**Wrapper for a checkbox/label meant to be placed in a CheckBoxContainer.
 * Will play with other sibling CheckBoxContainerItem
 */
export const CheckBoxContainerCheckBox: React.FC<ICheckBoxContainerCheckBoxProps> =
  (props) => {
    const { label, values, ...restOfProps } = props;
    return (
      <div className="flex items-center h-5 space-x-2 ml-3 mr-3 mb-2">
        <input
          id={restOfProps.id || restOfProps.name}
          type="checkbox"
          checked={values.some((x) => x == restOfProps.value)}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          {...restOfProps}
        />
        <label
          htmlFor={restOfProps.id || restOfProps.name}
          className="text-gray-700 mb-0"
        >
          {label}
        </label>
      </div>
    );
  };

export interface ICheckBoxContainerRadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  values: any[];
}

export const CheckBoxContainerRadio: React.FC<ICheckBoxContainerRadioProps> = (
  props
) => {
  const { label, values, ...restOfProps } = props;
  return (
    <div className="flex items-center h-5 space-x-2 ml-3 mr-3 mb-2">
      <input
        {...restOfProps}
        type="radio"
        checked={values.some((x) => x == restOfProps.value)}
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      />
      <label htmlFor={restOfProps.id} className="text-gray-700 mb-0">
        {label}
      </label>
    </div>
  );
};

export interface IFormBlockRightColumnGridProps {
  onClickSave?: () => void;
}
/**Right side of the form block containing inputs.
 * Renders all children inside of a grid
 */
export const FormBlockRightColumnGrid: React.FC<IFormBlockRightColumnGridProps> =
  (props) => {
    return (
      <div className="xs:mt-3 md:mt-0 md:col-span-3 lg:col-span-2">
        <form action="#" method="POST">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-4 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">{props.children}</div>
            </div>
            {/**comment out footer for now */}
            {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={props.onClickSave}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div> */}
          </div>
        </form>
      </div>
    );
  };

/**wrap our labels up
 * defaults classes used
 */
export const Label: React.FC<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
> = (props) => {
  const { className, ...restOfProps } = props;

  return (
    <label
      className={
        "block text-sm font-medium text-gray-700 tracking-tighter " +
        (className || "")
      }
      {...restOfProps}
    >
      {props.children}
    </label>
  );
};

const defaultInputClassName =
  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ";
export interface ITextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const TextInput: React.FC<ITextInputProps> = (props) => {
  const { className, value, ...restOfProps } = props;

  return (
    <>
      <input
        className={defaultInputClassName + (className || "")}
        value={value == null ? "" : value}
        {...restOfProps}
      />
    </>
  );
};

export const PhoneInput: React.FC<ITextInputProps> = (props) => {
  const { ref, value, defaultValue, type, ...otherProps } = props;
  return (
    <NumberFormat
      format="###-###-####"
      customInput={TextInput}
      type="tel"
      autoComplete="tel"
      mask="_"
      value={value as string}
      {...otherProps}
    />
  );
};

export const SsnInput: React.FC<ITextInputProps> = (props) => {
  const { ref, value, defaultValue, type, ...otherProps } = props;
  return (
    <NumberFormat
      format="###-##-####"
      customInput={TextInput}
      type="tel"
      mask="_"
      value={value as string}
      {...otherProps}
    />
  );
};

export const DateInput: React.FC<ITextInputProps> = (props) => {
  const { ref, value, defaultValue, type, ...otherProps } = props;
  return (
    <NumberFormat
      format="##/##/####"
      placeholder="MM/DD/YYYY"
      mask={["M", "M", "D", "D", "Y", "Y", "Y", "Y"]}
      customInput={TextInput}
      type="tel"
      value={value as string}
      {...otherProps}
    />
  );
};

export interface ITextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

export const TextArea: React.FC<ITextAreaProps> = (props) => {
  const { className, ...restOfProps } = props;
  return (
    <textarea
      className={defaultInputClassName + (className || "")}
      {...restOfProps}
    >
      {props.children}
    </textarea>
  );
};

export const Select: React.FC<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = (props) => {
  const defaultClassName =
    "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ";
  const { className, value, ...restOfProps } = props;

  return (
    <select
      className={defaultClassName + (className || "")}
      value={value == null ? "" : value}
      {...restOfProps}
    >
      {props.children}
    </select>
  );
};

export const FormBlock: React.FC = (props) => {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-4 lg:grid-cols-3 md:gap-6">
        {props.children}
      </div>
    </div>
  );
};

interface IFormBlockLeftColumnProps {
  title: string;
  description?: string;
}

export const FormBlockLeftColumn: React.FC<IFormBlockLeftColumnProps> = (
  props
) => {
  return (
    <div className="md:col-span-1">
      <div className="px-4 sm:px-0">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {props.title}
        </h3>
        {props.description && (
          <p className="mt-1 text-sm text-gray-600">{props.description}</p>
        )}
      </div>
    </div>
  );
};

export const ErrorMessage: React.FC = (props) => {
  return <div className="text-red-600 tracking-tighter">{props.children}</div>;
};
