import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

export interface UseRequestResult<T> {
  data: T;
  error: boolean;
  isLoading: boolean;
}

/**
 * hook for managing an axios request. has no opinion (unlike useAxios)
 * @param config - config for the request
 * @param immediateRequest - if true request will immediately fire and isLoading will initialize as true
 * @returns
 */
export function useRequest<T>(
  config: AxiosRequestConfig, //request config
  immediateRequest?: boolean //if true returned 'loading' parameter will be true initially
): [UseRequestResult<T>, () => void] {
  const [result, setResult] = useState<UseRequestResult<T>>({
    data: {} as T,
    error: false,
    isLoading: !!immediateRequest,
  });

  function request() {
    setResult({
      ...result,
      isLoading: true,
    });

    axios
      .request<T>(config)
      .then((v) => {
        setResult({
          data: v.data,
          error: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        setResult({
          data: {} as T,
          error: true,
          isLoading: false,
        });
      });
  }

  useEffect(() => {
    if (!!immediateRequest) {
      request();
    }
  }, []);

  return [result, request];
}
