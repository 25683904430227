import houseHoldTypes from "../../json/householdTypes.json";
import housingTypes from "../../json/housingType.json";
import healthInsuranceTypes from "../../json/healthInsuranceTypes.json";
import relationships from "../../json/relationships.json";

import React, { useRef } from "react";
import {
  Label,
  Select,
  CheckboxStripContainer,
  CheckBoxContainerRadio,
} from "../formComponents";
import { StepperBlock } from "../Stepper";
import { FormikErrors, useFormik } from "formik";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import educationalLevels from "../../json/educationalLevels.json";
import { isEmpty, RequiredMessage } from "../validation";
import { parseBooleanYesNo } from "../../types/helpers/booleanHelpers";

export type Household = {
  //these are only relevant for primary applicant
  HouseholdType: string;
  HousingType: string;

  //these fields apply for primary applicant and additional members
  Education: string;
  MilitaryStatus: string;
  HealthInsurance: string;
  HealthInsuranceType: string;

  /**This is only relevant to additional house hold members */
  Relationship: string;
};

export interface IHouseholdStepProps {
  onBack: () => void;
  onNext: (data: Household) => Promise<void>;
  onSaveAndComplete?: (data: Household) => Promise<void>;
  initialValues: Household;
  formType: "primary" | "additional-member";
}

export const HouseholdStep: React.FC<IHouseholdStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  //spin up formik
  const formik = useFormik<Household>({
    initialValues: props.initialValues,
    enableReinitialize: true,
    validate: validateHousehold,
    onSubmit: (values) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
  });

  function validateHousehold(values: Household): FormikErrors<Household> {
    const errors: FormikErrors<Household> = {};

    if (props.formType === "primary") {
      if (isEmpty(values.HouseholdType)) {
        errors.HouseholdType = RequiredMessage;
      }

      if (isEmpty(values.HousingType)) {
        errors.HousingType = RequiredMessage;
      }
    }

    if (isEmpty(values.Education)) {
      errors.Education = RequiredMessage;
    }

    if (isEmpty(values.MilitaryStatus)) {
      errors.MilitaryStatus = RequiredMessage;
    }

    if (isEmpty(values.HealthInsurance)) {
      errors.HealthInsurance = RequiredMessage;
    } else if (
      parseBooleanYesNo(values.HealthInsurance) &&
      isEmpty(values.HealthInsuranceType)
    ) {
      errors.HealthInsuranceType = RequiredMessage;
    }

    if (props.formType === "additional-member") {
      if (isEmpty(values.Relationship)) {
        errors.Relationship = RequiredMessage;
      }
    }

    return errors;
  }

  //get formik helpers
  const { getFieldProps, ErrorMessage } = useFormikHelpers(formik);

  return (
    <StepperBlock
      onBack={props.onBack}
      onNext={() => {
        completeOrNext.current = "next";
        formik.submitForm();
      }}
      onSaveAndComplete={() => {
        completeOrNext.current = "complete";
        formik.submitForm();
      }}
      isLoading={formik.isSubmitting}
    >
      {props.formType === "primary" && (
        <>
          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="HouseholdType">Household Type</Label>
            <Select id="HouseholdType" {...getFieldProps("HouseholdType")}>
              {houseHoldTypes.sort().map((x) => {
                return (
                  <option key={x} value={x}>
                    {x}
                  </option>
                );
              })}
            </Select>
            <ErrorMessage name="HouseholdType" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="HousingType">Housing Type</Label>
            <Select id="HousingType" {...getFieldProps("HousingType")}>
              {housingTypes.sort().map((x) => {
                return (
                  <option key={x} value={x}>
                    {x}
                  </option>
                );
              })}
            </Select>
            <ErrorMessage name="HousingType" />
          </div>
        </>
      )}

      {props.formType === "additional-member" && (
        <>
          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="Relationship">Relationship to applicant</Label>
            <Select {...getFieldProps("Relationship")}>
              {relationships.map((x, i) => {
                return (
                  <option key={i} value={x}>
                    {x}
                  </option>
                );
              })}
            </Select>
            <ErrorMessage name="Relationship" />
          </div>
        </>
      )}

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="education">Education Completed</Label>
        <Select {...getFieldProps("Education")}>
          {educationalLevels.map((x, i) => {
            return (
              <option key={i} value={x}>
                {x}
              </option>
            );
          })}
        </Select>
        <ErrorMessage name="Education" />
      </div>
      <div className="col-span-6">
        <Label>Military Status</Label>
        <CheckboxStripContainer>
          <CheckBoxContainerRadio
            id="active"
            values={[formik.values.MilitaryStatus]}
            {...getFieldProps("MilitaryStatus")}
            value="active"
            label="Active"
          />
          <CheckBoxContainerRadio
            id="never_served"
            values={[formik.values.MilitaryStatus]}
            {...getFieldProps("MilitaryStatus")}
            value="never served"
            label="Never Served"
          />
          <CheckBoxContainerRadio
            id="veteran"
            values={[formik.values.MilitaryStatus]}
            {...getFieldProps("MilitaryStatus")}
            value="veteran"
            label="Veteran"
          />
        </CheckboxStripContainer>
        <ErrorMessage name="MilitaryStatus" />
      </div>

      <div className="col-span-6">
        <Label>Health Insurance</Label>
        <CheckboxStripContainer>
          <CheckBoxContainerRadio
            id="health_yes"
            values={[formik.values.HealthInsurance]}
            {...getFieldProps("HealthInsurance")}
            value="yes"
            label="Yes"
          />
          <CheckBoxContainerRadio
            id="health_no"
            values={[formik.values.HealthInsurance]}
            {...getFieldProps("HealthInsurance")}
            value="no"
            label="No"
          />
        </CheckboxStripContainer>
        <ErrorMessage name="HealthInsurance" />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="health_insurance_type">If Yes, What Kind?</Label>
        <Select
          id="health_insurance_type"
          {...getFieldProps("HealthInsuranceType")}
        >
          {healthInsuranceTypes.sort().map((x) => {
            return (
              <option key={x} value={x}>
                {x}
              </option>
            );
          })}
        </Select>
        <ErrorMessage name="HealthInsuranceType" />
      </div>
    </StepperBlock>
  );
};
