import { previousDay } from "date-fns";
import { useEffect } from "react";
import { AppointmentFile } from "../../types/appointmentFile";
import { useRequest, UseRequestResult } from "../hooks/useApi";
import {
  useAppointmentsGlobalState,
  useSetAppointmentsGlobalState,
} from "./appointmentStore";
import { useToken } from "./useToken";

/**
 * wrap the api call to get files.
 * will keep the global state in sync
 * @param immediateRequest
 * @returns request function for loading / refreshing files in the global state
 */
export function useAppointmentFiles(
  immediateRequest?: boolean
): [UseRequestResult<AppointmentFile[]>, () => void] {
  const { token } = useToken();
  const setState = useSetAppointmentsGlobalState();
  const state = useAppointmentsGlobalState();

  //do files request
  const [response, request] = useRequest<AppointmentFile[]>(
    {
      url: "/api/appointment/files/" + token,
    },
    immediateRequest
  );

  useEffect(() => {
    if (!response.isLoading && (response.data || response.error)) {
      setState((prev) => ({ ...prev, files: response.data || [] }));
    }
  }, [response.data, response.error, response.isLoading]);

  return [response, request];
}
