import { useSetStepState, useStepState } from "./stepStore";

export default function useStepper() {
  const stepState = useStepState();
  const setStepState = useSetStepState();
  function doStep(i: number) {
    setStepState((prev) => ({
      ...prev,
      hasStepped: true,
      step: i,
    }));
  }

  function stepUp() {
    doStep(stepState.step + 1);
  }

  function stepDown() {
    doStep(stepState.step - 1);
  }

  return {
    doStep,
    stepUp,
    stepDown,
  };
}
