import React, { useRef } from "react";
import {
  CheckBoxContainerRadio,
  CheckboxStripContainer,
  FormSubHeader,
  Label,
  Select,
} from "../formComponents";
import { StepperBlock } from "../Stepper";
import raceTypes from "../../json/raceTypes.json";
import tribeTypes from "../../json/tribeTypes.json";
import ethnicityTypes from "../../json/ethnicityTypes.json";
import { FormikErrors, useFormik } from "formik";
import { isEmpty, RequiredMessage } from "../validation";
import { useFormikHelpers } from "../hooks/useFormikHelpers";

export type Race = {
  Race: string;
  Ethnicity: string;
  Tribe: string;
  WorkAtWCAI: string;
  FamilyAtWCAI: string;
};

export interface IRaceStepProps {
  onBack: () => void;
  onNext: (data: Race) => Promise<void>;
  onSaveAndComplete?: (data: Race) => Promise<void>;
  initialValues: Race;
  isAdditionalMember: boolean;
}

export const RaceStep: React.FC<IRaceStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  //spin up formik
  const formik = useFormik<Race>({
    initialValues: props.initialValues,
    validate: validateRace,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
  });

  //get helpers
  const { getFieldProps, ErrorMessage } = useFormikHelpers<Race>(formik);

  function validateRace(values: Race): FormikErrors<Race> {
    const errors: FormikErrors<Race> = {};

    if (isEmpty(values.Race)) {
      errors.Race = RequiredMessage;
    }

    if (isEmpty(values.Ethnicity)) {
      errors.Ethnicity = RequiredMessage;
    }

    if (props.isAdditionalMember === false) {
      if (isEmpty(values.WorkAtWCAI)) {
        errors.WorkAtWCAI = RequiredMessage;
      }

      if (isEmpty(values.FamilyAtWCAI)) {
        errors.FamilyAtWCAI = RequiredMessage;
      }
    }

    return errors;
  }

  return (
    <StepperBlock
      onBack={props.onBack}
      onNext={() => {
        completeOrNext.current = "next";
        formik.submitForm();
      }}
      onSaveAndComplete={() => {
        completeOrNext.current = "complete";
        formik.submitForm();
      }}
      isLoading={formik.isSubmitting}
    >
      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="race">Race</Label>
        <Select id="race" {...getFieldProps("Race")}>
          {raceTypes.sort().map((x) => {
            return (
              <option key={x} value={x}>
                {x}
              </option>
            );
          })}
        </Select>
        <ErrorMessage name="Race" />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="ethnicity">Ethnicity</Label>
        <Select {...getFieldProps("Ethnicity")} id="ethnicity">
          {ethnicityTypes.sort().map((x) => {
            return (
              <option key={x} value={x}>
                {x}
              </option>
            );
          })}
        </Select>
        <ErrorMessage name="Ethnicity" />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <Label htmlFor="tribe">Tribe</Label>
        <Select {...getFieldProps("Tribe")} id="tribe">
          {tribeTypes.sort().map((x) => {
            return (
              <option value={x} key={x}>
                {x}
              </option>
            );
          })}
        </Select>
        <ErrorMessage name="Tribe" />
      </div>

      {props.isAdditionalMember === false && (
        <>
          <div className="col-span-6">
            <FormSubHeader>
              Do you work at WCAI or another CAA or are you a member of the
              board of either WCAI or another CAA?
            </FormSubHeader>
            <CheckboxStripContainer>
              <CheckBoxContainerRadio
                id="wcai_work_yes"
                values={[formik.values.WorkAtWCAI]}
                {...getFieldProps("WorkAtWCAI")}
                value="yes"
                label="Yes"
              />
              <CheckBoxContainerRadio
                id="wcai_work_no"
                values={[formik.values.WorkAtWCAI]}
                {...getFieldProps("WorkAtWCAI")}
                value="no"
                label="No"
              />
            </CheckboxStripContainer>
            <ErrorMessage name="WorkAtWCAI" />
          </div>

          <div className="col-span-6">
            <FormSubHeader>
              Do you have a family member that works at WCAI?
            </FormSubHeader>
            <CheckboxStripContainer>
              <CheckBoxContainerRadio
                id="family_wcai_work_yes"
                values={[formik.values.FamilyAtWCAI]}
                {...getFieldProps("FamilyAtWCAI")}
                value="yes"
                label="Yes"
              />
              <CheckBoxContainerRadio
                id="family_wcai_work_no"
                values={[formik.values.FamilyAtWCAI]}
                {...getFieldProps("FamilyAtWCAI")}
                value="no"
                label="No"
              />
            </CheckboxStripContainer>
            <ErrorMessage name="FamilyAtWCAI" />
          </div>
        </>
      )}
    </StepperBlock>
  );
};
