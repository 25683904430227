import React, { useRef, useState } from "react";
import { Stepper } from "./Stepper";
import { format, parseISO } from "date-fns";
import { PersonalInformationStep } from "./steps/PersonalInformationStep";
import { MailingInformationStep } from "./steps/MailingInformationStep";
import { DemographicsStep } from "./steps/DemographicsStep";
import { HouseholdStep } from "./steps/HouseholdStep";
import { RaceStep } from "./steps/RaceStep";
import { IncomeStep } from "./steps/IncomeStep";
import { ResidenceStep } from "./steps/ResidenceStep";
import { OtherContactStep } from "./steps/OtherContactStep";
import {
  defaultAdditionalMember,
  FormStateProvider,
  InProgressStatus,
  NeedsDocumentationStatus,
  useSharedFormState,
  WatereeFormData,
} from "./store/store";
import {
  AdditionalHouseholdMember,
  AdditionalHouseholdMembers,
} from "./steps/AdditionalHouseHoldMembers";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { AdditionalMemberPersonalInformationStep } from "./steps/AdditionalMemberPersonalInformationStep";
import { RequiredDocumentsStep } from "./steps/RequiredDocumentsStep";
import { DisclosuresAndAgreementsStep } from "./steps/DisclosuresAndAgreementsStep";
import { CheckIcon, DocumentAddIcon } from "@heroicons/react/outline";
import { BlockSpinner } from "./Spinner";
import { getAxiosInstance, useAxios } from "./hooks/useAxios";
import { Application } from "../types/application";
import {
  mapApplicationDomainToState,
  mapCurrentAdditionalMemberToDtos,
  mapCurrentFormToEditApplicationDtos,
  mapOtherHouseholdMemberDomainToClient,
} from "../types/helpers/mappers";
import { OtherHouseholdMember } from "../types/otherHouseholdMember";
import { FailureNotification } from "./Notification";
import { PrimaryButton } from "./Buttons";

/**Main application for Wateree. Wraps main assistance application */
export default function WatereeApp() {
  return (
    <FormStateProvider>
      <WatereeAssistanceApplication />
    </FormStateProvider>
  );
}

/**primary assistance application */
export function WatereeAssistanceApplication() {
  const [state, setState] = useSharedFormState();
  const [iniitalLoadComplete, setInitialLoadComplete] =
    useState<boolean>(false);
  const [failMessage, setFailMessage] = useState<string>("");
  const failMessageTimeoutId = useRef<NodeJS.Timeout>();
  //grab application from server
  const [applicationRequest, reloadApplication] = useAxios<{
    application: Application;
    isDemoUser: boolean;
  }>({
    url: "/api/application",
  });

  async function saveApplication(
    newFormData: WatereeFormData,
    newCurrentStep: number,
    finalSubmit: boolean
  ): Promise<void> {
    const axios = await getAxiosInstance();

    const editApplicationDtos = mapCurrentFormToEditApplicationDtos(
      newFormData,
      newCurrentStep
    );

    await axios.put(
      `/api/application?submit=${finalSubmit.toString().toLowerCase()}`,
      editApplicationDtos
    );

    if (finalSubmit) {
      setState({
        ...state,
        isEditingAfterSubmission: false,
      });

      reloadApplication();
    }
  }

  async function saveAdditionalMember(
    currentAdditionalMember: AdditionalHouseholdMember,
    isComplete: boolean
  ): Promise<OtherHouseholdMember> {
    const axios = await getAxiosInstance();

    const dtos = mapCurrentAdditionalMemberToDtos(currentAdditionalMember);

    dtos.isComplete = isComplete;

    return (
      await axios.post<OtherHouseholdMember>(
        "/api/application/household_member",
        dtos
      )
    ).data;
  }

  async function saveAndComplete(data?: WatereeFormData) {
    const newData = data || state.CurrentFormData;

    //submit final application
    await saveApplication(newData, state.currentStep, true);

    setState({
      ...state,
      isEditingAfterSubmission: false,
    });

    reloadApplication();
  }

  //redirect the site for down.
  //make sure to undo the UI spinner as well when this is removed and the site is back up
  useEffect(() => {
    if (
      iniitalLoadComplete &&
      (!applicationRequest.response.application.status || //for bad data move to site down page
        (applicationRequest.response.application.status === InProgressStatus && //for non demo in progress apps move to site down
          applicationRequest.response.isDemoUser === false))
    ) {
      window.location.replace("/Identity/Maintenance/Down");
    }
  }, [iniitalLoadComplete]);

  //see if we got something
  useEffect(() => {
    if (!applicationRequest.loading && applicationRequest.response) {
      //just set the main application id. eventually we'll map the whole thing down.
      setState({
        ...state,
        CurrentFormData: mapApplicationDomainToState(
          applicationRequest.response.application
        ),
        currentStep:
          applicationRequest.response.application.publicFormCurrentStep,
      });

      setInitialLoadComplete(true);
    }
  }, [applicationRequest.loading, applicationRequest.response]);

  //do some scrolling when we change display state to make sure the step is in focus
  useLayoutEffect(() => {
    //for main state we're switching back and probably pretty deep down (adding members is like step 8)
    //we should be setting the main form scroll top before adding a member so restore it now.
    if (state.displayState === "main-form" && state.currentStep > 1) {
      const position = $(".current-step").position();

      if (position) {
        $("html, body").animate({ scrollTop: position.top - 30 }, 300);
      }
    }

    //if we're adding a new member just scroll back to the the top of the stepper
    if (state.displayState === "add-additional-member") {
      const offset = $("#add-additional-household-member-header").offset();

      if (offset) {
        $("html, body").animate(
          {
            scrollTop: !!offset ? offset.top - 30 : 0,
          },
          0
        );
      }
    }
  }, [state.displayState, state.isEditingAfterSubmission]);

  async function saveApplicationStep(currentStep: number) {
    const axios = await getAxiosInstance();

    await axios.put(`/api/application/step/${currentStep}`);
  }

  /**Move the main form next and save current form data along with it */
  const mainFormNext = async (newFormData?: WatereeFormData): Promise<void> => {
    const newCurrentStep = state.currentStep + 1;

    setFailMessage("");

    //try to do save
    try {
      if (newFormData) {
        await saveApplication(newFormData, newCurrentStep, false);
      } else {
        await saveApplicationStep(newCurrentStep);
      }

      setState({
        ...state,
        //some steps don't actually submit form data (they add entities separately)
        CurrentFormData: newFormData || state.CurrentFormData,
        currentStep: newCurrentStep,
      });
    } catch {
      //if we  splode just show the fail notification
      setFailMessage("We couldn't save your applicaiton. Please try again");
    }
  };

  const mainFormBack = async (): Promise<void> => {
    const newCurrentStep = state.currentStep - 1;

    setFailMessage("");

    try {
      await saveApplicationStep(newCurrentStep);

      setState({
        ...state,
        currentStep: newCurrentStep,
      });
    } catch {
      //if we  splode just show the fail notification
      setFailMessage("We couldn't save your applicaiton. Please try again");
    }
  };

  const addFormNext = async (
    newMemberData: AdditionalHouseholdMember
  ): Promise<void> => {
    setFailMessage("");

    try {
      var householdMember = await saveAdditionalMember(
        newMemberData,
        newMemberData.isComplete
      );

      //assign id in case this is the first post
      newMemberData.id = householdMember.otherHouseholdMemberID;

      setState({
        ...state,
        CurrentFormData: {
          ...state.CurrentFormData,
          AdditionalMembers: mergeHouseholdMember(
            [...state.CurrentFormData.AdditionalMembers],
            householdMember
          ),
        },
        CurrentAdditionalMember: {
          ...newMemberData,
        },
        additionalMemberCurrentStep: state.additionalMemberCurrentStep + 1,
      });
    } catch {
      //if we  splode just show the fail notification
      setFailMessage("We couldn't save this member. Please try again");
    }
  };

  const addFormBack = (): void => {
    setState({
      ...state,
      additionalMemberCurrentStep: state.additionalMemberCurrentStep - 1,
    });
  };

  function mergeHouseholdMember(
    members: AdditionalHouseholdMember[],
    newMember: OtherHouseholdMember
  ): AdditionalHouseholdMember[] {
    //map domain to front end model
    const mappedNewMember = mapOtherHouseholdMemberDomainToClient(newMember);
    //see if we have an existing entry in our array.
    const index = members.findIndex((x) => x.id === mappedNewMember.id);

    //push or splice (add or update)
    if (index < 0) {
      members.push(mappedNewMember);
    } else {
      members.splice(index, 1, mappedNewMember);
    }

    return members;
  }

  return (
    <>
      {iniitalLoadComplete && (
        <>
          {/**currently redirectly new or in progress applications.
           * this check should be moved to the below block with 'isEditingAfterSubmission' once the site is back up.
           */}
          {(!state.CurrentFormData.status ||
            (state.CurrentFormData.status === InProgressStatus &&
              applicationRequest.response.isDemoUser === false)) && (
            <BlockSpinner />
          )}

          {(state.isEditingAfterSubmission ||
            (applicationRequest.response &&
              applicationRequest.response.isDemoUser)) && (
            <>
              {state.displayState === "main-form" && (
                <>
                  <div className="text-2xl my-3">Primary Application</div>
                  <p className="text-base text-gray-500 mb-4 lg:mb-8">
                    Fill out the form below to see if you qualify for assistance
                  </p>
                  <Stepper
                    id="main-application-stepper"
                    currentStep={state.currentStep}
                    steps={[
                      {
                        title: "Personal Information",
                        render: () => (
                          <PersonalInformationStep
                            onNext={async (data) => {
                              const newFormData = {
                                ...state.CurrentFormData,
                                PersonalInformation: data,
                              };

                              await mainFormNext(newFormData);
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   const newFormData = {
                            //     ...state.CurrentFormData,
                            //     PersonalInformation: data,
                            //   };

                            //   await saveAndComplete(newFormData);
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Mailing Information",
                        render: () => (
                          <MailingInformationStep
                            onNext={async (data) => {
                              const newFormData = {
                                ...state.CurrentFormData,
                                MailingInformation: data,
                              };

                              await mainFormNext(newFormData);
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   const newFormData = {
                            //     ...state.CurrentFormData,
                            //     MailingInformation: data,
                            //   };

                            //   await saveAndComplete(newFormData);
                            // }}
                            onBack={mainFormBack}
                          />
                        ),
                      },
                      {
                        title: "Demographics",
                        render: () => (
                          <DemographicsStep
                            initialValues={state.CurrentFormData.Demographics}
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              const newFormData = {
                                ...state.CurrentFormData,
                                Demographics: data,
                              };

                              await mainFormNext(newFormData);
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   const newFormData = {
                            //     ...state.CurrentFormData,
                            //     Demographics: data,
                            //   };

                            //   await saveAndComplete(newFormData);
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Household",
                        render: () => (
                          <HouseholdStep
                            initialValues={state.CurrentFormData.Household}
                            formType={"primary"}
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              const newFormData = {
                                ...state.CurrentFormData,
                                Household: data,
                              };

                              await mainFormNext(newFormData);
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   const newData = {
                            //     ...state.CurrentFormData,
                            //     Household: data,
                            //   };

                            //   saveAndComplete(newData);
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Race",
                        render: () => (
                          <RaceStep
                            isAdditionalMember={false}
                            initialValues={state.CurrentFormData.Race}
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              const newFormData = {
                                ...state.CurrentFormData,
                                Race: data,
                              };

                              await mainFormNext(newFormData);
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   const newFormData = {
                            //     ...state.CurrentFormData,
                            //     Race: data,
                            //   };

                            //   await saveAndComplete(newFormData);
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Income",
                        render: () => (
                          <IncomeStep
                            initialValues={state.CurrentFormData.Income}
                            hideAssistanceInputs={false}
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              await mainFormNext({
                                ...state.CurrentFormData,
                                Income: data,
                              });
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   await saveAndComplete({
                            //     ...state.CurrentFormData,
                            //     Income: data,
                            //   });
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Residence",
                        render: () => (
                          <ResidenceStep
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              await mainFormNext({
                                ...state.CurrentFormData,
                                Residence: data,
                              });
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   await saveAndComplete({
                            //     ...state.CurrentFormData,
                            //     Residence: data,
                            //   });
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Other Contact",
                        render: () => (
                          <OtherContactStep
                            initialValues={state.CurrentFormData.OtherContact}
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              await mainFormNext({
                                ...state.CurrentFormData,
                                OtherContact: data,
                              });
                            }}
                            // onSaveAndComplete={async (data) => {
                            //   await saveAndComplete({
                            //     ...state.CurrentFormData,
                            //     OtherContact: data,
                            //   });
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Additional Household Members",
                        render: () => (
                          <AdditionalHouseholdMembers
                            onBack={mainFormBack}
                            onNext={() => {
                              mainFormNext();
                            }}
                            // onSaveAndComplete={async () => {
                            //   await saveAndComplete();
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Required Documents",
                        render: () => (
                          <RequiredDocumentsStep
                            onBack={mainFormBack}
                            onNext={() => {
                              mainFormNext();
                            }}
                            // onSaveAndComplete={async () => {
                            //   await saveAndComplete();
                            // }}
                          />
                        ),
                      },
                      {
                        title: "Disclosures",
                        render: () => (
                          <DisclosuresAndAgreementsStep
                            onBack={mainFormBack}
                            onNext={async (data) => {
                              const newData = {
                                ...state.CurrentFormData,
                                DisclosureAgreement: data,
                              };

                              //submit final application
                              await saveApplication(
                                newData,
                                state.currentStep,
                                true
                              );

                              reloadApplication();
                            }}
                            initialValues={
                              state.CurrentFormData.DisclosureAgreement
                            }
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}

              {state.displayState === "add-additional-member" && (
                <>
                  <div
                    className="text-2xl my-3"
                    id="add-additional-household-member-header"
                  >
                    Additional Household Member
                  </div>
                  <p className="text-base text-gray-500 mb-4 lg:mb-8">
                    Add an additional household member below
                  </p>
                  <Stepper
                    id="add-additional-household-member-stepper"
                    currentStep={state.additionalMemberCurrentStep}
                    steps={[
                      {
                        title: "Personal Information",
                        render: () => (
                          <AdditionalMemberPersonalInformationStep
                            initialData={
                              state.CurrentAdditionalMember.PersonalInformation
                            }
                            onBack={() => {
                              /**set display state back to main form and reset current additional member */
                              setState({
                                ...state,
                                displayState: "main-form",
                                CurrentAdditionalMember:
                                  defaultAdditionalMember,
                              });
                            }}
                            onNext={async (data) => {
                              addFormNext({
                                ...state.CurrentAdditionalMember,
                                PersonalInformation: data,
                              });
                            }}
                          />
                        ),
                      },
                      {
                        title: "Demographics",
                        render: () => (
                          <DemographicsStep
                            initialValues={
                              state.CurrentAdditionalMember.Demographics
                            }
                            onBack={addFormBack}
                            onNext={async (data) => {
                              addFormNext({
                                ...state.CurrentAdditionalMember,
                                Demographics: data,
                              });
                            }}
                          />
                        ),
                      },
                      {
                        title: "Household",
                        render: () => (
                          <HouseholdStep
                            initialValues={
                              state.CurrentAdditionalMember.Household
                            }
                            formType={"additional-member"}
                            onBack={addFormBack}
                            onNext={async (data) => {
                              addFormNext({
                                ...state.CurrentAdditionalMember,
                                Household: data,
                              });
                            }}
                          />
                        ),
                      },
                      {
                        title: "Race",
                        render: () => (
                          <RaceStep
                            isAdditionalMember={true}
                            initialValues={state.CurrentAdditionalMember.Race}
                            onBack={addFormBack}
                            onNext={async (data) => {
                              addFormNext({
                                ...state.CurrentAdditionalMember,
                                Race: data,
                              });
                            }}
                          />
                        ),
                      },
                      {
                        title: "Income",
                        render: () => (
                          <IncomeStep
                            hideAssistanceInputs={true}
                            initialValues={state.CurrentAdditionalMember.Income}
                            onBack={addFormBack}
                            onNext={async (data) => {
                              const currentMember = {
                                ...state.CurrentAdditionalMember,
                                IncomeStep: data,
                              };

                              //save to server
                              const otherHouseholdMember =
                                await saveAdditionalMember(currentMember, true);

                              //merge newly added household member to our array of members for the client side
                              const additionalMembers = mergeHouseholdMember(
                                [...state.CurrentFormData.AdditionalMembers],
                                otherHouseholdMember
                              );

                              //now update the global state
                              setState({
                                ...state,
                                CurrentFormData: {
                                  ...state.CurrentFormData,
                                  AdditionalMembers: additionalMembers,
                                },
                                //reset the current additional member to the default for next time
                                CurrentAdditionalMember:
                                  defaultAdditionalMember,
                                //reset additional member step for next time
                                additionalMemberCurrentStep: 0,
                                //head back to main form display
                                displayState: "main-form",
                              });
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </>
          )}

          {state.CurrentFormData.status !== InProgressStatus &&
            !state.isEditingAfterSubmission && (
              <>
                <div className="flex justify-center py-5 mb-5">
                  {/**Show document icon for needs documentation; checkbox for all other statuses */}
                  {state.CurrentFormData.status === NeedsDocumentationStatus ? (
                    <div className="rounded-xl h-24 w-24 text-white text-sm bg-yellow-400">
                      <DocumentAddIcon />
                    </div>
                  ) : (
                    <div className="rounded-full h-24 w-24 text-white text-xl bg-green-500">
                      <CheckIcon />
                    </div>
                  )}
                </div>
                <div className="text-2xl mb-5">
                  Application has been received!
                </div>
                <div className="text-lg mb-5">
                  <span className="text-gray-400">Date Received: </span>
                  {format(
                    parseISO(state.CurrentFormData.dateSubmitted as string),
                    "MMMM do, yyyy"
                  )}
                </div>
                <div className="text-lg mb-5">
                  <span className="text-gray-400">Current Status: </span>{" "}
                  {state.CurrentFormData.status}
                </div>
                <div className="text-lg mb-5">
                  <span className="text-gray-400">What's next?: </span>
                  {state.CurrentFormData.statusDescription}
                </div>

                {state.CurrentFormData.status === NeedsDocumentationStatus && (
                  <div className="mt-10 text-center">
                    <PrimaryButton
                      color="regal-blue"
                      onClick={() => {
                        //force to documents step
                        setState({
                          ...state,
                          currentStep: 9,
                          isEditingAfterSubmission: true,
                        });
                      }}
                    >
                      Go to My Documents
                    </PrimaryButton>
                  </div>
                )}
              </>
            )}
        </>
      )}

      {!iniitalLoadComplete && <BlockSpinner />}

      {failMessage && (
        <FailureNotification
          title="Save failed"
          message={failMessage}
          afterShow={() => {
            //if we show a notification before a previous expires clear its timoeut.
            if (failMessageTimeoutId.current) {
              clearTimeout(failMessageTimeoutId.current);
              failMessageTimeoutId.current = undefined;
            }

            failMessageTimeoutId.current = setTimeout(() => {
              setFailMessage("");
            }, 5000);
          }}
        />
      )}
    </>
  );
}
