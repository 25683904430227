import { format, formatISO, parse, parseISO } from "date-fns";

/**
 * Format value from server for display in our app (or to assign to client side dtos models)
 * Value is expected to be an ISO date string but support dates just because
 * @param value
 * @returns
 */
export function formatServerDateForDisplay(value: string | Date): string {
  if (!value) {
    return "";
  }
  if (value instanceof Date) {
    value = format(value, "MM/dd/yyyy");
  } else {
    const parsedDate = parseISO(value);
    value = format(parsedDate, "MM/dd/yyyy");
  }

  return value;
}

/**
 * Format a client side display date to ISO for posting to server consistently
 * Expects a string value of 'MM/dd/yyyy' but support dates as well just because
 * @param value
 */
export function formatClientDateForPost(value: string | Date): string {
  if (!value) {
    return "";
  }
  if (typeof value === "string") {
    value = parse(value, "MM/dd/yyyy", new Date());
  }

  return formatISO(value);
}
