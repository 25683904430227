import { useState } from "react";
import { createContainer } from "react-tracked";
import { ApplicationFile } from "../../types/applicationFile";
import { Applications_AM } from "../../types/applicationAM";

interface IApplicationsGlobalState {
  files: ApplicationFile[];
  appSummary: Applications_AM;
}

const defaultAppSummary: Applications_AM = {
    applicationID: 0,
    agencyCode: 0,
    dateReceived: null,
    methodReceived: "",
    status: "",
    assistanceType: "",
    dateDisconnect: null,
    firstName: "",
    lastName: "",
    email: "",
    sSN: "",
    dateOfBirth: null,
    countyID: null,
    createdBy: "",
    dateCreated: null,
    lastUpdatedBy: "",
    lastUpdated: null,
    cellPhone: "",
    assignedTo: "",
    assignedDate: null,
    color: "",
    beginWorkDate: null
}

const useStoreValue = () =>
  useState<IApplicationsGlobalState>({
    files: [],
    appSummary: defaultAppSummary
  });

export const {
  Provider: ApplicationFilesProvider,
  useTrackedState: useApplicationFilesState,
  useUpdate: useSetApplicationFilesState,
} = createContainer(useStoreValue);
