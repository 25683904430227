import React from "react";
import { useHistory } from "react-router";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import { useAuthenticatedState } from "./api-authorization/useAuthenticatedState";
import { PrimaryButton } from "./Buttons";
import { BlockSpinner } from "./Spinner";

export interface IHomeProps {}

const Paragraph: React.FC = (props) => {
  return (
    <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 space-y-6 mx-auto mb-6 text-left">
      {props.children}
    </p>
  );
};

export const Home: React.FC<IHomeProps> = (props) => {
  const history = useHistory();
  const authenticatedState = useAuthenticatedState();

  return (
    <>
      {!authenticatedState.ready ? (
        <BlockSpinner />
      ) : (
        <div className="pb-6">
          <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-none font-extrabold tracking-tight text-gray-900 mb-8 mt-14 sm:mb-10">
            Apply for Assistance
          </h1>
          {authenticatedState.authenticated === false && (
            <>
              <Paragraph>
                Need to start an application? Simply sign up and begin. The
                application typically takes around 30 minutes to complete.
              </Paragraph>
              <Paragraph>
                Already started but couldn't finish? Login and pick back up
                right where you left off.
              </Paragraph>
              <hr className="my-20 sm:my-12" />
              <div className="text-center">
                <PrimaryButton
                  color="regal-blue"
                  onClick={() =>
                    history.push(
                      ApplicationPaths.Login +
                        "?returnUrl=" +
                        encodeURIComponent(
                          window.location.origin + "/application"
                        )
                    )
                  }
                  className="mr-5 w-36"
                >
                  Login
                </PrimaryButton>
                <PrimaryButton
                  color="guardsman-red"
                  className="w-36"
                  onClick={() =>
                    history.push(
                      ApplicationPaths.Register +
                        "?returnUrl=" +
                        encodeURIComponent(
                          window.location.origin + "/application"
                        )
                    )
                  }
                >
                  Sign up
                </PrimaryButton>
              </div>
            </>
          )}

          {authenticatedState.authenticated && (
            <>
              <Paragraph>
                Welcome back! We'll take you where you last left off.
              </Paragraph>
              <Paragraph>
                Please finish your application within 30 days of starting or
                you'll have to resubmit.
              </Paragraph>
              <hr className="my-20 sm:my-12" />
              <div className="text-center">
                <PrimaryButton
                  color="regal-blue"
                  onClick={() => history.push("/application")}
                  className="w-36"
                >
                  Let's Go!
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
