import { FormikErrors, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { FormSubHeader, Label, Select, TextInput } from "../formComponents";
import { StepperBlock } from "../Stepper";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { isEmpty, RequiredMessage } from "../validation";
import { IGlobalState, useSharedFormState } from "../store/store";
import { useAxios } from "../hooks/useAxios";
import { Counties } from "../../types/counties";

export type MailingInformation = {
  PhysicalAddress: string;
  PhysicalCity: string;
  PhysicalCounty: string;
  PhysicalZip: string;
  MailingAddress: string;
  MailingCity: string;
  MailingCounty: string;
  MailingZip: string;
};

export interface IMailingInformationStepProps {
  onBack: () => void;
  onNext: (data: MailingInformation) => Promise<void>;
  onSaveAndComplete?: (data: MailingInformation) => Promise<void>;
}

function physicalAndMailingAreSame(state: IGlobalState): boolean {
  var form = state.CurrentFormData.MailingInformation;

  return (
    form.MailingAddress === form.PhysicalAddress &&
    form.MailingCity === form.PhysicalCity &&
    form.MailingCounty === form.PhysicalCounty &&
    form.MailingZip === form.PhysicalZip
  );
}

export const MailingInformationStep: React.FC<IMailingInformationStepProps> = (
  props
) => {
  //get global state
  const [state] = useSharedFormState();
  const [countiesData] = useAxios<Counties[]>({ url: "/data/counties" });
  const [mailingSameAs, setMailingSameAs] = useState<boolean>(
    physicalAndMailingAreSame(state)
  );
  const completeOrNext = useRef<"complete" | "next">("complete");

  function validateMailingInformation(
    values: MailingInformation
  ): FormikErrors<MailingInformation> {
    let errors: FormikErrors<MailingInformation> = {};

    if (isEmpty(values.PhysicalAddress)) {
      errors.PhysicalAddress = RequiredMessage;
    }

    if (isEmpty(values.PhysicalCity)) {
      errors.PhysicalCity = RequiredMessage;
    }

    if (isEmpty(values.PhysicalCounty)) {
      errors.PhysicalCounty = RequiredMessage;
    }

    if (isEmpty(values.PhysicalZip)) {
      errors.PhysicalZip = RequiredMessage;
    }

    if (isEmpty(values.MailingAddress) && !mailingSameAs) {
      errors.MailingAddress = RequiredMessage;
    }

    if (isEmpty(values.MailingCity) && !mailingSameAs) {
      errors.MailingCity = RequiredMessage;
    }

    if (isEmpty(values.MailingCounty) && !mailingSameAs) {
      errors.MailingCounty = RequiredMessage;
    }

    if (isEmpty(values.MailingZip) && !mailingSameAs) {
      errors.MailingZip = RequiredMessage;
    }

    return errors;
  }

  //spin up formik
  const formik = useFormik<MailingInformation>({
    initialValues: state.CurrentFormData.MailingInformation,
    enableReinitialize: true,
    onSubmit: (values: MailingInformation) => {
      //assign physical address fields here.
      if (mailingSameAs) {
        values.MailingAddress = values.PhysicalAddress;
        values.MailingCity = values.PhysicalCity;
        values.MailingCounty = values.PhysicalCounty;
        values.MailingZip = values.PhysicalZip;
      }

      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
    validate: validateMailingInformation,
  });

  //custom hook to wrap some formik components and apply type safety!
  const { getFieldProps, ErrorMessage } =
    useFormikHelpers<MailingInformation>(formik);

  return (
    <>
      <StepperBlock
        onBack={props.onBack}
        onNext={() => {
          completeOrNext.current = "next";
          formik.submitForm();
        }}
        onSaveAndComplete={() => {
          completeOrNext.current = "complete";
          formik.submitForm();
        }}
        isLoading={formik.isSubmitting}
      >
        <div className="col-span-6">
          <FormSubHeader>Primary Address</FormSubHeader>
        </div>
        <div className="col-span-6">
          <Label htmlFor="street_address">Street address</Label>
          <TextInput
            type="text"
            {...getFieldProps("PhysicalAddress")}
            autoComplete="street-address"
          />
          <ErrorMessage name="PhysicalAddress" />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="city">City</Label>
          <TextInput
            type="text"
            {...getFieldProps("PhysicalCity")}
            autoComplete="address-level2"
          />
          <ErrorMessage name="PhysicalCity" />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="county">County</Label>
          <Select
            id="county"
            {...getFieldProps("PhysicalCounty")}
            disabled={countiesData.loading}
          >
            <option key={-1} value={""}></option>
            {!countiesData.loading &&
              countiesData.response &&
              countiesData.response.map((x) => {
                return (
                  <option key={x.id} value={x.id}>
                    {x.county}
                  </option>
                );
              })}
          </Select>
          <ErrorMessage name="PhysicalCounty" />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Label htmlFor="postal_code">ZIP / Postal</Label>
          <TextInput
            type="text"
            {...getFieldProps("PhysicalZip")}
            autoComplete="postal-code"
          />
          <ErrorMessage name="PhysicalZip" />
        </div>

        <div className="col-span-6">
          <FormSubHeader>
            Mailing Address
            <div className="inline-block ml-5 text-sm text-gray-400">
              <label>
                <input
                  type="checkbox"
                  checked={mailingSameAs}
                  onChange={() => setMailingSameAs(!mailingSameAs)}
                  className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 mr-3"
                />
                Same as physical address
              </label>
            </div>
          </FormSubHeader>
        </div>

        {!mailingSameAs && (
          <>
            <div className="col-span-6">
              <Label htmlFor="mailing_street_address">Street address</Label>
              <TextInput
                type="text"
                {...getFieldProps("MailingAddress")}
                autoComplete="street-address"
              />
              <ErrorMessage name="MailingAddress" />
            </div>

            <div className="col-span-6 sm:col-span-2">
              <Label htmlFor="mailing_city">City</Label>
              <TextInput
                type="text"
                {...getFieldProps("MailingCity")}
                autoComplete="address_level2"
              />
              <ErrorMessage name="MailingCity" />
            </div>

            <div className="col-span-6 sm:col-span-2">
              <Label htmlFor="mailing_county">County</Label>
              <Select
                id="mailing_county"
                {...getFieldProps("MailingCounty")}
                disabled={countiesData.loading}
              >
                <option key={-1} value={""}></option>
                {!countiesData.loading &&
                  countiesData.response &&
                  countiesData.response.map((x) => {
                    return (
                      <option key={x.id} value={x.id}>
                        {x.county}
                      </option>
                    );
                  })}
              </Select>
              <ErrorMessage name="MailingCounty" />
            </div>

            <div className="col-span-6 sm:col-span-2">
              <Label htmlFor="mailing_postal_code">ZIP / Postal</Label>
              <TextInput
                type="text"
                {...getFieldProps("MailingZip")}
                autoComplete="postal-code"
              />
              <ErrorMessage name="MailingZip" />
            </div>
          </>
        )}
      </StepperBlock>
    </>
  );
};
