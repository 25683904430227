/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormikErrors, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { CurrencyDollarIcon, PlusCircleIcon } from "@heroicons/react/outline";
import {
  ConfirmationContentContainer,
  ConfirmationFooterContainer,
  ConfirmationModal,
} from "../ConfirmationModal";
import {
  FormSubHeader,
  Label,
  TextInput,
  CheckboxStripContainer,
  CheckBoxContainerCheckBox,
  Select,
} from "../formComponents";
import { StepperBlock } from "../Stepper";
// import incomeSources from "../../json/incomeTypes.json";
import { useFormikHelpers } from "../hooks/useFormikHelpers";
import { isEmpty, RequiredMessage } from "../validation";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { ListContainer, ListItem, ListItemIconContainer } from "../Lists";
import { getAxiosInstance, useAxios } from "../hooks/useAxios";
import { ApplicationIncomeSources } from "../../types/applicationIncomeSources";
import { IncomeSources } from "../../types/incomeSources";
import { useSharedFormState } from "../store/store";
import { AdditionalHouseholdMember } from "./AdditionalHouseHoldMembers";

export type IncomeSource = {
  IncomeSources: ApplicationIncomeSources[];
  FoodStampAmount: number;
  AssistanceSources: string[];
  FoodAssistanceAmount: number;
  ShelterAssistanceAmount: number;
  UtilitiesAssistanceAmount: number;
};

function validateIncomeSource(
  values: IncomeSource
): FormikErrors<IncomeSource> {
  const errors: FormikErrors<IncomeSource> = {};

  if (isEmpty(values.FoodStampAmount) || values.FoodStampAmount < 0) {
    errors.FoodStampAmount = "Must be at least 0.00";
  }

  /**if they've checked assistance sources ensure they set a value for each */
  if (values.AssistanceSources.length > 0) {
    //if we have absolutely nothing tell them at least one need a positive value
    if (
      (isEmpty(values.FoodAssistanceAmount) &&
        isEmpty(values.UtilitiesAssistanceAmount) &&
        isEmpty(values.ShelterAssistanceAmount)) ||
      (values.FoodAssistanceAmount <= 0 &&
        values.UtilitiesAssistanceAmount <= 0 &&
        values.ShelterAssistanceAmount <= 0)
    ) {
      errors.FoodAssistanceAmount = "Must be greater than 0.00";
      errors.ShelterAssistanceAmount = "Must be greater than 0.00";
      errors.UtilitiesAssistanceAmount = "Must be greater than 0.00";
    } else {
      if (values.FoodAssistanceAmount < 0) {
        errors.FoodAssistanceAmount = "Must be at least 0.00";
      }

      if (values.ShelterAssistanceAmount < 0) {
        errors.ShelterAssistanceAmount = "Must be at least 0.00";
      }

      if (values.UtilitiesAssistanceAmount < 0) {
        errors.UtilitiesAssistanceAmount = "Must be at least 0.00";
      }
    }
  }

  return errors;
}

export interface IIncomeStepProps {
  onBack: () => void;
  onNext: (data: IncomeSource) => Promise<void>;
  onSaveAndComplete?: (data: IncomeSource) => Promise<void>;
  initialValues: IncomeSource;
  hideAssistanceInputs: boolean;
}

export const IncomeStep: React.FC<IIncomeStepProps> = (props) => {
  const completeOrNext = useRef<"complete" | "next">("complete");
  const [state, setState] = useSharedFormState();
  const [showIncomeModal, setShowIncomeModal] = useState<boolean>(false);
  const [selectedIncomeSource, setSelectedIncomeSource] =
    useState<ApplicationIncomeSources | null>(null);

  //spinup formik
  const formik = useFormik<IncomeSource>({
    initialValues: props.initialValues,
    enableReinitialize: true,
    onSubmit: async (values: IncomeSource) => {
      if (completeOrNext.current === "next") {
        props.onNext(values).then(() => {
          formik.setSubmitting(false);
        });
      } else if (props.onSaveAndComplete) {
        props.onSaveAndComplete(values).then(() => {
          formik.setSubmitting(false);
        });
      }
    },
    validate: validateIncomeSource,
  });

  //get helpers
  const { getFieldProps, ErrorMessage } =
    useFormikHelpers<IncomeSource>(formik);

  return (
    <StepperBlock
      onBack={props.onBack}
      onNext={() => {
        completeOrNext.current = "next";
        formik.submitForm();
      }}
      onSaveAndComplete={() => {
        completeOrNext.current = "complete";
        formik.submitForm();
      }}
      isLoading={formik.isSubmitting}
      nextText={props.hideAssistanceInputs ? "Save" : "Next"}
    >
      <div className="col-span-6">
        <FormSubHeader>
          Add the monthly dollar amount for any income sources you may have
        </FormSubHeader>
        <PrimaryButton onClick={() => setShowIncomeModal(true)} color="green">
          <PlusCircleIcon className="mr-2 h-6 w-6 -ml-2" />
          Add Income Source
        </PrimaryButton>

        {/** no incomes sources */}
        {formik.values.IncomeSources.length === 0 && (
          <p className="my-10 pl-5 italic">
            You have no currently listed income sources
          </p>
        )}
        {/** list income sources */}
        {formik.values.IncomeSources.length > 0 && (
          <ListContainer>
            {formik.values.IncomeSources.map((x) => (
              <ListItem
                key={`${x.id}_${x.incomeSourceId}`}
                mainText={x.incomeSource}
                subText={`$${x.amount.toFixed(2)}`}
                onClick={() => {
                  setSelectedIncomeSource(x);
                  setShowIncomeModal(true);
                }}
                rightElement={
                  <a
                    className="text-red-600"
                    onClick={async (e) => {
                      e.stopPropagation();

                      formik.setSubmitting(true);
                      const axios = await getAxiosInstance();
                      axios
                        .delete(`/api/application/income/${x.id}`)
                        .then(() => {
                          //swipe out some stuff
                          if (
                            x.otherHouseholdMemberId &&
                            x.otherHouseholdMemberId > 0
                          ) {
                            //update the current selected member
                            var member = { ...state.CurrentAdditionalMember };
                            member.Income.IncomeSources =
                              member.Income.IncomeSources.reduce<
                                ApplicationIncomeSources[]
                              >((arr, curr) => {
                                //keep all the sources that aren't this one
                                if (curr.id !== x.id) {
                                  arr.push(curr);
                                }
                                return arr;
                              }, []);

                            //swap out in full members list as well
                            var members =
                              state.CurrentFormData.AdditionalMembers.reduce<
                                AdditionalHouseholdMember[]
                              >((arr, curr) => {
                                //keep all members that aren't this one
                                if (curr.id !== member.id) {
                                  arr.push(curr);
                                } else {
                                  //when we have a match add the member we modified above.
                                  arr.push(member);
                                }
                                return arr;
                              }, []);

                            //update state
                            setState({
                              ...state,
                              CurrentFormData: {
                                ...state.CurrentFormData,
                                AdditionalMembers: members,
                              },
                              CurrentAdditionalMember: member,
                            });
                          } else {
                            const incomes =
                              state.CurrentFormData.Income.IncomeSources.reduce<
                                ApplicationIncomeSources[]
                              >((arr, curr) => {
                                if (curr.id !== x.id) {
                                  arr.push(curr);
                                }

                                return arr;
                              }, []);

                            //update state
                            setState({
                              ...state,
                              CurrentFormData: {
                                ...state.CurrentFormData,
                                Income: {
                                  ...state.CurrentFormData.Income,
                                  IncomeSources: incomes,
                                },
                              },
                            });
                          }
                        })
                        .finally(() => formik.setSubmitting(false));
                    }}
                  >
                    REMOVE
                  </a>
                }
                iconContainer={
                  <ListItemIconContainer>
                    <CurrencyDollarIcon />
                  </ListItemIconContainer>
                }
              />
            ))}
          </ListContainer>
        )}
      </div>

      {!props.hideAssistanceInputs && (
        <>
          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="food_stamps">Food Stamps (per month)</Label>
            <TextInput
              id="food_stamps"
              {...getFieldProps("FoodStampAmount")}
              type="number"
            />
            <ErrorMessage name="FoodStampAmount" />
          </div>

          <div className="col-span-6">
            <FormSubHeader>
              I have no income to report based on the sources above, but have
              been meeting my basic living needs (food, shelter, utilities) with
              contributions from:
            </FormSubHeader>
            <CheckboxStripContainer>
              <CheckBoxContainerCheckBox
                id="assistance_source_family"
                values={formik.values.AssistanceSources}
                {...getFieldProps("AssistanceSources")}
                value="family"
                label="Family member"
              />
              <CheckBoxContainerCheckBox
                id="assistance_source_friend"
                values={formik.values.AssistanceSources}
                {...getFieldProps("AssistanceSources")}
                value="friend"
                label="Friend"
              />
              <CheckBoxContainerCheckBox
                id="assistance_source_church"
                values={formik.values.AssistanceSources}
                {...getFieldProps("AssistanceSources")}
                value="church"
                label="Church"
              />
              <CheckBoxContainerCheckBox
                id="assistance_source_nonprofit"
                values={formik.values.AssistanceSources}
                {...getFieldProps("AssistanceSources")}
                value="nonprofit"
                label="Nonprofit"
              />
              <CheckBoxContainerCheckBox
                id="assistance_source_other"
                values={formik.values.AssistanceSources}
                {...getFieldProps("AssistanceSources")}
                value="other"
                label="Other"
              />
            </CheckboxStripContainer>
            <ErrorMessage name="AssistanceSources" />
          </div>

          <div className="col-span-6">
            <FormSubHeader>
              If checked above provide monthly amounts from all sources
            </FormSubHeader>
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="total_assistance_food">Food</Label>
            <TextInput
              type="number"
              id="total_assistance_food"
              {...getFieldProps("FoodAssistanceAmount")}
            />
            <ErrorMessage name="FoodAssistanceAmount" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="total_assistance_shelter">Shelter</Label>
            <TextInput
              type="text"
              id="total_assistance_shelter"
              {...getFieldProps("ShelterAssistanceAmount")}
            />
            <ErrorMessage name="ShelterAssistanceAmount" />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <Label htmlFor="total_assistance_utilities">Utilities</Label>
            <TextInput
              type="text"
              id="total_assistance_utilities"
              {...getFieldProps("UtilitiesAssistanceAmount")}
            />
            <ErrorMessage name="UtilitiesAssistanceAmount" />
          </div>
        </>
      )}

      {/** modal for adding a single income source */}
      {showIncomeModal && (
        <IncomeSourceModal
          incomeSource={selectedIncomeSource}
          onConfirm={(incomeSource) => {
            if (incomeSource) {
              //grab values from formik
              let values = formik.values;
              //see if we're already added this one
              let matchingSource = values.IncomeSources.find(
                (x) => x.incomeSourceId == incomeSource.incomeSourceId
              );

              //if we have a match update it
              if (!!matchingSource) {
                matchingSource.amount = incomeSource.amount;
                formik.setValues(values, false);
              } else {
                //else push on the array
                values.IncomeSources.push(incomeSource);
                formik.setValues(values);
              }
            }

            setShowIncomeModal(false);
            setSelectedIncomeSource(null);
          }}
        />
      )}
    </StepperBlock>
  );
};

interface IIncomeSourceModalProps {
  onConfirm: (incomeSource?: ApplicationIncomeSources) => void;
  incomeSource: ApplicationIncomeSources | null;
}

/**Modal used to add a new income source */
const IncomeSourceModal: React.FC<IIncomeSourceModalProps> = (props) => {
  const [incomeSourceDataRequest] = useAxios<IncomeSources[]>({
    url: "/data/incomesources",
  });
  const [state] = useSharedFormState();

  const incomeSource: ApplicationIncomeSources = props.incomeSource || {
    id: 0,
    amount: 0,
    applicationId: state.CurrentFormData.ApplicationId,
    incomeSource: "",
    incomeSourceId: 0,
    otherHouseholdMemberId:
      state.CurrentAdditionalMember.id === 0
        ? null
        : state.CurrentAdditionalMember.id,
  };

  const isEdit = incomeSource.id > 0;

  const validate = (
    value: ApplicationIncomeSources
  ): FormikErrors<ApplicationIncomeSources> => {
    let validateResponse: FormikErrors<ApplicationIncomeSources> = {};

    if (!value.incomeSourceId || value.incomeSourceId <= 0) {
      validateResponse.incomeSourceId = RequiredMessage;
    }

    if (!value.amount || value.amount <= 0) {
      validateResponse.amount = "Must be greater than 0.00";
    }

    return validateResponse;
  };

  /**use formik to store form values instead of managing state manually ourselves.
   * It handles applying our validation function and some other things
   */
  const formik = useFormik<ApplicationIncomeSources>({
    initialValues: incomeSource,
    onSubmit: async (values) => {
      //assign income source from data since we are just tracking id in the formik form.
      //this'll all be swapped out for legit ajax calls anyways.
      values.incomeSource = incomeSourceDataRequest.response.find(
        (x) => x.incomeSourceID == values.incomeSourceId
      )?.incomeSource as string;

      const axios = await getAxiosInstance();

      axios
        .post<ApplicationIncomeSources>("/api/application/income", values)
        .then((response) => {
          props.onConfirm(response.data);
        });
    },
    validate: validate,
  });

  //get helpers
  const { getFieldProps, ErrorMessage } = useFormikHelpers(formik);

  return (
    <>
      <ConfirmationModal
        onClose={props.onConfirm}
        title={isEdit ? "Update Income Source" : "Add Income Source"}
      >
        <ConfirmationContentContainer>
          <Label htmlFor="type">Income Source</Label>
          <Select
            {...getFieldProps("incomeSourceId")}
            id="type"
            disabled={incomeSourceDataRequest.loading}
          >
            <option key="-1" value="-1"></option>
            {incomeSourceDataRequest.loading === false &&
              incomeSourceDataRequest.response &&
              incomeSourceDataRequest.response.map((x) => {
                return (
                  <option key={x.incomeSourceID} value={x.incomeSourceID}>
                    {x.incomeSource}
                  </option>
                );
              })}
          </Select>
          <ErrorMessage name="incomeSourceId" />

          <Label htmlFor="amount" className="mt-3">
            Amount (per month)
          </Label>
          <TextInput id="amount" type="number" {...getFieldProps("amount")} />
          <ErrorMessage name="amount" />
        </ConfirmationContentContainer>
        <ConfirmationFooterContainer>
          <SecondaryButton
            onClick={formik.submitForm}
            color="green"
            disabled={incomeSourceDataRequest.loading}
          >
            {isEdit ? "Update Income" : "Add Income"}
          </SecondaryButton>

          <SecondaryButton onClick={() => props.onConfirm()} color="gray">
            Nevermind
          </SecondaryButton>
        </ConfirmationFooterContainer>
      </ConfirmationModal>
    </>
  );
};
