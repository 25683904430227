import { useState } from "react";
import { createContainer } from "react-tracked";

export interface IStepperState {
  step: number;
  hasStepped: boolean;
  steps: JSX.Element[];
}

export const {
  Provider: StepStateProvider,
  useTrackedState: useStepState,
  useUpdate: useSetStepState,
} = createContainer((initialState: IStepperState) =>
  useState<IStepperState>(initialState)
);
