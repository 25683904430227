import React from "react";
import { Spinner } from "reactstrap";

export type IButtonColor =
  | "green"
  | "gray"
  | "indigo"
  | "yellow"
  | "regal-blue"
  | "guardsman-red";
export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color: IButtonColor;
  loading?: boolean;
}

export const SecondaryButton: React.FC<IButtonProps> = (props) => {
  const { className, loading, ...restOfProps } = props;
  return (
    <button
      className={`inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent 
      rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 
      ${getSecondaryButtonClasses(props.color).join(" ")} 
      ${className || ""}`}
      disabled={!!props.loading}
      {...restOfProps}
    >
      {!props.loading && props.children}
      {!!props.loading && <Spinner />}
    </button>
  );
};

/**function to get secondary button classes based on color property.
 * we write these as whole classes (as opposed to building dynamically inline)
 * to support tailwind css purging
 */
function getSecondaryButtonClasses(
  color: IButtonColor
): [string, string, string, string] {
  switch (color) {
    case "indigo":
      return [
        "text-indigo-900",
        "bg-indigo-100",
        "hover:bg-indigo-200",
        "focus-visibile:ring-indigo-500",
      ];
    case "gray":
      return [
        "text-gray-900",
        "bg-gray-100",
        "hover:bg-gray-200",
        "focus-visibile:ring-gray-500",
      ];
    case "green":
      return [
        "text-green-900",
        "bg-green-100",
        "hover:bg-green-200",
        "focus-visibile:ring-green-500",
      ];
    case "yellow":
      return [
        "text-yellow-900",
        "bg-yellow-100",
        "hover:bg-yellow-200",
        "focus-visibile:ring-yellow-500",
      ];
    case "guardsman-red":
      return [
        "text-guardsman-red-900",
        "bg-guardsman-red-100",
        "hover:bg-guardsman-red-200",
        "focus-visibile:ring-guardsman-red-500",
      ];
    case "regal-blue":
      return [
        "text-regal-blue-900",
        "bg-regal-blue-100",
        "hover:bg-regal-blue-200",
        "focus-visibile:ring-regal-blue-500",
      ];
  }
}

export const PrimaryButton: React.FC<IButtonProps> = (props) => {
  const { className, loading, ...restOfProps } = props;
  return (
    <button
      className={`inline-flex mt-1 justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md 
        text-white focus:outline-none focus:ring-2 focus:ring-offset-2 
        ${getPrimaryButtonClasses(props.color).join(" ")} 
        ${className || ""}`}
      disabled={!!props.loading}
      {...restOfProps}
    >
      {!props.loading && props.children}
      {!!props.loading && <Spinner />}
    </button>
  );
};

/**function to get primary button classes based on color property.
 * we write these as whole classes (as opposed to building dynamically inline)
 * to support tailwind css purging
 */
function getPrimaryButtonClasses(
  color: IButtonColor
): [string, string, string] {
  switch (color) {
    case "indigo":
      return ["bg-indigo-600", "hover:bg-indigo-700", "focus:ring-indigo-500"];
    case "gray":
      return ["bg-gray-600", "hover:bg-gray-700", "focus:ring-gray-500"];
    case "green":
      return ["bg-green-600", "hover:bg-green-700", "focus:ring-green-500"];
    case "yellow":
      return ["bg-yellow-600", "hover:bg-yellow-700", "focus:ring-yellow-500"];
    case "guardsman-red":
      return [
        "bg-guardsman-red-500",
        "hover:bg-guardsman-red-600",
        "focus:ring-guardsman-red-400",
      ];
    case "regal-blue":
      return [
        "bg-regal-blue-400",
        "hover:bg-regal-blue-500",
        "focus:ring-regal-blue-300",
      ];
  }
}

export const OutlineButton: React.FC<IButtonProps> = (props) => {
  const { className, loading, ...restOfProps } = props;
  return (
    <button
      className={`inline-flex mt-1 justify-center items-center py-2 px-4 border 
      shadow-sm text-sm font-medium rounded-md bg-white hover:bg-gray-50 
      focus:outline-none focus:ring-2 focus:ring-offset-2
      ${getOutlineButtonClasses(props.color).join(" ")}
      ${className || ""}`}
      disabled={!!props.loading}
      {...restOfProps}
    >
      {!props.loading && props.children}
      {!!props.loading && <Spinner />}
    </button>
  );
};

/**function to get outline button classes based on color property.
 * we write these as whole classes (as opposed to building dynamically inline)
 * to support tailwind css purging
 */
function getOutlineButtonClasses(
  color: IButtonColor
): [string, string, string] {
  switch (color) {
    case "indigo":
      return ["border-indigo-300", "text-indigo-600", "focus:ring-indigo-500"];
    case "gray":
      return ["border-gray-300", "text-gray-600", "focus:ring-gray-500"];
    case "green":
      return ["border-green-300", "text-green-600", "focus:ring-green-500"];
    case "yellow":
      return ["border-yellow-300", "text-yellow-600", "focus:ring-yellow-500"];
    case "guardsman-red":
      return [
        "border-guardsman-red-300",
        "text-guardsman-red-600",
        "focus:ring-guardsman-red-500",
      ];
    case "regal-blue":
      return [
        "border-regal-blue-300",
        "text-regal-blue-600",
        "focus:ring-regal-blue-500",
      ];
  }
}
